import React from 'react';
import {Typography} from '@mui/material';

interface ErrorTextProps {
    message: string;
    type?: "field" | "form";
}

const ErrorText: React.FC<ErrorTextProps> = ({message, type = "field"}) => {
    return (
        <Typography
            variant="caption"
            color="error"
            sx={{
                display: 'block',
                mt: 1,
                ml: 1,
                fontWeight: 400,
                fontSize: type === "field" ? '0.75rem' : '1rem'
            }}>
            {message}
        </Typography>
    );
};

export default ErrorText;
