import {Button, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createCompanyAction} from "../../api/settings";
import FormControl from "@mui/material/FormControl";
import CustomDialog from "../../components/CustomDialog";
import {AppState} from "../../store";
import {AsyncTaskStatusType} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {CompanyType} from "../../api/dtos";
import {setCreateClientTaskStatus, setCreateCompanyTaskStatus} from "../../features/settings/settingsSlice";

interface CreateCompanyDialogProps {
    companyType: CompanyType;
    handleClose: () => void;
    handleCreate: () => void;
}

export const CreateCompanyDialog: React.FC<CreateCompanyDialogProps> = ({companyType, handleClose, handleCreate}) => {
    const navigate = useNavigate();
    const createCompanyTaskStatus = useSelector((state: AppState) => state.settings.createCompanyTaskStatus);
    const dispatch: any = useDispatch();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson: any = {};
        formData.forEach((value, key) => {
            formJson[key] = value;
        });

        formJson["type"] = companyType;

        const result = await dispatch(createCompanyAction(formJson));

        if (result.type === "settings/createCompany/fulfilled") {
            handleClose();
            if (page === 1) {
                handleCreate();
            } else {
                navigate("");
            }
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setCreateCompanyTaskStatus(null));
        };
    }, [dispatch]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>
                {companyType === CompanyType.Branch ? "Şube Ekle" : "İştirak Ekle"}
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"/>
                </FormControl>

                {createCompanyTaskStatus?.type === AsyncTaskStatusType.Error && createCompanyTaskStatus.errorMessage && (
                    <ErrorText
                        type="form"
                        message={createCompanyTaskStatus.errorMessage || (
                            companyType === CompanyType.Branch
                                ? "Şube eklenirken bir hata oluştu"
                                : "İştirak eklenirken bir hata oluştu"
                        )}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
