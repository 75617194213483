// ** MUI Imports
import Divider from '@mui/material/Divider'
import {styled, useTheme} from '@mui/material/styles'
import Typography, {TypographyProps} from '@mui/material/Typography'
import MuiListSubheader, {ListSubheaderProps} from '@mui/material/ListSubheader'

// ** Types
import {NavSectionTitle} from '../../../types'
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

interface Props {
    item: NavSectionTitle,
    isOpen: boolean,
    toggleSection: () => void;
}

// ** Styled Components
const ListSubheader = styled((props: ListSubheaderProps) => <MuiListSubheader component='li' {...props} />)(
    ({theme}) => ({
        lineHeight: 1,
        display: 'flex',
        position: 'relative',
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
        transition: 'padding-left .25s ease-in-out'
    })
)

const TypographyHeaderText = styled(Typography)<TypographyProps>(({theme}) => ({
    fontSize: '0.75rem',
    lineHeight: 'normal',
    letterSpacing: '0.21px',
    textTransform: 'uppercase',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightMedium
}))

const VerticalNavSectionTitle = (props: Props) => {
    // ** Props
    const {item, isOpen, toggleSection} = props;

    // ** Hook
    const theme = useTheme();

    return (
        <div
            className='nav-section-title'
            style={{
                paddingTop: 1.75,
                paddingBottom: 1.75,
                display: "flex",
                color: theme.palette.text.disabled
            }}>
            <Divider
                textAlign='left'
                sx={{
                    flex: 1,
                    alignItems: "center",
                    m: 0,
                    width: '100%',
                    lineHeight: 'normal',
                    textTransform: 'uppercase',
                    '&:before, &:after': {top: 7, transform: 'none'},
                    '& .MuiDivider-wrapper': {px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px'}
                }}>
                <TypographyHeaderText>
                    {item.sectionTitle}
                </TypographyHeaderText>
            </Divider>
            <IconButton style={{marginLeft: "5px"}} onClick={toggleSection}>
                {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </IconButton>
        </div>
    );
}

export default VerticalNavSectionTitle
