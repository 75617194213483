import UserLayout from "../components/UserLayout";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../store';
import {getAllNotificationsAction} from '../api/notification';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export default function Notifications() {
    const dispatch = useDispatch<any>();
    const notifications = useSelector((state: AppState) => state.notification.allPaginatedNotifications?.entity);

    useEffect(() => {
        dispatch(getAllNotificationsAction(1));
    }, [dispatch]);

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>BİLDİRİMLER</h1>
            </div>
            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label="notifications table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Başlık</TableCell>
                            <TableCell>Açıklama</TableCell>
                            <TableCell>Kullanıcı</TableCell>
                            <TableCell>Durum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notifications && notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <TableRow key={notification.id}>
                                    <TableCell>{notification.title}</TableCell>
                                    <TableCell>{notification.description}</TableCell>
                                    <TableCell>{notification.user.firstName} {notification.user.lastName}</TableCell>
                                    <TableCell>{notification.read ? 'Okundu' : 'Okunmadı'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography variant="body2" align="center">
                                        Bildirim bulunamadı.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </UserLayout>
    );
}
