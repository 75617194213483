import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, Select} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CustomDialog from "../../components/CustomDialog";
import MenuItem from "@mui/material/MenuItem";
import {getAllUsersAction, getUsersAction} from "../../api/appUser";
import {editTaskAssigneesAction} from "../../api/task";
import {setAllPaginatedUsers, setAllUsersLookup} from "../../features/app-user/appUserSlice";

interface EditTaskAssigneesDialogProps {
    handleClose: () => void;
    handleEditTaskAssignees: () => void;
    taskId: number;
    currentAssigneeIds: number[];
}

export const EditTaskAssigneesDialog: React.FC<EditTaskAssigneesDialogProps> = ({
                                                                                    taskId,
                                                                                    currentAssigneeIds,
                                                                                    handleClose,
                                                                                    handleEditTaskAssignees
                                                                                }) => {
    const [assigneeIds, setAssigneeIds] = useState<number[]>(currentAssigneeIds);
    const [errors, setErrors] = useState<FieldErrors>({});
    const dispatch: any = useDispatch();
    const allPaginatedUsers = useSelector((state: AppState) => state.appUser.allPaginatedUsers);
    const allUsersLookup = useSelector((state: AppState) => state.appUser.allUsersLookup);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const associatedCompanyId = currentUser?.associatedCompany?.id;
    const useAssociatedCompanyId = currentUser?.associatedCompany?.id;
    const editTaskAssigneesTaskStatus = useSelector((state: AppState) => state.task.editTaskAssigneesTaskStatus);

    useEffect(() => {
        if (useAssociatedCompanyId) {
            dispatch(getUsersAction({companyIdFilter: currentUser?.associatedCompany?.id, page: 1}));
        } else {
            dispatch(getAllUsersAction());
        }
        return () => {
            dispatch(setAllPaginatedUsers(null));
            dispatch(setAllUsersLookup(null));
        };
    }, [dispatch]);

    const handleSaveClick = async () => {
        try {
            const result = await dispatch(editTaskAssigneesAction({
                taskId: taskId,
                assigneeIds
            }));
            if (result.type === "task/editTaskAssignees/fulfilled") {
                handleEditTaskAssignees();
            }
        } catch (error) {
            console.error("Atanan kullanıcılar güncellenemedi: ", error);
        }
    };

    const handleChangeAssignees = (event: any) => {
        const {value} = event.target;
        setAssigneeIds(value);
    };

    useEffect(() => {
        if (editTaskAssigneesTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editTaskAssigneesTaskStatus?.fieldErrors.forEach((it) => updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [editTaskAssigneesTaskStatus]);

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Atanan Kişileri Düzenle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                    <InputLabel id="assignee-label">Atanan Kişiler</InputLabel>
                    <Select
                        labelId="assignee-label"
                        id="assigneeIds"
                        name="assignees"
                        multiple
                        value={assigneeIds}
                        onChange={handleChangeAssignees}
                        label="Atanan Kişiler"
                        fullWidth>
                        {associatedCompanyId
                            ? allPaginatedUsers?.entity.map((paginatedUser) => (
                                <MenuItem key={paginatedUser.id} value={paginatedUser.id}>
                                    {paginatedUser.firstName} {paginatedUser.lastName}
                                </MenuItem>
                            ))
                            : allUsersLookup?.filter((lookup) => !associatedCompanyId || lookup.associatedCompany?.id === associatedCompanyId)
                                .map((assigneeLookup) => (
                                    <MenuItem key={assigneeLookup.id} value={assigneeLookup.id}>
                                        {assigneeLookup.value}
                                    </MenuItem>
                                ))}
                    </Select>
                    {errors.assignees && <ErrorText message={errors.assignees}/>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button
                    onClick={handleSaveClick}
                    color="primary"
                    disabled={editTaskAssigneesTaskStatus?.type === AsyncTaskStatusType.Loading}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
