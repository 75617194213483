import React, {useState} from 'react';
import {Button, IconButton, Input, Typography} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';

interface FileUploadProps {
    value: string | null;
    fieldName: string;
    uploadButtonLabel: string;
    setFileName: (fileName: string | null) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({fieldName, setFileName, uploadButtonLabel = "Dosya Ekle", value}) => {
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setError(null);

        if (file) {
            if (file.size > 5000000) { // Example size limit: 5MB
                setError('Dosya boyutu 5MB\'dan küçük olmalıdır.');
                return;
            }
            /*
            if (!['image/jpeg', 'image/png'].includes(file.type)) { // Example file types
                setError('Sadece JPEG veya PNG dosyaları yüklenebilir.');
                return;
            }*/

            setFileName(file.name);
        }
    };

    const handleClear = () => {
        setFileName(null);
        setError(null);
        const fileInput = document.getElementById('file-upload') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <div style={{textAlign: 'center'}}>
            <Input
                id="file-upload"
                type="file"
                name={fieldName}
                style={{display: 'none'}}
                onChange={handleFileChange}/>
            <label htmlFor="file-upload">
                <Button
                    variant="outlined"
                    component="span"
                    startIcon={<UploadIcon/>}
                    sx={{
                        borderRadius: 2,
                        padding: '8px 16px',
                        boxShadow: 2,
                        '&:hover': {
                            backgroundColor: 'grey.100',
                        }
                    }}>
                    {uploadButtonLabel}
                </Button>
            </label>
            {value && (
                <div style={{marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="body2" color="textSecondary" sx={{marginRight: 1}}>
                        Seçilen Dosya: {value}
                    </Typography>
                    <IconButton
                        onClick={handleClear}
                        color="error"
                        sx={{
                            borderRadius: '50%',
                            width: 32,
                            height: 32,
                            '&:hover': {
                                backgroundColor: 'error.light',
                            }
                        }}>
                        <ClearIcon/>
                    </IconButton>
                </div>
            )}
            {error && <Typography variant="body2" color="error" sx={{marginTop: 1}}>{error}</Typography>}
        </div>
    );
};

export default FileUpload;
