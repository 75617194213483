import {createAsyncThunk} from "@reduxjs/toolkit";
import {AddContractDTO, ContractStatus, ContractType} from "./dtos";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {getAppToken} from "../shared/utils";
import {AppState} from "../store";
import axios from "axios";
import {EndPoints} from "./EndPoints";
import {
    setAllPaginatedContracts,
    setCreateContractTaskStatus,
    setDeleteContractTaskStatus,
    setEditContractTaskStatus,
    setGetAllPaginatedContractsTaskStatus
} from "../features/contracts/contractSlice";
import {ErrorMessages, Headers} from './constants'

export const createContractAction = createAsyncThunk(
    'contract/createContract',
    async (dto: AddContractDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(EndPoints.contract, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllPaginatedContractsAction = createAsyncThunk(
    'contract/getAllContracts',
    async ({relatedCompanyId, type, status, page}: {
        relatedCompanyId: number | null;
        type: ContractType | null;
        status: ContractStatus | null;
        page: number;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setGetAllPaginatedContractsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({page: page.toString()});
            if (relatedCompanyId != null) params.append("relatedCompanyId", relatedCompanyId.toString());
            if (type != null) params.append("type", type);
            if (status != null) params.append("status", status);

            const response = await axios.get(EndPoints.contract + `/contracts-by-filter?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllPaginatedContractsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedContracts(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setGetAllPaginatedContractsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setGetAllPaginatedContractsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteContractAction = createAsyncThunk(
    'contract/deleteContract',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        dispatch(setDeleteContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.delete(`${EndPoints.contract}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return resultValue;
            } else {
                dispatch(setDeleteContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editContractAction = createAsyncThunk(
    'contract/editContract',
    async ({id, type, relatedSide, signDate, validUntil}: {
        id: number;
        type: ContractType;
        relatedSide: string;
        signDate: number | null;
        validUntil: number | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setEditContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const payload = {type, relatedSide, signDate, validUntil};

            const response = await axios.put(`${EndPoints.contract}/${id}`, payload, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setEditContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
