import {DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {editUserAction, getUsersAction} from "../../api/appUser";
import {useNavigate} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import {CompanyType, EditUserDTO, getPresentableUserRole, UserDTO, UserRole} from "../../api/dtos";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AppState} from "../../store";
import {setEditUserTaskStatus} from "../../features/app-user/appUserSlice";
import CustomDialog from "../../components/CustomDialog";
import {setAllCompanies} from "../../features/settings/settingsSlice";
import {getAllCompaniesAction} from "../../api/settings";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

interface EditUserDialogProps {
    handleClose: () => void;
    page: number;
    user: UserDTO;
}

interface EditUserFormData {
    firstName: string;
    lastName: string;
    role: string;
    associatedCompanyId: number | null;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = ({handleClose, page, user}) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [formData, setFormData] = useState<EditUserFormData>({
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        associatedCompanyId: user.associatedCompany?.id || null
    });
    const editUserTaskStatus = useSelector((state: AppState) => state.appUser.editUserTaskStatus);
    const allCompanies = useSelector((state: AppState) => state.settings.allCompanies);

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAutocompleteChange = (event: any, value: any) => {
        setFormData({
            ...formData,
            associatedCompanyId: value?.id || null
        });
    };

    const convertDTO = (data: EditUserFormData): EditUserDTO => ({
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role as UserRole,
        associatedCompanyId: data.associatedCompanyId
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const result = await dispatch(editUserAction({id: user.id, dto: convertDTO(formData)}));

        if (result.type === 'user/editUser/fulfilled') {
            handleClose();
            if (page === 1) {
                dispatch(getUsersAction({page: 1}));
            } else {
                navigate("");
            }
        }
    };

    useEffect(() => {
        if (editUserTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editUserTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [editUserTaskStatus]);

    useEffect(() => {
        dispatch(getAllCompaniesAction());
        return () => {
            dispatch(setEditUserTaskStatus(null));
            dispatch(setAllCompanies(null));
        }
    }, [dispatch]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Kullanıcı Düzenle ({user.firstName} {user.lastName})</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="firstName"
                        name="firstName"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.firstName}
                        onChange={handleChangeFormData}/>
                    {errors.firstName && (
                        <ErrorText message={errors.firstName}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="lastName"
                        name="lastName"
                        label="Soyadı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.lastName}
                        onChange={handleChangeFormData}/>
                    {errors.lastName && (
                        <ErrorText message={errors.lastName}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="role-label">Rol</InputLabel>
                    <Select
                        required
                        labelId="role-label"
                        id="role"
                        name="role"
                        label="Rol"
                        fullWidth
                        value={formData.role}
                        onChange={handleChangeFormData}>
                        <MenuItem value={UserRole.Manager}>{getPresentableUserRole(UserRole.Manager)}</MenuItem>
                        <MenuItem value={UserRole.Member}>{getPresentableUserRole(UserRole.Member)}</MenuItem>
                        <MenuItem value={UserRole.Company}>{getPresentableUserRole(UserRole.Company)}</MenuItem>
                    </Select>
                    {errors.role && (
                        <ErrorText message={errors.role}/>
                    )}
                </FormControl>

                {formData.role === UserRole.Company && (
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            options={allCompanies || []}
                            groupBy={(option) => option.type === CompanyType.Branch ? "ŞUBELER" : "İŞTİRAKLER"}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={allCompanies?.find(company => company.id === formData.associatedCompanyId) || null}
                            onChange={handleAutocompleteChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Şube/İştirakler"
                                    variant="outlined"
                                    error={Boolean(errors.associatedCompanyId)}
                                    helperText={errors.associatedCompanyId && errors.associatedCompanyId}/>
                            )}/>
                    </FormControl>
                )}
                {editUserTaskStatus?.type === AsyncTaskStatusType.Error && editUserTaskStatus.errorMessage && (
                    <ErrorText type="form" message={editUserTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Düzenle</Button>
            </DialogActions>
        </CustomDialog>
    );
};
