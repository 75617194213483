import * as React from 'react';
import {useEffect, useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import {Button, IconButton, InputAdornment, Popover, TextField} from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import DatePickerWrapper from '../theme/@core/styles/libs/react-datepicker';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

const styles = {
    datePickerHolder: {
        minWidth: "116px",
        flex: 1
    },
    pickerHeader: {
        width: "100%",
        textAlign: "center"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "5px",
        paddingBottom: "10px"
    }
};

interface CustomDateRangePickerProps {
    startDate: Date | null;
    endDate: Date | null;
    applyStartDateFilter: (date: Date | null) => void;
    applyEndDateFilter: (date: Date | null) => void;
}

export const DateRangePicker: React.FC<CustomDateRangePickerProps> = ({
                                                                          startDate,
                                                                          endDate,
                                                                          applyEndDateFilter,
                                                                          applyStartDateFilter
                                                                      }) => {
    const [innerStartDate, setInnerStartDate] = useState<Date | null>(startDate);
    const [innerEndDate, setInnerEndDate] = useState<Date | null>(endDate);

    useEffect(() => {
        setInnerStartDate(startDate);
        setInnerEndDate(endDate);
    }, [startDate, endDate]);

    const handleClose = () => setAnchorEl(null);

    const handleSave = () => {
        applyStartDateFilter(innerStartDate);
        applyEndDateFilter(innerEndDate);
        handleClose();
    }

    const clearSaved = (event: React.MouseEvent) => {
        event.stopPropagation();
        applyStartDateFilter(null);
        applyEndDateFilter(null);
        handleClose();
    }

    const onClearStartDateClick = () => setInnerStartDate(null);

    const onClearEndDateClick = () => setInnerEndDate(null);

    const [anchorEl, setAnchorEl] = useState(null);

    const emptyDateText = "        ---      ";

    const formattedDateRange = startDate || endDate
        ? `${startDate ? moment(startDate).format('DD/MM/YYYY') : emptyDateText} - ` +
        `${endDate ? moment(endDate).format('DD/MM/YYYY') : emptyDateText}`
        : "";

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);

    const open = Boolean(anchorEl);
    const id = open ? 'date-range-picker-popover' : undefined;

    return (
        <div style={{position: "relative", width: "min-content"}}>
            <TextField
                margin="dense"
                size="small"
                variant="outlined"
                onClick={handleClick}
                label="Tarih Aralığı"
                value={formattedDateRange}
                fullWidth
                InputProps={{
                    sx: {width: "295px"},
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {(startDate || endDate) && (
                                <IconButton onClick={clearSaved}>
                                    <ClearIcon/>
                                </IconButton>
                            )}
                            <IconButton>
                                <DateRangeIcon/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}/>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <DatePickerWrapper>
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "10px"
                        }}>
                            <div style={{
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    height: "40px",
                                    width: "100%",
                                    position: "relative",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Typography sx={styles.pickerHeader}>Başlangıç Tarihi</Typography>
                                    <IconButton
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            visibility: innerStartDate == null ? "hidden" : "visible"
                                        }}
                                        onClick={onClearStartDateClick}>
                                        <ClearIcon/>
                                    </IconButton>
                                </div>
                                <DatePicker
                                    locale="tr"
                                    inline={true}
                                    selected={innerStartDate}
                                    selectsStart={true}
                                    startDate={innerStartDate}
                                    maxDate={innerEndDate}
                                    endDate={innerEndDate}
                                    onChange={setInnerStartDate}/>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    height: "40px",
                                    width: "100%",
                                    position: "relative",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Typography sx={styles.pickerHeader}>Bitiş Tarihi</Typography>
                                    <IconButton
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            visibility: innerEndDate == null ? "hidden" : "visible"
                                        }}
                                        onClick={onClearEndDateClick}>
                                        <ClearIcon/>
                                    </IconButton>
                                </div>

                                <DatePicker
                                    locale="tr"
                                    inline={true}
                                    selected={innerEndDate}
                                    selectsEnd={true}
                                    startDate={innerStartDate}
                                    minDate={innerStartDate}
                                    endDate={innerEndDate}
                                    onChange={setInnerEndDate}/>
                            </div>
                        </div>
                        <div style={styles.buttonContainer}>
                            <Button variant="contained" color="error" onClick={() => {
                                onClearStartDateClick();
                                onClearEndDateClick();
                            }}>
                                Temizle
                            </Button>
                            <Button variant="contained" onClick={handleSave}>
                                Tamamla
                            </Button>
                        </div>
                    </div>
                </DatePickerWrapper>
            </Popover>
        </div>
    );
}
