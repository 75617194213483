import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {AppLogDTO, AppUserLookupDTO, PaginatedEntityList, UserDTO} from "../../api/dtos";

export interface AppUserState {
    loadAllUsersLookupTaskStatus: AsyncTaskStatus | null;
    createUserTaskStatus: AsyncTaskStatus | null;
    resetPasswordTaskStatus: AsyncTaskStatus | null;
    resetOwnPasswordTaskStatus: AsyncTaskStatus | null;
    editUserTaskStatus: AsyncTaskStatus | null;
    allPaginatedUsers: PaginatedEntityList<UserDTO> | null;
    allUsersLookup: AppUserLookupDTO[] | null;
    editUserPermissionsTaskStatus: AsyncTaskStatus | null;
    getLogsTaskStatus: AsyncTaskStatus | null;
    logs: PaginatedEntityList<AppLogDTO> | null;
}

const initialState: AppUserState = {
    createUserTaskStatus: null,
    loadAllUsersLookupTaskStatus: null,
    editUserTaskStatus: null,
    resetPasswordTaskStatus: null,
    resetOwnPasswordTaskStatus: null,
    allPaginatedUsers: null,
    allUsersLookup: null,
    editUserPermissionsTaskStatus: null,
    getLogsTaskStatus: null,
    logs: null,
};

const appUserSlice = createSlice({
    name: 'appUser',
    initialState,
    reducers: {
        setAllUsersLookup: (state, action: PayloadAction<AppUserLookupDTO[] | null>) => {
            state.allUsersLookup = action.payload;
        },
        setCreateUserTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createUserTaskStatus = action.payload;
        },
        setGetAllUsersLookupTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllUsersLookupTaskStatus = action.payload;
        },
        setAllPaginatedUsers: (state, action: PayloadAction<PaginatedEntityList<UserDTO> | null>) => {
            state.allPaginatedUsers = action.payload;
        },
        setResetPasswordTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.resetPasswordTaskStatus = action.payload;
        },
        setResetOwnPasswordTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.resetOwnPasswordTaskStatus = action.payload;
        },
        setEditUserTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editUserTaskStatus = action.payload;
        },
        setEditUserPermissionsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editUserPermissionsTaskStatus = action.payload;
        },
        setGetLogsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getLogsTaskStatus = action.payload;
        },
        setLogs: (state, action: PayloadAction<PaginatedEntityList<AppLogDTO> | null>) => {
            state.logs = action.payload;
        }
    }
});

export const {
    setCreateUserTaskStatus,
    setGetAllUsersLookupTaskStatus,
    setAllUsersLookup,
    setAllPaginatedUsers,
    setEditUserTaskStatus,
    setResetPasswordTaskStatus,
    setResetOwnPasswordTaskStatus,
    setEditUserPermissionsTaskStatus,
    setGetLogsTaskStatus,
    setLogs
} = appUserSlice.actions;

export default appUserSlice.reducer;
