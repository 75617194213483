import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {UserDTO} from "../../api/dtos";
import {StorageKeys} from "../../api/constants";

export const getToken = () => localStorage.getItem(StorageKeys.TOKEN) || sessionStorage.getItem(StorageKeys.TOKEN) || null;
export const getRefreshToken = () => localStorage.getItem(StorageKeys.REFRESH_TOKEN) || sessionStorage.getItem(StorageKeys.REFRESH_TOKEN) || null;
export const getTokenExpiry = () => localStorage.getItem(StorageKeys.TOKEN_EXPIRY) || sessionStorage.getItem(StorageKeys.TOKEN_EXPIRY)
    ? Number(localStorage.getItem(StorageKeys.TOKEN_EXPIRY) || sessionStorage.getItem(StorageKeys.TOKEN_EXPIRY))
    : null;

export interface AuthState {
    userWithToken: {
        //TODO: add an interface
        user: UserDTO | null;
        token: string | null;
        refreshToken: string | null;
        tokenExpiryIn: number | null;
    } | null;
    loginTaskStatus: AsyncTaskStatus | null;
    refreshTokenTaskStatus: AsyncTaskStatus | null;
}

const initialState: AuthState = {
    userWithToken: {
        user: null,
        token: getToken(),
        refreshToken: getRefreshToken(),
        tokenExpiryIn: getTokenExpiry()
    },
    loginTaskStatus: null,
    refreshTokenTaskStatus: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticationResult: (state, action) => {
            state.userWithToken = action.payload;
        },
        setLoginTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loginTaskStatus = action.payload;
        },
        setRefreshTokenTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.refreshTokenTaskStatus = action.payload;
        },
        logout: (state) => {
            state.userWithToken = null;
            state.loginTaskStatus = null;
        }
    }
});

export const {setAuthenticationResult, setLoginTaskStatus, setRefreshTokenTaskStatus, logout} = authSlice.actions;

export default authSlice.reducer;
