import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {getStatisticsAction} from '../api/legalCase';
import {AppState} from '../store';
import {useTheme} from '@mui/material/styles';
import UserLayout from "./UserLayout";
import ApexChartWrapper from "../theme/@core/styles/libs/react-apexcharts";
import {getPresentableLegalCaseStatus, LegalCaseStatus} from "../api/dtos";

const CaseStatusStatistic = () => {
    const theme = useTheme();
    const dispatch: any = useDispatch();

    const statistics = useSelector((state: AppState) => state.legalCase.statistics);

    useEffect(() => {
        dispatch(getStatisticsAction());
    }, [dispatch]);

    if (!statistics) {
        return null;
    }

    const createOptions = (categories: string[]): ApexOptions => ({
        chart: {
            parentHeightOffset: 0,
            toolbar: {show: false},
            stacked: false,
            zoom: {enabled: false},
            selection: {enabled: false},
        },
        plotOptions: {bar: {borderRadius: 4, distributed: false, columnWidth: "60px"}},
        stroke: {width: 2, colors: [theme.palette.background.paper]},
        dataLabels: {enabled: false},
        legend: {show: true},
        xaxis: {
            categories: categories,
            labels: {show: true},
            axisTicks: {show: false},
            axisBorder: {show: false}
        },
        yaxis: {
            show: true,
            min: 0,
            labels: {
                offsetX: -17,
                formatter: (val) => Math.ceil(val).toString()
            }
        },
        grid: {
            strokeDashArray: 7,
            padding: {top: -1, right: 0, left: -12, bottom: 5}
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `Dava Sayısı: ${val}`
            }
        },
        colors: [theme.palette.primary.main, '#0000ff','#6adf14','#4375d6']
    });

    const series = Object.keys(LegalCaseStatus)
        .map((legalCaseStatus) => {
            const status = statistics.statisticsByStatus
                .find((stat) => stat.name === legalCaseStatus);

            return {
                name: getPresentableLegalCaseStatus(legalCaseStatus as LegalCaseStatus),
                data: [status?.count || 0]
            }
        });

    return (
        <UserLayout>
            <ApexChartWrapper>
                <Card>
                    <CardContent>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Dava Durumu
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Bu grafik, davaların durumlarına göre dağılımını gösterir.
                            </Typography>
                            <Chart
                                type="bar"
                                height={300}
                                options={createOptions(["Durum"])}
                                series={series}/>
                        </Grid>
                    </CardContent>
                </Card>
            </ApexChartWrapper>
        </UserLayout>
    );
};

export default CaseStatusStatistic;
