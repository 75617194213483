import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {AddCircle, SwapHoriz} from "@mui/icons-material";
import {LegalCaseDTO, UserPermission} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import Tooltip from '@mui/material/Tooltip';
import {adminOrHasPermission} from "../shared/utils";

interface LegalCaseActionProps {
    legalCase: LegalCaseDTO;
    onAddFile: (legalCase: LegalCaseDTO) => void;
    onChangeStatus: (legalCase: LegalCaseDTO) => void;
    onAddTask: (legalCase: LegalCaseDTO) => void;
}

const LegalCaseAction: React.FC<LegalCaseActionProps> = ({
                                                             legalCase,
                                                             onAddFile,
                                                             onChangeStatus,
                                                             onAddTask,
                                                         }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    if (!user) return null;
    const addFile = () => onMenuItemClick(() => onAddFile(legalCase));
    const changeStatus = () => onMenuItemClick(() => onChangeStatus(legalCase));
    const addTask = () => onMenuItemClick(() => onAddTask(legalCase));
    const caseFileAdd = adminOrHasPermission(user, UserPermission.CASE_FILE_ADD);
    const caseStatusUpdate = adminOrHasPermission(user, UserPermission.CASE_STATUS_UPDATE);
    const caseTaskAdd = adminOrHasPermission(user, UserPermission.TASK_ADD);
    const hasAtLeastOneAction = caseFileAdd || caseStatusUpdate || caseTaskAdd;

    return hasAtLeastOneAction
        ? (
            <>
                <IconButton
                    aria-controls={open ? 'action-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size='small'
                    aria-label='settings'
                    className='card-more-options'
                    sx={{color: 'text.secondary'}}>
                    <DotsVertical/>
                </IconButton>
                <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    {caseFileAdd && (
                        <MenuItem onClick={addFile}>
                            <IconButton size='small' aria-label='add-file' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <AddCircle/>
                            </IconButton>
                            Dosya Ekle
                        </MenuItem>
                    )}
                    {caseStatusUpdate && (
                        <MenuItem onClick={changeStatus}>
                            <IconButton size='small' aria-label='change-status' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <SwapHoriz/>
                            </IconButton>
                            Durumu Değiştir
                        </MenuItem>
                    )}
                    {caseTaskAdd && (
                        <MenuItem onClick={addTask}>
                            <IconButton size='small' aria-label='add-task' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <AddCircle/>
                            </IconButton>
                            Görev Ekle
                        </MenuItem>
                    )}
                </Menu>
            </>
        )
        : (
            <Tooltip title="Bu işlem için yetkiniz yok!">
                <IconButton
                    size="small"
                    aria-label="settings"
                    className="card-more-options"
                    sx={{color: 'action.disabled'}}>
                    <DotsVertical/>
                </IconButton>
            </Tooltip>
        );
};

export default LegalCaseAction;
