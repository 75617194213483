import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {CourtDTO, CourtType, getPresentableCourtType} from "../../api/dtos";
import {setEditCourtTaskStatus, setLoadCourtHousesTaskStatus} from "../../features/settings/settingsSlice";
import {editCourtAction, getAllCourtHousesAction, getLoadCitiesAction} from "../../api/settings";
import {TextFieldVariants} from "@mui/material/TextField/TextField";

interface EditCourtDialogProps {
    handleClose: () => void;
    handleEditCourt: (cityId: number, courtHouseId: number) => void;
    court: CourtDTO;
}

interface EditCourtFormData {
    name: string;
    type: CourtType;
    courtHouseId: number;
    cityId: number;
}

const fieldVariant = "outlined";

export const EditCourtDialog: React.FC<EditCourtDialogProps> = ({handleClose, handleEditCourt, court}) => {
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const [formData, setFormData] = useState<EditCourtFormData>({
        name: court.name,
        type: court.type,
        cityId: court.courtHouse.city.plateId,
        courtHouseId: court.courtHouse.id
    });

    const allCourtHouses = useSelector((state: AppState) => state.settings.allCourtHouses);
    const editCourtTaskStatus = useSelector((state: AppState) => state.settings.editCourtTaskStatus);

    useEffect(() => {
        dispatch(getAllCourtHousesAction());
        dispatch(getLoadCitiesAction());

        return () => {
            dispatch(setLoadCourtHousesTaskStatus(null));
            dispatch(setEditCourtTaskStatus(null));
        };
    }, [dispatch]);

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData((prev) => ({
            ...prev,
            [name as string]: value
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const result = await dispatch(editCourtAction({
            id: court.id,
            ...formData
        }));

        if (result.type === 'courts/editCourt/fulfilled') {
            handleEditCourt(formData.cityId, formData.courtHouseId);
        }
    };

    useEffect(() => {
        if (editCourtTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editCourtTaskStatus.fieldErrors.forEach(({field, message}) => {
                updatedErrors[field] = message;
            });
            setErrors(updatedErrors);
        }
    }, [editCourtTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Mahkeme Düzenle ({court.name})</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Mahkeme Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChangeFormData}/>
                    {errors.name && <ErrorText message={errors.name}/>}
                </FormControl>

                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                    <InputLabel id="type-label">Mahkeme Türü</InputLabel>
                    <Select
                        labelId="type-label"
                        id="type"
                        name="type"
                        label="Mahkeme Türü"
                        value={formData.type}
                        onChange={handleChangeFormData}
                        required>
                        {Object.values(CourtType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {getPresentableCourtType(type)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.type && <ErrorText message={errors.type}/>}
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="city-id-label">Şehir <span>*</span></InputLabel>
                    <Select
                        labelId="city-label"
                        id="cityId"
                        name="cityId"
                        label="Şehirler"
                        required
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.cityId}
                        onChange={handleChangeFormData}>
                        {allCitiesLookup?.map((cityLookup: any) => (
                            <MenuItem key={cityLookup.id} value={cityLookup.id}>
                                {cityLookup.value}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.cityId && (
                        <ErrorText message={errors.cityId}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                    <InputLabel id="court-house-label">Adliye</InputLabel>
                    <Select
                        labelId="court-house-label"
                        id="courtHouseId"
                        name="courtHouseId"
                        label="Adliye"
                        value={formData.courtHouseId}
                        onChange={handleChangeFormData}
                        required>
                        {allCourtHouses?.filter((courtHouse) => courtHouse.city.plateId === formData.cityId)
                            .map(courtHouse => (
                                <MenuItem key={courtHouse.id} value={courtHouse.id}>
                                    {courtHouse.name}
                                </MenuItem>
                            ))}
                    </Select>
                    {errors.courtHouseId && <ErrorText message={errors.courtHouseId}/>}
                </FormControl>

                {editCourtTaskStatus?.type === AsyncTaskStatusType.Error && editCourtTaskStatus.errorMessage && (
                    <ErrorText type="form" message={editCourtTaskStatus.errorMessage}/>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Düzenle</Button>
            </DialogActions>
        </CustomDialog>
    );
};
