import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {editUserPermissionsAction, getUsersAction} from "../../api/appUser";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AppState} from "../../store";
import {setEditUserPermissionsTaskStatus} from "../../features/app-user/appUserSlice";
import CustomDialog from "../../components/CustomDialog";
import {getPresentableUserRole, UserPermission, UserRole} from "../../api/dtos";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {useNavigate} from "react-router-dom";
import {EditPermissionDialog} from "../create-user/createUserDialog";

interface EditPermissionsDialogProps {
    handleClose: () => void;
    userId: number;
    currentPermissions: UserPermission[];
    currentRole: UserRole;
    page: number;
}

export const EditPermissionsDialog: React.FC<EditPermissionsDialogProps> = ({
                                                                                page,
                                                                                handleClose,
                                                                                userId,
                                                                                currentPermissions,
                                                                                currentRole,
                                                                            }) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [formData, setFormData] = useState({
        permissions: currentPermissions,
        role: currentRole,
    });
    const editPermissionsTaskStatus = useSelector((state: AppState) => state.appUser.editUserPermissionsTaskStatus);
    const [isPermissionsDialogOpen, setPermissionsDialogOpen] = useState(false);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            role: currentRole,
        }));
    }, [currentRole]);

    const handleChange = (event: any) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const result = await dispatch(
            editUserPermissionsAction({
                id: userId,
                dto: {permissions: formData.permissions, role: formData.role},
            })
        );

        if (result.type === "user/editUserPermissions/fulfilled") {
            handleClose();
            if (page === 1) {
                dispatch(getUsersAction({page: 1}));
            } else {
                navigate("");
            }
        }
    };

    useEffect(() => {
        if (editPermissionsTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editPermissionsTaskStatus?.fieldErrors.forEach(
                (it) => (updatedErrors[it.field] = it.message)
            );
            setErrors(updatedErrors);
        }
    }, [editPermissionsTaskStatus]);

    useEffect(() => {
        return () => {
            dispatch(setEditUserPermissionsTaskStatus(null));
        };
    }, [dispatch]);

    const handleOpenPermissionsDialog = () => {
        setPermissionsDialogOpen(true);
    };

    const handleClosePermissionsDialog = () => {
        setPermissionsDialogOpen(false);
    };

    const handleSetPermissions = (permissions: UserPermission[]) => {
        setFormData((prevData) => ({...prevData, permissions}));
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Kullanıcı İzinlerini ve Rolünü Düzenle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="role-label">Rol</InputLabel>
                    <Select
                        labelId="role-label"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        label="Rol">
                        {Object.values(UserRole)
                            .filter((role) => role !== UserRole.Admin)
                            .map((role) => (
                                <MenuItem key={role} value={role}>
                                    {getPresentableUserRole(role)}
                                </MenuItem>
                            ))}
                    </Select>

                    {errors.role && <ErrorText message={errors.role}/>}
                </FormControl>

                {editPermissionsTaskStatus?.type === AsyncTaskStatusType.Error &&
                    editPermissionsTaskStatus.errorMessage && (
                        <ErrorText type="form" message={editPermissionsTaskStatus.errorMessage}/>
                    )}

                {formData.role !== UserRole.Manager && (
                    <Button
                        variant="text"
                        onClick={handleOpenPermissionsDialog}
                        sx={{mt: 1, display: "block"}}>
                        İZİNLERİ DÜZENLE
                    </Button>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Kaydet</Button>
            </DialogActions>

            <EditPermissionDialog
                open={isPermissionsDialogOpen}
                onClose={handleClosePermissionsDialog}
                permissions={formData.permissions}
                setPermissions={handleSetPermissions}/>
        </CustomDialog>
    );
};
