import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import FileUpload from "../../components/fileUpload";
import {
    AddLegalCaseDTO,
    AddLegalCaseMetadata,
    AddLitigantToCaseDTO,
    CaseType,
    CompanyDTO,
    CourtDTO,
    CourtType,
    LegalCaseStatus,
    SettingsLookupDTO,
    UserRole
} from "../../api/dtos";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {
    getAllClientsAction,
    getAllCourtHousesAction,
    getAllCourtsAction,
    getLoadCitiesAction
} from "../../api/settings";
import {createLegalCaseAction, getLegalCasesAction} from "../../api/legalCase";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import Chip from "@mui/material/Chip";
import {styled} from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import Fab from "@mui/material/Fab";
import {AddProsecutorDialog} from "./addProsecutorDialog";
import {AddDefendantsDialog} from "./addDefendantsDialog";
import {AddReportedEntitiesDialog} from "./addReportedEntitiesDialog";
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import {AddTrialDateDialog} from "./addTrialDateDialog";
import {v4 as uuidv4} from 'uuid';
import {
    setAllCitiesLookup,
    setAllClientsLookup,
    setAllCompanies,
    setAllCourtHouses,
    setAllCourts
} from "../../features/settings/settingsSlice";
import {LitigantType} from "../../api/litigantType";
import OutlinedInput from "@mui/material/OutlinedInput";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {setCreateLegalCaseTaskStatus} from "../../features/legal-case/legalCaseSlice";
import {tr} from 'date-fns/locale';
import {getAllUsersAction} from "../../api/appUser";
import CustomDialog from "../../components/CustomDialog";
import {Autocomplete} from "@mui/lab";

export interface AddLitigantType {
    litigantType: LitigantType;
    selection?: CompanyDTO | SettingsLookupDTO;
    name?: string;
}

interface CreateLegalCaseDialogProps {
    handleClose: () => void;
    onAddLegalCase: () => void;
    page: number;
    caseType: CaseType;
}

interface AddCreateLegalCaseFormData {
    file: string | null;
    name: string;
    fileNumber: string;
    corporateFileNumber: string;
    investigationDate: string;
    prosecutors: AddLitigantToCaseDTO[];
    defendants: AddLitigantToCaseDTO[];
    reportedEntities: AddLitigantToCaseDTO[];
    caseDate: Date;
    cityId: number | null;
    courtId: number | null;
    courtHouseId: number | null;
    caseType: CaseType;
    status: LegalCaseStatus;
    trialDates: string[];
    responsibleIds: number[];
    appeal: string | null;
    notes: string | null;
}

const fieldVariant = "outlined"

export const CreateLegalCaseDialog: React.FC<CreateLegalCaseDialogProps> = ({
                                                                                handleClose,
                                                                                onAddLegalCase,
                                                                                caseType,
                                                                                page
                                                                            }) => {
    const [addTrialDatesDialogIsOpen, setAddTrialDatesDialogIsOpen] = useState(false);

    const handleAddTrialDatesDialogOpen = () => setAddTrialDatesDialogIsOpen(true);
    const handleAddTrialDatesDialogClose = () => setAddTrialDatesDialogIsOpen(false);

    const [addProsecutorDialogIsOpen, setAddProsecutorDialogIsOpen] = useState(false);

    const handleAddProsecutorDialogOpen = () => setAddProsecutorDialogIsOpen(true);
    const handleAddProsecutorDialogClose = () => setAddProsecutorDialogIsOpen(false);

    const [trialDatesChipData, setTrialDatesChipData] = React.useState<readonly ChipData[]>([]);

    const [addDefendantsDialogIsOpen, setAddDefendantsDialogIsOpen] = useState(false);

    const handleAddDefendantsDialogOpen = () => setAddDefendantsDialogIsOpen(true);
    const handleAddDefendantsDialogClose = () => setAddDefendantsDialogIsOpen(false);

    const [addReportedEntitiesDialogIsOpen, setAddReportedEntitiesDialogIsOpen] = useState(false);

    const handleAddReportedEntitiesDialogOpen = () => setAddReportedEntitiesDialogIsOpen(true);
    const handleAddReportedEntitiesDialogClose = () => setAddReportedEntitiesDialogIsOpen(false);

    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const allCourtHouses = useSelector((state: AppState) => state.settings.allCourtHouses);
    const allCourts = useSelector((state: AppState) => state.settings.allCourts);
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const allUsersLookup = useSelector((state: AppState) => state.appUser.allUsersLookup);
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);

    useEffect(() => {
        dispatch(getAllClientsAction());
        dispatch(getAllCourtHousesAction())
        dispatch(getAllCourtsAction());
        dispatch(getLoadCitiesAction());
        dispatch(getAllUsersAction());

        return () => {
            dispatch(setAllClientsLookup(null));
            dispatch(setAllCourtHouses(null));
            dispatch(setAllCompanies(null));
            dispatch(setAllCourts(null));
            dispatch(setAllCitiesLookup(null));
            dispatch(setCreateLegalCaseTaskStatus(null));
        }
    }, [dispatch]);

    const defaultFormData: AddCreateLegalCaseFormData = {
        file: null,
        name: '',
        fileNumber: "",
        corporateFileNumber: "",
        investigationDate: "",
        prosecutors: [],
        defendants: [],
        reportedEntities: [],
        caseDate: new Date(),
        cityId: null,
        courtId: null,
        courtHouseId: null,
        caseType: CaseType.Law,
        status: LegalCaseStatus.Open,
        trialDates: [],
        responsibleIds: [],
        appeal: null,
        notes: null
    };

    const [formData, setFormData] = useState<AddCreateLegalCaseFormData>(defaultFormData);
    const [prosecutorsChipData, setProsecutorsChipData] = React.useState<readonly ExtendedChipData[]>([]);

    const [defendantsChipData, setDefendantsChipData] = React.useState<readonly ExtendedChipData[]>([]);

    const [reportedEntitiesChipData, setReportedEntitiesChipData] = React.useState<readonly ExtendedChipData[]>([]);
    const [responsibleIds, setResponsibleIds] = useState<number[]>([]);

    const setLegalCaseFileName = (fileName: string | null) => {
        setFormData({
            ...formData, file: fileName
        });
    }

    const handleChangeFormData = (event: any) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                // @ts-ignore
                [name]: Array.from(event.target.selectedOptions, (option) => option.value)
            });
        } else {
            let sideEffects = {};
            if (name === "cityId") {
                sideEffects = {
                    courtId: null,
                    courtHouseId: null
                };
            }
            if (name === "courtHouseId") {
                sideEffects = {
                    courtId: null
                };
            }
            setFormData({
                ...formData,
                [name]: value,
                ...sideEffects
            });
        }
    };

    const handleDateChange = (name: any) => (newDate: any) => {
        if (name === 'trialDates' && newDate) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                [name]: [...prevFormData[name], newDate]
            }));
            setTrialDatesChipData((chips) => [
                ...chips,
                {id: new Date().toISOString(), value: moment(newDate).format("YYYY-MM-DD HH:mm:ss")}
            ]);
        } else {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                [name]: newDate
            }));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const newErrors: FieldErrors = {};

        if (!formData.caseDate) {
            newErrors.caseDate = "Dava tarihi zorunludur.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const ddd: AddLegalCaseMetadata = {
            file: new FormData(event.currentTarget).get('file') as any,
            metadata: {
                name: formData.name,
                prosecutorIds: formData.prosecutors,
                defendantIds: formData.defendants,
                reportedEntityIds: formData.reportedEntities,
                courtId: formData.courtId,
                type: caseType,
                status: formData.status,
                fileNumber: formData.fileNumber,
                corporateFileNumber: formData.corporateFileNumber,
                caseDate: moment(formData.caseDate).unix(),
                investigationDate: formData.investigationDate,
                litigationType: null,
                trialDates: formData.trialDates.map((it: any) => moment(it).unix()),
                relatedCaseIds: [],
                notes: formData.notes,
                appeal: formData.appeal,
                responsibleIds,
            } as AddLegalCaseDTO,
        };

        const submitFormData = new FormData();
        submitFormData.append("file", ddd.file);
        submitFormData.append("metadata", new Blob([JSON.stringify(ddd.metadata)], {type: "application/json"}));

        try {
            const result = await dispatch(createLegalCaseAction(submitFormData));
            if (result.type === 'legalCase/createLegalCase/fulfilled') {
                onAddLegalCase();
                handleClose();
                if (page === 1) {
                    const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;
                    dispatch(getLegalCasesAction({
                        companyIdFilter: null,
                        clientIdFilter: null,
                        nameFilter: null,
                        typeFilter: caseType,
                        courtIdFilter: null,
                        statusFilter: null,
                        courtHouseFilter: null,
                        fileNumberFilter: null,
                        responsibleIdFilter: isAdminOrManager ? null : user?.id,
                        page: 1,
                    }));
                } else {
                    navigate("");
                }
            }
        } catch (error) {
            console.error("Form gönderiminde bir hata oluştu:", error);
        }
    };

    interface ChipData {
        value: string;
        id: string | number;
    }

    interface ExtendedChipData extends ChipData {
        type: LitigantType;
    }

    const ListItem = styled('li')(({theme}) => ({
        margin: theme.spacing(0.5),
    }));

    const handleDeleteProsecutorsChipData = (chipToDelete: ExtendedChipData) => () => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            prosecutors: prevFormData.prosecutors.filter((prosecutor: any) => {
                    if (prosecutor.type === chipToDelete.type) {
                        if (chipToDelete.type === LitigantType.Client && prosecutor.clientId === chipToDelete.id) {
                            return false;
                        } else if (chipToDelete.type === LitigantType.Company && prosecutor.companyId === chipToDelete.id) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        }));
        setProsecutorsChipData((chips) => chips.filter((chip) =>
            chip.id !== chipToDelete.id || chip.type !== chipToDelete.type));
    };

    const handleDeleteDefendantsChipData = (chipToDelete: ExtendedChipData) => () => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            defendants: prevFormData.defendants.filter((prosecutor: any) => {
                    if (prosecutor.type === chipToDelete.type) {
                        if (chipToDelete.type === LitigantType.Client && prosecutor.clientId === chipToDelete.id) {
                            return false;
                        } else if (chipToDelete.type === LitigantType.Company && prosecutor.companyId === chipToDelete.id) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        }));
        setDefendantsChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id || chip.type !== chipToDelete.type));
    };

    const handleDeleteReportedEntitiesChipData = (chipToDelete: ExtendedChipData) => () => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            reportedEntities: prevFormData.reportedEntities.filter((prosecutor: any) => {
                    if (prosecutor.type === chipToDelete.type) {
                        if (chipToDelete.type === LitigantType.Client && prosecutor.clientId === chipToDelete.id) {
                            return false;
                        } else if (chipToDelete.type === LitigantType.Company && prosecutor.companyId === chipToDelete.id) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        }));
        setReportedEntitiesChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id || chip.type !== chipToDelete.type));
    };

    const handleDeleteTrialDateChipData = (chipToDelete: ChipData) => () => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            trialDates: prevFormData.trialDates.filter((trialDate: any) => {
                return moment(trialDate).format("YYYY-MM-DDTHH:mm:ss") !== chipToDelete.value;
            })
        }));
        setTrialDatesChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
    };

    const addProsecutor = (result: AddLitigantType) => {
        const prosecutorName = result.litigantType === LitigantType.Company
            ? (result.selection as CompanyDTO).name
            : (result.selection as SettingsLookupDTO).value;

        setFormData((formData: any) => ({
            ...formData,
            prosecutors: [
                ...formData.prosecutors,
                {
                    companyId: result.litigantType === LitigantType.Company ? (result.selection as CompanyDTO).id : null,
                    clientId: result.litigantType === LitigantType.Client ? (result.selection as SettingsLookupDTO).id : null,
                    name: prosecutorName,
                    type: result.litigantType
                } as AddLitigantToCaseDTO
            ]
        }));

        setProsecutorsChipData((chips) => [
            ...chips,
            {
                id: [LitigantType.Client, LitigantType.Company].includes(result.litigantType)
                    ? (result.selection as CompanyDTO | SettingsLookupDTO).id
                    : uuidv4(),
                value: prosecutorName,
                type: result.litigantType
            }
        ]);
    }

    const addDefendant = (result: AddLitigantType) => {
        const defendantName = result.litigantType === LitigantType.Company
            ? (result.selection as CompanyDTO).name
            : (result.selection as SettingsLookupDTO).value;

        setFormData((formData: any) => ({
            ...formData,
            defendants: [
                ...formData.defendants,
                {
                    companyId: result.litigantType === LitigantType.Company ? (result.selection as CompanyDTO).id : null,
                    clientId: result.litigantType === LitigantType.Client ? (result.selection as SettingsLookupDTO).id : null,
                    name: defendantName,
                    type: result.litigantType
                } as AddLitigantToCaseDTO
            ]
        }));

        setDefendantsChipData((chips) => [
            ...chips,
            {
                id: [LitigantType.Client, LitigantType.Company].includes(result.litigantType)
                    ? (result.selection as CompanyDTO | SettingsLookupDTO).id
                    : uuidv4(),
                value: defendantName,
                type: result.litigantType
            }
        ]);
    }

    const addTrialDate = (result: any) => {
        setFormData((formData: any) => ({
            ...formData,
            trialDates: [...formData.trialDates, result]
        }));

        setTrialDatesChipData((chips) => [...chips, {
            id: uuidv4(),
            value: result
        }]);
    }

    const addReportedEntity = (result: AddLitigantType) => {
        const reportedEntityName = result.litigantType === LitigantType.Company
            ? (result.selection as CompanyDTO).name
            : (result.selection as SettingsLookupDTO).value;

        setFormData((formData: any) => ({
            ...formData,
            reportedEntities: [
                ...formData.reportedEntities,
                {
                    companyId: result.litigantType === LitigantType.Company ? (result.selection as CompanyDTO).id : null,
                    clientId: result.litigantType === LitigantType.Client ? (result.selection as SettingsLookupDTO).id : null,
                    name: reportedEntityName,
                    type: result.litigantType
                } as AddLitigantToCaseDTO
            ]
        }));

        setReportedEntitiesChipData((chips) => [
            ...chips,
            {
                id: [LitigantType.Client, LitigantType.Company].includes(result.litigantType)
                    ? (result.selection as CompanyDTO | SettingsLookupDTO).id
                    : uuidv4(),
                value: reportedEntityName,
                type: result.litigantType
            }
        ]);
    }

    let title = "Hukuk Davası Ekle";
    let prosecutorsLabel = "Davacılar *";
    let reportedEntitiesVisible = true;
    let defendantsLabel = "Davalılar *";
    let fileNumberLabel = "Dosya Numarası"
    let privateFileLabel = "Dosya Özel Numarası"
    let caseDateLabel
    let trialDatesVisible = true;

    switch (caseType) {
        case CaseType.Criminal://ceza davaları
            title = "Ceza Davası Ekle";
            prosecutorsLabel = "Müştekiler/Katılanlar";
            reportedEntitiesVisible = false;
            defendantsLabel = "Sanıklar"
            fileNumberLabel = "Dosya Numarası"
            caseDateLabel = "Şikayet Tarihi"
            trialDatesVisible = true
            break;
        case CaseType.Executive://icra takibi
            title = "İcra Takibi Ekle";
            prosecutorsLabel = "Alacaklılar";
            reportedEntitiesVisible = false;
            defendantsLabel = "Borçlular"
            fileNumberLabel = "Dosya Numarası"
            caseDateLabel = "Takip Tarihi"
            trialDatesVisible = false
            break;
        case CaseType.Investigation://soruşturmalar
            title = "Soruşturma Ekle";
            prosecutorsLabel = "Müştekiler";
            reportedEntitiesVisible = false;
            defendantsLabel = "Şüpheliler";
            fileNumberLabel = "Soruşturma Numarası"
            caseDateLabel = "Şikayet Tarihi"
            trialDatesVisible = false
            break;
        default:
            caseDateLabel = "Dava Tarihi *";
            break;
    }
    const createLegalCaseTaskStatus = useSelector((state: AppState) => state.legalCase.createLegalCaseTaskStatus);

    const [errors, setErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (createLegalCaseTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createLegalCaseTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [createLegalCaseTaskStatus]);

    useEffect(() => {
        if (formData.caseDate) {
            setErrors((prevErrors) => {
                const {caseDate, ...rest} = prevErrors;
                return rest;
            });
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                caseDate: "Dava tarihi zorunludur.",
            }));
        }
    }, [formData.caseDate]);

    const excludedItems = [
        ...formData.prosecutors,
        ...formData.defendants,
        ...formData.reportedEntities
    ]

    const courtFilterByType = (court: CourtDTO) => {
        switch (caseType) {
            case CaseType.Law:
                return court.type === CourtType.Law;
            case CaseType.Executive:
                return court.type === CourtType.Executive;
            case CaseType.Criminal:
                return court.type === CourtType.Criminal;
            case CaseType.Investigation:
                return court.type === CourtType.Investigation;
        }
    }

    const cityOptions = allCitiesLookup?.map((city) => ({
        value: city.id,
        label: city.value,
    })) || [];

    const courtHouseOptions = allCourtHouses?.filter(courtHouse => courtHouse.city.plateId === formData.cityId)
        .map((courtHouse) => ({
            value: courtHouse.id,
            label: courtHouse.name,
        })) || [];

    const courtOptions = allCourts?.filter(court => court.courtHouse.id === formData.courtHouseId && courtFilterByType(court))
        .map((court) => ({
            value: court.id,
            label: court.name,
        })) || [];

    return (
        <>
            <CustomDialog
                open={true}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} sx={{mb: 1}}>
                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Konu"
                            type="text"
                            fullWidth
                            variant={fieldVariant as TextFieldVariants}
                            onChange={handleChangeFormData}/>
                        {errors.name && (
                            <ErrorText message={errors.name}/>
                        )}
                    </FormControl>

                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="prosecutors-id-label">
                            {prosecutorsLabel}
                        </InputLabel>

                        <OutlinedInput
                            required
                            label={prosecutorsLabel}
                            id='prosecutors'
                            inputProps={{
                                style: {
                                    position: "absolute",
                                    opacity: 0,
                                    pointerEvents: "none"
                                },
                                value: prosecutorsChipData
                            }}
                            startAdornment={
                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        flex: 1,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        listStyle: 'none',
                                    }}>
                                    {prosecutorsChipData.map((data) => (
                                        <ListItem key={data.id}>
                                            <Chip label={data.value}
                                                  sx={{
                                                      height: 24,
                                                      fontSize: '0.75rem',
                                                      '& .MuiChip-label': {fontWeight: 500}
                                                  }}
                                                  onDelete={handleDeleteProsecutorsChipData(data)}/>
                                        </ListItem>
                                    ))}
                                </div>
                            }
                            endAdornment={
                                <div style={{
                                    marginTop: "12px",
                                    marginBottom: "12px",
                                }}>
                                    <Fab size="small" sx={{marginLeft: "auto"}}
                                         color="primary" aria-label="add" onClick={handleAddProsecutorDialogOpen}>
                                        <AddIcon/>
                                    </Fab>
                                </div>
                            }/>
                        {errors.prosecutorIds && (
                            <ErrorText message={errors.prosecutorIds}/>
                        )}
                    </FormControl>
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="defandants-id-label">
                            {defendantsLabel}
                        </InputLabel>

                        <OutlinedInput
                            required
                            label='Davacılar *'
                            id='defendants'
                            inputProps={{
                                style: {
                                    position: "absolute",
                                    opacity: 0,
                                    pointerEvents: "none"
                                },
                                value: defendantsChipData
                            }}
                            startAdornment={
                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        flex: 1,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        listStyle: 'none',
                                    }}>
                                    {defendantsChipData.map((data) => (
                                        <ListItem key={data.id}>
                                            <Chip label={data.value}
                                                  sx={{
                                                      height: 24,
                                                      fontSize: '0.75rem',
                                                      '& .MuiChip-label': {fontWeight: 500}
                                                  }}
                                                  onDelete={handleDeleteDefendantsChipData(data)}/>
                                        </ListItem>
                                    ))}
                                </div>
                            }
                            endAdornment={
                                <div style={{
                                    marginTop: "12px",
                                    marginBottom: "12px",
                                }}>
                                    <Fab size="small" sx={{marginLeft: "auto"}}
                                         color="primary" aria-label="add" onClick={handleAddDefendantsDialogOpen}>
                                        <AddIcon/>
                                    </Fab>
                                </div>
                            }/>
                        {errors.defendantIds && (
                            <ErrorText message={errors.defendantIds}/>
                        )}
                    </FormControl>

                    {reportedEntitiesVisible && (
                        <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                            <InputLabel id="reportedEntities-id-label">
                                İhbar Olunanlar
                            </InputLabel>
                            <OutlinedInput
                                label="İhbar Olunanlar"
                                id="reportedEntities"
                                inputProps={{
                                    style: {
                                        position: "absolute",
                                        opacity: 0,
                                        pointerEvents: "none"
                                    },
                                    value: reportedEntitiesChipData
                                }}
                                startAdornment={
                                    <div
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            flex: 1,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                        }}>
                                        {reportedEntitiesChipData.map((data) => (
                                            <ListItem key={data.id}>
                                                <Chip label={data.value}
                                                      sx={{
                                                          height: 24,
                                                          fontSize: '0.75rem',
                                                          '& .MuiChip-label': {fontWeight: 500}
                                                      }}
                                                      onDelete={handleDeleteReportedEntitiesChipData(data)}/>
                                            </ListItem>
                                        ))}
                                    </div>
                                }
                                endAdornment={
                                    <div style={{
                                        marginTop: "12px",
                                        marginBottom: "12px",
                                    }}>
                                        <Fab size="small" sx={{marginLeft: "auto"}}
                                             color="primary" aria-label="add"
                                             onClick={handleAddReportedEntitiesDialogOpen}>
                                            <AddIcon/>
                                        </Fab>
                                    </div>
                                }/>
                            {errors.reportedEntityIds && (
                                <ErrorText message={errors.reportedEntityIds}/>
                            )}
                        </FormControl>
                    )}

                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setFormData({
                                    ...formData,
                                    cityId: newValue ? newValue.value : null,
                                    courtHouseId: null,
                                    courtId: null,
                                });
                            }}
                            options={cityOptions}
                            value={cityOptions.find((opt) => opt.value === formData.cityId) || null}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Şehirler"
                                           variant="outlined"
                                           required/>
                            }/>
                    </FormControl>

                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setFormData({
                                    ...formData,
                                    courtHouseId: newValue ? newValue.value : null,
                                    courtId: null,
                                });
                            }}
                            options={courtHouseOptions}
                            value={courtHouseOptions.find((opt) => opt.value === formData.courtHouseId) || null}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Adliyeler"
                                           variant="outlined"
                                           required/>}
                            disabled={!formData.cityId}/>
                    </FormControl>

                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setFormData({
                                    ...formData,
                                    courtId: newValue ? newValue.value : null,
                                });
                            }}
                            options={courtOptions}
                            value={courtOptions.find((opt) => opt.value === formData.courtId) || null}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Mahkemeler"
                                           variant="outlined"
                                           required/>}
                            disabled={!formData.courtHouseId}/>
                    </FormControl>

                    <FormControl fullWidth={true} sx={{mb: 1}}>
                        <TextField
                            required
                            margin="dense"
                            id="fileNumber"
                            name="fileNumber"
                            label={fileNumberLabel}
                            type="text"
                            fullWidth
                            variant={fieldVariant as TextFieldVariants}
                            onChange={handleChangeFormData}/>
                        {errors.fileNumber && (
                            <ErrorText message={errors.fileNumber}/>
                        )}
                    </FormControl>
                    <FormControl fullWidth={true} sx={{mb: 1}}>
                        <TextField
                            required
                            margin="dense"
                            id="corporateFileNumber"
                            name="corporateFileNumber"
                            label={privateFileLabel}
                            type="text"
                            fullWidth
                            variant={fieldVariant as TextFieldVariants}
                            onChange={handleChangeFormData}/>
                        {errors.corporateFileNumber && (
                            <ErrorText message={errors.corporateFileNumber}/>
                        )}
                    </FormControl>

                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                            <DatePicker
                                label={caseDateLabel}
                                name="caseDate"
                                value={formData.caseDate}
                                onChange={handleDateChange('caseDate')}
                                // @ts-ignore
                                renderInput={(params: any) => <TextField {...params} fullWidth/>}/>
                        </LocalizationProvider>
                        {errors.caseDate && (
                            <ErrorText message={errors.caseDate}/>
                        )}
                    </FormControl>

                    {trialDatesVisible && (
                        <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                            <InputLabel id="trialDates-id-label">
                                Duruşma Tarihleri
                            </InputLabel>
                            <OutlinedInput
                                label="Duruşma Tarihleri"
                                id='trialDates'
                                inputProps={{
                                    style: {
                                        position: "absolute",
                                        opacity: 0,
                                        pointerEvents: "none"
                                    },
                                    value: trialDatesChipData
                                }}
                                startAdornment={
                                    <div
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            flex: 1,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                        }}>
                                        {trialDatesChipData.map((data) => {
                                            const formattedDate = moment(data.value).format('DD MMMM YYYY');
                                            return (
                                                <ListItem key={data.id}>
                                                    <Chip label={formattedDate}
                                                          sx={{
                                                              height: 24,
                                                              fontSize: '0.75rem',
                                                              '& .MuiChip-label': {fontWeight: 500}
                                                          }}
                                                          onDelete={handleDeleteTrialDateChipData(data)}/>
                                                </ListItem>
                                            );
                                        })}
                                    </div>
                                }
                                endAdornment={
                                    <div style={{
                                        marginTop: "12px",
                                        marginBottom: "12px",
                                    }}>
                                        <Fab size="small" sx={{marginLeft: "auto"}}
                                             color="primary" aria-label="add"
                                             onClick={handleAddTrialDatesDialogOpen}>
                                            <AddIcon/>
                                        </Fab>
                                    </div>
                                }/>

                            {errors.trialDates && (
                                <ErrorText message={errors.trialDates}/>
                            )}
                        </FormControl>
                    )}
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="assignee-ids-label">Sorumlular*</InputLabel>
                        <Select
                            required
                            labelId="responsibleIds-label"
                            id="responsible-ids"
                            name="responsibleIds"
                            label="Sorumlular*"
                            fullWidth
                            multiple={true}
                            variant={fieldVariant as TextFieldVariants}
                            value={responsibleIds}
                            onChange={(event: any) => {
                                setResponsibleIds(event.target.value);
                            }}>
                            {allUsersLookup?.map((responsibleLookup: any) => (
                                <MenuItem key={responsibleLookup.id} value={responsibleLookup.id}>
                                    {responsibleLookup.value}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.responsibleIds && (
                            <ErrorText message={errors.responsibleIds}/>
                        )}
                    </FormControl>
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <FileUpload
                            setFileName={setLegalCaseFileName}
                            fieldName="file"
                            value={formData.file}
                            uploadButtonLabel="Evrak Ekle"/>
                        {errors.file && (
                            <ErrorText message={errors.file}/>
                        )}
                    </FormControl>
                    {createLegalCaseTaskStatus?.type === AsyncTaskStatusType.Error && createLegalCaseTaskStatus.errorMessage && (
                        <ErrorText type="form" message={createLegalCaseTaskStatus.errorMessage}/>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>İptal</Button>
                    <Button type="submit" disabled={!formData.file}>Oluştur</Button>
                </DialogActions>
            </CustomDialog>

            {addProsecutorDialogIsOpen && (
                <AddProsecutorDialog excludeItems={excludedItems} handleClose={handleAddProsecutorDialogClose}
                                     caseType={caseType}
                                     addProsecutor={addProsecutor}/>
            )}
            {addDefendantsDialogIsOpen && (
                <AddDefendantsDialog
                    excludeItems={excludedItems}
                    handleClose={handleAddDefendantsDialogClose} addDefendants={addDefendant}/>
            )}
            {addReportedEntitiesDialogIsOpen && (
                <AddReportedEntitiesDialog excludeItems={excludedItems}
                                           handleClose={handleAddReportedEntitiesDialogClose}
                                           addReportedEntities={addReportedEntity}/>
            )}
            {addTrialDatesDialogIsOpen && (
                <AddTrialDateDialog handleClose={handleAddTrialDatesDialogClose}
                                    addTrialDate={addTrialDate}/>
            )}
        </>
    );
}
