import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Delete} from "@mui/icons-material";
import {CaseFolderDTO} from "../api/dtos";

interface FolderActionProps {
    folder: CaseFolderDTO;
    onDelete: (folder: CaseFolderDTO) => void;
}

const FolderAction: React.FC<FolderActionProps> = ({
                                                       folder,
                                                       onDelete,
                                                   }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const deleteFolder = () => onMenuItemClick(() => onDelete(folder));

    return (
        <>
            <IconButton
                aria-controls={open ? 'folder-action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                aria-label="settings"
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="folder-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={deleteFolder}>
                    <IconButton
                        size="small"
                        aria-label="delete"
                        sx={{color: 'text.secondary'}}>
                        <Delete/>
                    </IconButton>
                    Klasörü Sil
                </MenuItem>
            </Menu>
        </>
    );
};

export default FolderAction;
