import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getStatisticsAction} from '../api/legalCase';
import {AppState} from '../store';
import {useTheme} from '@mui/material/styles';

const Statics = () => {
    const theme = useTheme();
    const dispatch: any = useDispatch();

    const statistics = useSelector((state: AppState) => state.legalCase.statistics);

    useEffect(() => {
        dispatch(getStatisticsAction());
    }, [dispatch]);

    if (!statistics) {
        return null;
    }

    const prosecutorCategories = statistics.companyProsecutorStatistics.map((stat) => stat.name);
    const defendantCategories = statistics.companyDefendantStatistics.map((stat) => stat.name);
    const reportedEntitiesCategories = statistics.companyReportedEntityStatistics.map((stat) => stat.name);
    const prosecutorOpenCategories = statistics.companyProsecutorOpenStatistics.map((stat) => stat.name);
    const defendantOpenCategories = statistics.companyDefendantOpenStatistics.map((stat) => stat.name);
    const reportedEntitiesOpenCategories = statistics.companyReportedEntityOpenStatistics.map((stat) => stat.name);

    const createOptions = (categories: string[]): ApexOptions => ({
        chart: {
            parentHeightOffset: 0,
            toolbar: {show: false},
            stacked: false,
            zoom: {enabled: false},
            selection: {enabled: false},
        },
        plotOptions: {bar: {borderRadius: 4, distributed: false, columnWidth: "16px"}},
        stroke: {width: 2, colors: [theme.palette.background.paper]},
        dataLabels: {enabled: false},
        legend: {show: true},
        xaxis: {
            categories: categories,
            labels: {show: true},
            axisTicks: {show: false},
            axisBorder: {show: false}
        },
        yaxis: {
            show: true,
            min: 0,
            labels: {
                offsetX: -17,
                formatter: (val) => Math.ceil(val).toString()
            }
        },
        grid: {
            strokeDashArray: 7,
            padding: {top: -1, right: 0, left: -12, bottom: 5}
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `Dava Sayısı: ${val}`
            }
        },
        colors: [theme.palette.primary.main, '#0000ff']
    });

    const prosecutorSeriesData = {
        name: 'Genel Davacı İstatistikleri',
        data: prosecutorCategories.map((prosecutorCategory) => {
            const found = statistics.companyProsecutorStatistics.find((stat) => stat.name === prosecutorCategory);
            return found?.count || 0;
        })
    };

    const defendantSeriesData = {
        name: 'Davalı İstatistikleri',
        data: defendantCategories.map((defendantCategory) => {
            const found = statistics.companyDefendantStatistics.find((stat) => stat.name === defendantCategory);
            return found?.count || 0;
        })
    };

    const reportedEntitiesData = {
        name: 'İhbar Olunan İstatistikleri',
        data: reportedEntitiesCategories.map((reportedEntitiesCategory) => {
            const found = statistics.companyReportedEntityStatistics.find((stat) => stat.name === reportedEntitiesCategory);
            return found?.count || 0;
        })
    };

    const prosecutorOpenSeriesData = {
        name: 'Açık Davacı İstatistikleri',
        data: prosecutorOpenCategories.map((prosecutorOpenCategory) => {
            const found = statistics.companyProsecutorOpenStatistics.find((stat) => stat.name === prosecutorOpenCategory);
            return found?.count || 0;
        })
    };

    const defendantOpenSeriesData = {
        name: 'Açık Davalı İstatistikleri',
        data: defendantOpenCategories.map((defendantOpenCategory) => {
            const found = statistics.companyDefendantOpenStatistics.find((stat) => stat.name === defendantOpenCategory);
            return found?.count || 0;
        })
    };

    const reportedEntitiesOpenData = {
        name: 'Açık Ceza Davaları İstatistikleri',
        data: reportedEntitiesOpenCategories.map((reportedEntitiesOpenCategory) => {
            const found = statistics.companyReportedEntityOpenStatistics.find((stat) => stat.name === reportedEntitiesOpenCategory);
            return found?.count || 0;
        })
    };

    return (
        <Card>
            <CardContent>
                <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                        Davacı İstatistikleri
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Bu grafik, genel ve açık davacı istatistiklerini gösterir
                    </Typography>
                    <Chart
                        type="bar"
                        height={300}
                        options={createOptions(prosecutorCategories)}
                        series={[prosecutorSeriesData, prosecutorOpenSeriesData]}/>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                        {defendantSeriesData.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Bu grafik, sanık olan şirketlerin dava sayısını temsil eder.
                    </Typography>
                    <Chart
                        type="bar"
                        height={300}
                        options={createOptions(defendantCategories)}
                        series={[defendantSeriesData, defendantOpenSeriesData]}/>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                        {reportedEntitiesData.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Bu grafik, rapor edilen şirketlerin istatistiklerini gösterir.
                    </Typography>
                    <Chart
                        type="bar"
                        height={300}
                        options={createOptions(reportedEntitiesCategories)}
                        series={[reportedEntitiesData, reportedEntitiesOpenData]}/>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Statics;
