import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography} from "@mui/material";
import UserLayout from "../components/UserLayout";
import {ResetOwnPasswordDialog} from "./reset-own-password/resetOwnPasswordDialog";
import {AppState} from "../store";
import {getPresentableUserRole} from "../api/dtos";
import axios from "axios";
import {EndPoints} from "../api/EndPoints";
import {FieldErrors, ResponseDTO} from "../shared/dtos";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import CustomDialog from "../components/CustomDialog";
import {activate2FAAction} from "../api/appUser";
import QRCode from "react-qr-code";
import {getUserDataAction} from "../api/auth";

interface TwoFaCodeProps {
    qrCodeUrl: string;
    handleClose: () => void;
}

const TwoFaCode: React.FC<TwoFaCodeProps> = ({qrCodeUrl, handleClose}) => {
    const [code, setCode] = useState('');
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const generate2FATaskStatus = useSelector((state: AppState) => state.settings.generate2FATaskStatus);

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const result = await dispatch(activate2FAAction(code));
        if (result.type === 'user/activate2FA/fulfilled') {
            handleClose();
            dispatch(getUserDataAction());
        } else if (result.payload) {
            setErrors({code: result.payload});
        }
    };

    useEffect(() => {
        if (generate2FATaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            generate2FATaskStatus.fieldErrors.forEach(({field, message}) => {
                updatedErrors[field] = message;
            });
            setErrors(updatedErrors);
        }
    }, [generate2FATaskStatus]);

    const handleChangeForm = (e: { target: { value: string } }) => {
        setCode(e.target.value);
        setErrors((prev) => ({...prev, code: ''}));
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>İKİ FAKTÖRLÜ DOĞRULAMA</DialogTitle>
            <DialogContent>
                <QRCode
                    size={256}
                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                    value={qrCodeUrl}
                    viewBox={`0 0 256 256`}/>

                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="code"
                        name="code"
                        label="Code"
                        type="text"
                        variant="outlined"
                        value={code}
                        onChange={handleChangeForm}
                        error={!!errors.code}
                        helperText={errors.code}/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button
                    type="submit"
                    variant="outlined"
                    sx={{
                        mt: 2,
                        borderRadius: 2,
                        padding: '8px 16px',
                        boxShadow: 2,
                        '&:hover': {
                            backgroundColor: 'grey.100',
                        },
                    }}>
                    2 FA Aktifleştir
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};

const ProfileDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container gap={1}>
        <Typography variant="body1" fontWeight="bold" display="inline">
            {label}:
        </Typography>
        <Typography variant="body1" display="inline">
            {value || "Veri yok"}
        </Typography>
    </Grid>
);

const TOTP_APP_NAME = "FBHUKUK";

export default function Profile() {
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [resetOwnPasswordDialogOpen, setResetOwnPasswordDialogOpen] = useState(false);
    const token = useSelector((state: AppState) => state.auth.userWithToken?.token);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [twoFAPopupOpen, setTwoFAPopupOpen] = useState(false);

    const buildQRCodeURL = (secret: string): string => {
        return `otpauth://totp/${TOTP_APP_NAME}?secret=${secret}&issuer=${TOTP_APP_NAME}`
    }

    const generate2FASecret = async () => {
        const response = await axios.post(EndPoints.user + "/mfa/secret", {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        const {code, resultValue} = response.data;
        if (code === ResponseDTO.CodeEnum.Success) {
            setQrCodeUrl(buildQRCodeURL(resultValue));
            setTwoFAPopupOpen(true);
        }
    }

    const handleResetOwnPasswordDialogOpen = () => setResetOwnPasswordDialogOpen(true);
    const handleResetOwnPasswordDialogClose = () => setResetOwnPasswordDialogOpen(false);

    const handleTwoFAPopupClose = () => setTwoFAPopupOpen(false);

    if (!user) {
        return null;
    }

    return (
        <UserLayout>
            <Paper elevation={3} sx={{p: 3}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            {user.firstName + " " + user.lastName} Profil Sayfası
                        </Typography>
                    </Grid>
                    <ProfileDetailsRow label="E-MAİL" value={user.email}/>
                    <ProfileDetailsRow label="ADI" value={user.firstName}/>
                    <ProfileDetailsRow label="SOYADI" value={user.lastName}/>
                    <ProfileDetailsRow label="ROLÜ" value={getPresentableUserRole(user.role)}/>
                </Grid>
                <Button
                    variant="outlined"
                    component="span"
                    onClick={handleResetOwnPasswordDialogOpen}
                    sx={{
                        mt: 2,
                        borderRadius: 2,
                        padding: '8px 16px',
                        boxShadow: 2,
                        '&:hover': {
                            backgroundColor: 'grey.100',
                        }
                    }}>
                    Şifre Değiştir
                </Button>
                {!user.mfaEnabled && (
                    <Button
                        variant="outlined"
                        component="span"
                        onClick={generate2FASecret}
                        sx={{
                            ml: 2,
                            mt: 2,
                            borderRadius: 2,
                            padding: '8px 16px',
                            boxShadow: 2,
                            '&:hover': {
                                backgroundColor: 'grey.100',
                            }
                        }}>
                        2 FA code üret
                    </Button>
                )}
                {twoFAPopupOpen && (
                    <TwoFaCode qrCodeUrl={qrCodeUrl} handleClose={handleTwoFAPopupClose}/>
                )}
            </Paper>
            {resetOwnPasswordDialogOpen && (
                <ResetOwnPasswordDialog handleClose={handleResetOwnPasswordDialogClose}/>
            )}
        </UserLayout>
    );
}
