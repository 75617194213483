export const StorageKeys = {
    TOKEN: 'hukuk-app-token',
    REFRESH_TOKEN: 'hukuk-app-refresh-token',
    TOKEN_EXPIRY: 'hukuk-app-token-expiry',
    USER_ID: 'hukuk-app-userId'
};

export const Headers = {
    AUTHORIZATION: 'Authorization',
    CONTENT_TYPE: 'Content-Type',
    ACCEPT: 'Accept',
    APPLICATION_JSON: 'application/json'
};

export const ErrorMessages = {
    GENERIC_ERROR: 'Bir hata oluştu. Lütfen tekrar deneyin',
    TOKEN_MISSING: 'Token is missing',
    UNKNOWN_ERROR: 'Unknown error',
    MFA_ACTIVATION_FAILED: '2FA etkinleştirilemedi.',
    DUPLICATE_COURT: 'Bu mahkeme zaten mevcut',
    DUPLICATE_OBJECT: 'Bu nesne zaten mevcut',
    AUTHENTICATION_FAILED: 'Authentication token is missing',
};

export const SuccessMessages = {
    LEGAL_NOTICE_CREATED: 'Legal notice başarıyla oluşturuldu',
    COURT_CREATED: 'Mahkeme başarıyla oluşturuldu',
    COMPANY_CREATED: 'Şirket başarıyla oluşturuldu',
};
