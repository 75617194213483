import {Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Popper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createUserAction, getUsersAction} from "../../api/appUser";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
    AddUserDTO,
    AllGroupedPermissions,
    CASE_PERMISSIONS,
    COMPANY_PERMISSIONS_LIST,
    CompanyType,
    CONTRACT_PERMISSIONS,
    EXPENSE_PERMISSIONS,
    getPresentableUserPermission,
    getPresentableUserRole,
    MEMBER_PERMISSIONS_LIST,
    SETTINGS_PERMISSIONS,
    TASK_PERMISSIONS,
    USER_PERMISSIONS,
    UserPermission,
    UserRole
} from "../../api/dtos";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AppState} from "../../store";
import {setCreateUserTaskStatus} from "../../features/app-user/appUserSlice";
import CustomDialog from "../../components/CustomDialog";
import {getAllCompaniesAction} from "../../api/settings";
import {setAllCompanies} from "../../features/settings/settingsSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

interface CreateUserDialogProps {
    handleClose: () => void;
    page: number;
}

interface CreateUserFormData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    role: string;
    associatedCompanyId: number | null;
    permissions: UserPermission[];
}

const RoleToPermissionsMap: Record<UserRole, UserPermission[]> = {
    [UserRole.Manager]: [],
    [UserRole.Member]: [
        ...MEMBER_PERMISSIONS_LIST
    ],
    [UserRole.Company]: [
        ...COMPANY_PERMISSIONS_LIST
    ],
    [UserRole.Admin]: [
        ...USER_PERMISSIONS,
        ...CASE_PERMISSIONS,
        ...TASK_PERMISSIONS,
        ...CONTRACT_PERMISSIONS,
        ...SETTINGS_PERMISSIONS,
        ...EXPENSE_PERMISSIONS,
    ],
};

export const getPermissionMenuItems = (selectedPermissions: UserPermission[]) => {
    const permissionOptions: React.ReactElement[] = [];

    AllGroupedPermissions.forEach((listItem) => {
        permissionOptions.push((
            <MenuItem disabled>
                <b>{listItem.title}</b>
            </MenuItem>
        ));
        {
            listItem.options.forEach((it) => {
                permissionOptions.push((
                    <MenuItem key={it} value={it}>
                        <Checkbox checked={selectedPermissions.includes(it)}/>
                        {getPresentableUserPermission(it)}
                    </MenuItem>
                ))
            })
        }
    });
    return permissionOptions;
}

export const EditPermissionDialog = ({
                                         open,
                                         onClose,
                                         permissions,
                                         setPermissions,
                                     }: {
    open: boolean;
    onClose: () => void;
    permissions: UserPermission[];
    setPermissions: (permissions: UserPermission[]) => void;
}) => {
    const handleCheckboxChange = (permission: UserPermission) => {
        const updatedPermissions = permissions.includes(permission)
            ? permissions.filter((perm) => perm !== permission)
            : [...permissions, permission];
        setPermissions(updatedPermissions);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>İzinleri Düzenle</DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={0.5}>
                    {AllGroupedPermissions.map((group) => (
                        <Grid item xs={2} key={group.title}>
                            <strong>{group.title}</strong>
                            <FormGroup>
                                {group.options.map((permission) => (
                                    <FormControlLabel
                                        key={permission}
                                        control={
                                            <Checkbox
                                                checked={permissions.includes(permission)}
                                                onChange={() => handleCheckboxChange(permission)}
                                                disabled={group.title === "Harcama İzinleri"}/>
                                        }
                                        label={getPresentableUserPermission(permission)}/>
                                ))}
                            </FormGroup>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Kapat</Button>
            </DialogActions>
        </Dialog>
    );
};

export const CreateUserDialog: React.FC<CreateUserDialogProps> = ({handleClose, page}) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [hasConfirmationError, setHasConfirmationError] = useState<boolean>(false);
    const [isPermissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
    const [formData, setFormData] = useState<CreateUserFormData>({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        passwordConfirmation: "",
        role: "",
        associatedCompanyId: null,
        permissions: []
    });
    const createUserTaskStatus = useSelector((state: AppState) => state.appUser.createUserTaskStatus);

    const allCompanies = useSelector((state: AppState) => state.settings.allCompanies);

    useEffect(() => {
        dispatch(getAllCompaniesAction());
        return () => {
            dispatch(setCreateUserTaskStatus(null));
            dispatch(setAllCompanies(null));
        }
    }, [dispatch]);

    const handleChangeFormData = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const {name, value} = event.target;

        if (name === "role") {
            const role = value as UserRole;
            const defaultPermissions = RoleToPermissionsMap[role] || [];
            setFormData((prevFormData) => ({
                ...prevFormData,
                role: role,
                associatedCompanyId: null,
                permissions: defaultPermissions,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                //@ts-ignore
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        if (formData.password === formData.passwordConfirmation) {
            setErrors((prevErrors) => {
                const {passwordConfirmation, ...rest} = prevErrors;
                return rest;
            });
            setHasConfirmationError(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordConfirmation: "Şifreler Uyuşmuyor."
            }));
            setHasConfirmationError(true);
        }
    }, [formData.password, formData.passwordConfirmation]);

    const convertDTO = (data: CreateUserFormData): AddUserDTO => ({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        role: data.role as UserRole,
        associatedCompanyId: data.associatedCompanyId,
        permissions: data.permissions
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const result = await dispatch(createUserAction(convertDTO(formData)));

        if (result.type === 'user/createUser/fulfilled') {
            handleClose();
            if (page === 1) {
                dispatch(getUsersAction({page: 1}));
            } else {
                navigate("");
            }
        }
    };

    useEffect(() => {
        if (createUserTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createUserTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [createUserTaskStatus]);

    useEffect(() => {
        return () => {
            dispatch(setCreateUserTaskStatus(null));
        };
    }, []);

    const handleOpenPermissionsDialog = () => {
        setPermissionsDialogOpen(true);
    };

    const handleClosePermissionsDialog = () => {
        setPermissionsDialogOpen(false);
    };

    const handleSetPermissions = (permissions: UserPermission[]) => {
        setFormData((prevData) => ({...prevData, permissions}));
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Kullanıcı Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="E-Posta"
                        type="email"
                        autoComplete="new-email"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChangeFormData}/>
                    {errors.email && (
                        <ErrorText message={errors.email}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="firstName"
                        name="firstName"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.firstName}
                        onChange={handleChangeFormData}/>
                    {errors.firstName && (
                        <ErrorText message={errors.firstName}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="lastName"
                        name="lastName"
                        label="Soyadı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.lastName}
                        onChange={handleChangeFormData}/>
                    {errors.lastName && (
                        <ErrorText message={errors.lastName}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        name="password"
                        label="Şifre"
                        type="password"
                        fullWidth
                        autoComplete="new-password"
                        variant="outlined"
                        value={formData.password}
                        onChange={handleChangeFormData}/>
                    {errors.password && (
                        <ErrorText message={errors.password}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        label="Şifre Onay"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={formData.passwordConfirmation}
                        onChange={handleChangeFormData}/>
                    {errors.passwordConfirmation && (
                        <ErrorText message={errors.passwordConfirmation}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="role-label">Rol</InputLabel>
                    <Select
                        required
                        labelId="role-label"
                        id="role"
                        name="role"
                        label="Rol"
                        fullWidth
                        value={formData.role}
                        //@ts-ignore
                        onChange={handleChangeFormData}>
                        <MenuItem value={UserRole.Manager}>{getPresentableUserRole(UserRole.Manager)}</MenuItem>
                        <MenuItem value={UserRole.Member}>{getPresentableUserRole(UserRole.Member)}</MenuItem>
                        <MenuItem value={UserRole.Company}>{getPresentableUserRole(UserRole.Company)}</MenuItem>
                    </Select>
                    {errors.role && (
                        <ErrorText message={errors.role}/>
                    )}
                </FormControl>
                {formData.role === UserRole.Company && (
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            disablePortal={false}
                            id="associated-company-id-autocomplete"
                            options={allCompanies || []}
                            getOptionLabel={(option) => option.name}
                            groupBy={(option) =>
                                option.type === CompanyType.Branch ? "ŞUBELER" : "İŞTİRAKLER"}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            value={
                                allCompanies?.find((company) => company.id === formData.associatedCompanyId) ||
                                null}
                            onChange={(event, value) => {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    associatedCompanyId: value ? value.id : null,
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Şube/İştirakler"
                                    error={Boolean(errors.associatedCompanyId)}
                                    helperText={errors.associatedCompanyId}/>
                            )}/>
                    </FormControl>
                )}

                {createUserTaskStatus?.type === AsyncTaskStatusType.Error && createUserTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createUserTaskStatus.errorMessage}/>
                )}
                {formData.role !== UserRole.Manager && (
                    <Button
                        variant="text"
                        onClick={handleOpenPermissionsDialog}
                        sx={{mt: 1, display: "block"}}>
                        İzinleri Düzenle
                    </Button>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit" disabled={hasConfirmationError}>Oluştur</Button>
            </DialogActions>

            <EditPermissionDialog
                open={isPermissionsDialogOpen}
                onClose={handleClosePermissionsDialog}
                permissions={formData.permissions}
                setPermissions={handleSetPermissions}/>

        </CustomDialog>
    );
};
