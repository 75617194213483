import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AsyncTaskStatusType, FieldErrors } from "../shared/dtos";
import themeConfig from '../theme/configs/themeConfig'

// MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import MuiCard, { CardProps } from '@mui/material/Card'
import InputAdornment from '@mui/material/InputAdornment'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import BlankLayout from "../theme/@core/layouts/BlankLayout";
import { authenticateUserAction } from "../api/auth";
import { AppState } from "../store";
import ErrorText from "../components/ErrorText";
import {StorageKeys} from "../api/constants";

interface State {
    username: string;
    password: string;
    mfaCode: string;
    showPassword: boolean;
}

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
    [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const LinkStyled = styled('a')(({ theme }) => ({
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary
    }
}))

const Login = () => {
    const [mfaCodeFieldVisible, setMfaCodefieldVisible] = useState(false)
    const handleLogin = async (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        try {
            const result = await dispatch(authenticateUserAction({
                email: values.username,
                password: values.password,
                rememberMe,
                mfaCode: values.mfaCode
            }));
            if (result.payload === "İki aşamalı doğrulama kodunu giriniz.") {
                setMfaCodefieldVisible(true);
            } else if (result.type === "auth/authenticateUser/fulfilled") {
                navigate('/');
            }
        } catch (error) {
            console.error("Giriş yapılırken hata oluştu:", error);
        }
    };

    const [values, setValues] = useState<State>({
        username: '',
        password: '',
        mfaCode: '',
        showPassword: false
    })
    const [rememberMe, setRememberMe] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<FieldErrors>({});
    const loginTaskStatus = useSelector((state: AppState) => state.auth.loginTaskStatus);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const savedToken = localStorage.getItem(StorageKeys.TOKEN) || sessionStorage.getItem(StorageKeys.TOKEN);
        if (savedToken) {
            navigate('/');
        } else {
            setLoading(false);
        }
    }, [navigate]);

    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked);

    if (loading) {
        return null;
    }
    const handleMailtoMail = (e: { preventDefault: () => void; }) => {
        window.location.href = "mailto:hukuk@fenerbahce.org";
        e.preventDefault();
    }

    return (
        <BlankLayout>
            <Box className='content-center'>
                <Card sx={{ zIndex: 1 }}>
                    <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                        <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img height={50} width={50} src='/avatar.png' />
                            <Typography
                                variant='h6'
                                sx={{
                                    ml: 3,
                                    lineHeight: 1,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    fontSize: '1.5rem !important'
                                }}>
                                {themeConfig.templateName}
                            </Typography>
                        </Box>
                        <form noValidate autoComplete='off' onSubmit={handleLogin}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                                <InputLabel htmlFor='auth-login-username'>Email</InputLabel>
                                <OutlinedInput
                                    label="Email"
                                    value={values.username}
                                    id='auth-login-username'
                                    onChange={(e) => setValues({ ...values, username: e.target.value })}
                                    error={Boolean(errors.username)}
                                    type="text" />
                                {errors.username && <ErrorText message={errors.username} />}
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                                <InputLabel htmlFor='auth-login-password'>Şifre</InputLabel>
                                <OutlinedInput
                                    label="Şifre"
                                    value={values.password}
                                    id='auth-login-password'
                                    onChange={(e) => setValues({ ...values, password: e.target.value })}
                                    error={Boolean(errors.password)}
                                    type={values.showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                edge='end'
                                                onClick={() => setValues({
                                                    ...values,
                                                    showPassword: !values.showPassword
                                                })}
                                                aria-label='toggle password visibility'>
                                                {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                                            </IconButton>
                                        </InputAdornment>
                                    } />
                                {errors.password && <ErrorText message={errors.password} />}
                            </FormControl>
                            {mfaCodeFieldVisible && (
                                <FormControl fullWidth sx={{ mb: 4 }}>
                                    <InputLabel htmlFor='auth-login-mfaCode'>Kod</InputLabel>
                                    <OutlinedInput
                                        label="Kod"
                                        value={values.mfaCode}
                                        id='auth-login-mfaCode'
                                        onChange={(e) =>
                                            setValues({ ...values, mfaCode: e.target.value })}
                                        error={Boolean(errors.mfaCode)}
                                        type="text" />
                                    {errors.mfaCode && <ErrorText message={errors.mfaCode} />}
                                </FormControl>
                            )}

                            {loginTaskStatus?.type === AsyncTaskStatusType.Error && loginTaskStatus.errorMessage && (
                                <ErrorText type="form" message={loginTaskStatus.errorMessage} />
                            )}
                            <Box
                                sx={{
                                    mb: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}>
                                <FormControlLabel
                                    control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} />}
                                    label='Beni Hatırla' />

                                <Link to="javascript:void(0)" onClick={handleMailtoMail}>
                                    <LinkStyled>Şifremi Unuttum</LinkStyled>
                                </Link>

                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                size='large'
                                variant='contained'
                                sx={{ marginBottom: 7 }}
                                onClick={handleLogin}>
                                Giriş Yap
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </BlankLayout>
    );
};

export default Login;
