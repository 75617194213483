import React from 'react';
import {Dialog, DialogProps} from '@mui/material';

const CustomDialog: React.FC<DialogProps> = (props) => {
    const {onClose, ...otherProps} = props;

    const handleInnerClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        if (onClose) {
            onClose(event, reason);
        }
    };

    return (
        <Dialog
            disableEscapeKeyDown={true}
            onClose={handleInnerClose}
            {...otherProps}         />
    );
};

export default CustomDialog;