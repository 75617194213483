import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {editCourtHouseAction, getCourtHousesAction, getLoadCitiesAction} from "../../api/settings"; // getLoadCitiesAction eklendi
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {CourtHouseDTO} from "../../api/dtos";
import {setLoadCitiesTaskStatus} from "../../features/settings/settingsSlice";
import {useNavigate} from "react-router-dom";

interface EditCourtHouseDialogProps {
    handleClose: () => void;
    courtHouse: CourtHouseDTO;
    page: number;
}

interface EditCourtHouseFormData {
    name: string;
    cityId: number;
}

export const EditCourtHouseDialog: React.FC<EditCourtHouseDialogProps> = ({handleClose, courtHouse, page}) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [formData, setFormData] = useState<EditCourtHouseFormData>({
        name: courtHouse.name,
        cityId: courtHouse.city.plateId
    });

    const cities = useSelector((state: AppState) => state.settings.allCitiesLookup) || [];
    const editCourtHouseTaskStatus = useSelector((state: AppState) => state.settings.editCourtHouseTaskStatus);

    useEffect(() => {
        dispatch(getLoadCitiesAction());
        return () => {
            dispatch(setLoadCitiesTaskStatus(null));
        }
    }, []);

    const handleChangeFormData = (event: React.ChangeEvent<{ name?: string; value: number }>) => {
        const {name, value} = event.target;
        setFormData((prev) => ({
            ...prev,
            [name as string]: value
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const result = await dispatch(editCourtHouseAction({
            id: courtHouse.id,
            ...formData
        }));

        if (result.type === 'courts/editCourtHouse/fulfilled') {
            handleClose();
            if (page === 1) {
                dispatch(getCourtHousesAction({filterByName: "", page}));
            } else {
                navigate("");
            }
        }
    };

    useEffect(() => {
        if (editCourtHouseTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editCourtHouseTaskStatus.fieldErrors.forEach(({field, message}) => {
                updatedErrors[field] = message;
            });
            setErrors(updatedErrors);
        }
    }, [editCourtHouseTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Adliye Düzenle ({courtHouse.name})</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adliye Adı"
                        type="text"
                        variant="outlined"
                        value={formData.name}
                        //@ts-ignore
                        onChange={handleChangeFormData}/>
                    {errors.name && <ErrorText message={errors.name}/>}
                </FormControl>
                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                    <InputLabel id="city-label">Şehir</InputLabel>
                    <Select
                        required
                        labelId="city-label"
                        id="cityId"
                        name="cityId"
                        label="Şehir"
                        value={formData.cityId}
                        //@ts-ignore
                        onChange={handleChangeFormData}>
                        {cities.map(city => (
                            <MenuItem key={city.id} value={city.id}>{city.value}</MenuItem>
                        ))}
                    </Select>
                    {errors.cityId && <ErrorText message={errors.cityId}/>}
                </FormControl>
                {editCourtHouseTaskStatus?.type === AsyncTaskStatusType.Error && editCourtHouseTaskStatus.errorMessage && (
                    <ErrorText type="form" message={editCourtHouseTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Düzenle</Button>
            </DialogActions>
        </CustomDialog>
    );
};
