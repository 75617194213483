export enum LitigantType {
    Company = 'Company',
    Client = 'Client',
}

export const getPresentableLitigantType = (litigantType: LitigantType) => {
    switch (litigantType) {
        case LitigantType.Client:
            return "Karşı Taraf";
        case LitigantType.Company:
            return "Fenerbahçe";
    }
}
