import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import {getCourtHousesAction} from "../api/settings";
import {AppState} from "../store";
import {CreateCourtHouse} from "./create-court-house/createCourtHouseDialog";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CourtHouseAction from "../actions/CourtHouseAction";
import {EditCourtHouseDialog} from "./edit-court-house/editCourtHouseDialog";
import {CourtHouseDTO} from "../api/dtos";
import {DeleteCourtHouseCasePopup} from "./delete-court-house/deleteCourtHouse";

export const CourtHouses = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const dispatch: any = useDispatch();
    const location = useLocation();
    const [createCourtHouseDialogOpen, setCreateCourtHouseDialogOpen] = useState(false);
    const [editCourtHouseDialogOpen, setEditCourtHouseDialogOpen] = useState(false);
    const [deleteCourtHouseDialogOpen, setDeleteCourtHouseDialogOpen] = useState(false);
    const [activeCourtHouse, setActiveCourtHouse] = useState<CourtHouseDTO | null>(null);
    const courtHouses = useSelector((state: AppState) => state.settings.allPaginatedCourtHouses);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCourtHousesAction({filterByName: "", page}));
    }, [page]);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const handleCreateCourtHouseDialogOpen = () => setCreateCourtHouseDialogOpen(true);

    const handleCreateCourtHouseDialogClose = () => {
        setCreateCourtHouseDialogOpen(false);
        dispatch(getCourtHousesAction({filterByName: "", page}));
    };

    const openEdit = (courtHouse: CourtHouseDTO) => {
        setActiveCourtHouse(courtHouse);
        setEditCourtHouseDialogOpen(true);
    };

    const openDelete = (courtHouse: CourtHouseDTO) => {
        setActiveCourtHouse(courtHouse);
        setDeleteCourtHouseDialogOpen(true);
    };

    const handleEditCourtHouseDialogClose = () => {
        setEditCourtHouseDialogOpen(false);
        setActiveCourtHouse(null);
    };

    const handleDeleteCourtHouseDialogClose = () => {
        setDeleteCourtHouseDialogOpen(false);
        setActiveCourtHouse(null);
    };

    const handleDelete = () => {
        setActiveCourtHouse(null);
        handleDeleteCourtHouseDialogClose();
    }

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>ADLİYELER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateCourtHouseDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {createCourtHouseDialogOpen && (
                <CreateCourtHouse
                    handleClose={handleCreateCourtHouseDialogClose}/>
            )}

            {editCourtHouseDialogOpen && activeCourtHouse && (
                <EditCourtHouseDialog
                    page={page}
                    handleClose={handleEditCourtHouseDialogClose}
                    courtHouse={activeCourtHouse}/>
            )}

            {deleteCourtHouseDialogOpen && activeCourtHouse && (
                <DeleteCourtHouseCasePopup
                    page={page}
                    onDeleteCourtHouse={handleDelete}
                    onClose={handleDeleteCourtHouseDialogClose}
                    courtHouse={activeCourtHouse}/>
            )}
            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label='court house table'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "30%"}}>ŞEHİR</TableCell>
                            <TableCell style={{width: "30%"}}>ADI</TableCell>
                            <TableCell style={{width: "10%"}}>İŞLEMLER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courtHouses?.entity.map((courtHouse) => (
                            <TableRow key={courtHouse.id}>
                                <TableCell>{courtHouse.city.name}</TableCell>
                                <TableCell>{courtHouse.name}</TableCell>
                                <TableCell>
                                    <CourtHouseAction
                                        courtHouse={courtHouse}
                                        onEdit={openEdit}
                                        onDelete={openDelete}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {courtHouses?.totalNumberPages != null && courtHouses.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={courtHouses.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
};
