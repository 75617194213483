import axios from "axios";
import {EndPoints} from "./EndPoints";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ErrorMessages, Headers} from "./constants";
import {
    setAllPaginatedNotifications,
    setDeleteNotificationTaskStatus,
    setLoadNotificationsTaskStatus, setMarkNotificationTaskStatus
} from "../features/notifications/notification";

export const getAllNotificationsAction = createAsyncThunk(
    'notification/getAllNotifications',
    async (page: number, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setLoadNotificationsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.notification}/notifications?page=${page}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadNotificationsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedNotifications(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadNotificationsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadNotificationsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const markNotificationAsReadAction = createAsyncThunk(
    'notification/markNotificationAsRead',
    async ({id, read}: { id: number; read: boolean }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setMarkNotificationTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(
                `${EndPoints.notification}/${id}`,
                {read},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    }
                }
            );

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setMarkNotificationTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setMarkNotificationTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setMarkNotificationTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteNotificationAction = createAsyncThunk(
    'notification/deleteNotification',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setDeleteNotificationTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.delete(`${EndPoints.notification}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteNotificationTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setDeleteNotificationTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteNotificationTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
