import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CustomDialog from "../../components/CustomDialog";
import {editContractAction} from "../../api/contract";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import moment from "moment";
import {ContractDTO, ContractType, getPresenTableContractType} from "../../api/dtos";
import {setEditContractTaskStatus} from "../../features/contracts/contractSlice";

interface EditContractDialogProps {
    handleClose: () => void;
    handleEdit: () => void;
    contract: ContractDTO;
}

export const EditContractDialog: React.FC<EditContractDialogProps> = ({
                                                                          contract,
                                                                          handleClose,
                                                                          handleEdit,
                                                                      }) => {
    const editContractTaskStatus = useSelector((state: AppState) => state.contract.editContractTaskStatus);
    const dispatch: any = useDispatch();

    const [formData, setFormData] = useState<{
        type: ContractType;
        relatedSide: string;
        signDate: Date | null;
        validUntil: Date | null;
    }>({
        type: contract.type,
        relatedSide: contract.relatedSide,
        signDate: contract.signDate ? moment.unix(contract.signDate).toDate() : null,
        validUntil: contract.validUntil ? moment.unix(contract.validUntil).toDate() : null,
    });

    const [errors, setErrors] = useState<FieldErrors>({});
    const [isValidUntilDisabled, setIsValidUntilDisabled] = useState(!formData.signDate);

    useEffect(() => {
        return () => {
            dispatch(setEditContractTaskStatus(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (editContractTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editContractTaskStatus?.fieldErrors.forEach((it) => (updatedErrors[it.field] = it.message));
            setErrors(updatedErrors);
        }
    }, [editContractTaskStatus]);

    useEffect(() => {
        setIsValidUntilDisabled(!formData.signDate);
        if (formData.validUntil && formData.signDate && formData.validUntil < formData.signDate) {
            setFormData((prevData) => ({
                ...prevData,
                validUntil: null,
            }));
        }
    }, [formData.signDate]);

    const handleSaveClick = async () => {
        if (!formData.type || !formData.relatedSide || !formData.signDate) {
            console.error("Zorunlu alanlar eksik!");
            return;
        }

        try {
            const result = await dispatch(
                editContractAction({
                    id: contract.id,
                    type: formData.type,
                    relatedSide: formData.relatedSide,
                    signDate: formData.signDate ? moment(formData.signDate).unix() : null,
                    validUntil: formData.validUntil ? moment(formData.validUntil).unix() : null,
                })
            );

            if (result.type === "contract/editContract/fulfilled") {
                handleEdit();
            }
        } catch (error) {
            console.error("Sözleşme güncelleme işlemi başarısız oldu: ", (error as any).message || error);
        }
    };

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeDate = (field: "signDate" | "validUntil", date: Date | null) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: date && !isNaN(date.getTime()) ? date : null,
        }));
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Sözleşmeyi Düzenle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="contract-type-label">Sözleşme Türü</InputLabel>
                    <Select
                        labelId="contract-type-label"
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChangeFormData}
                        label="Sözleşme Türü"
                        fullWidth>
                        {Object.values(ContractType).map((contractTypeValue) => (
                            <MenuItem key={contractTypeValue} value={contractTypeValue}>
                                {getPresenTableContractType(contractTypeValue)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.type && <ErrorText message={errors.type}/>}
                </FormControl>

                <TextField
                    label="İlgili Taraf"
                    name="relatedSide"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={formData.relatedSide}
                    onChange={handleChangeFormData}
                    error={!!errors.relatedSide}
                    helperText={errors.relatedSide}/>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="İmzalanma Tarihi"
                            value={formData.signDate}
                            onChange={(newDate) => handleChangeDate("signDate", newDate)}
                            //@ts-ignore
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!errors.signDate}
                                    helperText={errors.signDate}
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"/>
                            )}/>
                    </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Geçerlilik Tarihi"
                            value={formData.validUntil}
                            onChange={(newDate) => handleChangeDate("validUntil", newDate)}
                            minDate={formData.signDate || undefined}
                            disabled={isValidUntilDisabled}
                            //@ts-ignore
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"/>
                            )}/>
                    </LocalizationProvider>
                </FormControl>

                {editContractTaskStatus?.type === AsyncTaskStatusType.Error && editContractTaskStatus.errorMessage && (
                    <ErrorText type="form" message={editContractTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleSaveClick} color="primary"
                        disabled={editContractTaskStatus?.type === AsyncTaskStatusType.Loading}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
