import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Autocomplete, Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import {getAllCourtHousesAction, getCourtsAction, getLoadCitiesAction} from "../api/settings";
import {AppState} from "../store";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {CreateCourt} from "./create-court/createCourtDialog";
import {getPresentableCourtType} from "../api/dtos";
import FormControl from "@mui/material/FormControl";
import {
    setAllPaginatedCourts,
    setLoadCitiesTaskStatus,
    setLoadCourtHousesTaskStatus
} from "../features/settings/settingsSlice";
import TextField from "@mui/material/TextField";
import {Option} from "../shared/utils";
import {EditCourtDialog} from "./edit-court/editCourtDialog";
import {DeleteCourtPopup} from "./delete-court/deleteCourt";
import CourtAction from "../actions/CourtAction";

export const Courts = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const page = Number(searchParams.get('page') || "1");
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [courtHouseId, setCourtHouseId] = useState<number | null>(null);
    const [cityId, setCityId] = useState<number | null>(null);
    const [createCourtDialogOpen, setCreateCourtDialogOpen] = useState(false);
    const [editCourtDialogOpen, setEditCourtDialogOpen] = useState(false);
    const [deleteCourtDialogOpen, setDeleteCourtDialogOpen] = useState(false);
    const [activeCourt, setActiveCourt] = useState<any | null>(null);
    const allCourtHouses = useSelector((state: AppState) => state.settings.allCourtHouses);
    const allCities = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const courts = useSelector((state: AppState) => state.settings.allPaginatedCourts);

    useEffect(() => {
        dispatch(getAllCourtHousesAction());
        dispatch(getLoadCitiesAction());
        return () => {
            dispatch(setLoadCourtHousesTaskStatus(null));
            dispatch(setLoadCitiesTaskStatus(null));
            dispatch(setAllPaginatedCourts(null));
        }
    }, [dispatch]);

    useEffect(() => {
        if (courtHouseId && cityId) {
            dispatch(getCourtsAction({courtHouseId, page}));
        }
    }, [dispatch, courtHouseId, cityId, page]);

    const handleCreateCourtDialogOpen = () => setCreateCourtDialogOpen(true);

    const handleCreateCourtDialogClose = () => setCreateCourtDialogOpen(false);

    const handleEditCourtDialogOpen = (court: any) => {
        setActiveCourt(court);
        setEditCourtDialogOpen(true);
    };

    const handleEditCourtDialogClose = () => {
        setEditCourtDialogOpen(false);
        setActiveCourt(null);
    };

    const handleDelete = () => {
        setActiveCourt(null);
        handleDeleteCourtDialogClose();

        if (page === 1) {
            if (courtHouseId) {
                dispatch(getCourtsAction({courtHouseId, page}));
            }
        } else {
            navigate("");
        }
    };

    const handleEditCourt = (cityId: number, courtHouseId: number) => {
        setActiveCourt(null);
        handleEditCourtDialogClose();
        setCityId(cityId);
        setCourtHouseId(courtHouseId);
        if (page === 1) {
            dispatch(getCourtsAction({courtHouseId, page}));
        } else {
            navigate("");
        }
    }

    const handleAddCourt = (cityId: number, courtHouseId: number) => {
        handleCreateCourtDialogClose();
        setCityId(cityId);
        setCourtHouseId(courtHouseId);
        if (page === 1) {
            dispatch(getCourtsAction({courtHouseId, page}));
        } else {
            navigate("");
        }
    }

    const openDeleteCourt = () => {
        setActiveCourt(null);
        setDeleteCourtDialogOpen(true);
    };

    const handleDeleteCourtDialogClose = () => {
        setDeleteCourtDialogOpen(false);
        setActiveCourt(null);
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const handleCourtHouseChange = (_: React.SyntheticEvent, newValue: Option | null) => {
        setCourtHouseId(newValue?.value || null);
        if (newValue?.value) {
            dispatch(getCourtsAction({courtHouseId: Number(newValue.value), page}));
        } else {
            dispatch(setAllPaginatedCourts(null));
        }
    };

    const handleCityChange = (_: React.SyntheticEvent, newValue: Option | null) => {
        setCityId(newValue?.value || null);
        setCourtHouseId(null);
        dispatch(setAllPaginatedCourts(null));
    };

    const courtHouseOptions: Option[] = allCourtHouses?.filter(
        (allCourtHouse) => allCourtHouse.city.plateId === cityId
    ).map((courtHouse) => ({
        value: courtHouse.id,
        label: courtHouse.name
    })) || [];

    const cityOptions: Option[] = allCities?.map((city) => ({
        value: city.id,
        label: city.value
    })) || [];

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>MAHKEME / SAVCILIK / İCRA DAİRESİ</h1>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <Autocomplete
                        onChange={handleCityChange}
                        disablePortal
                        id="cityId"
                        value={cityOptions.find((it) => it.value === cityId) || null}
                        options={cityOptions}
                        sx={{width: 400}}
                        renderInput={(params) =>
                            <TextField {...params}
                                       margin="dense"
                                       label="Şehir"
                                       autoComplete="new-city-id"/>
                        }/>
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <Autocomplete
                        onChange={handleCourtHouseChange}
                        disablePortal
                        id="court-house-id"
                        disabled={!cityId}
                        options={courtHouseOptions}
                        sx={{width: 400}}
                        value={courtHouseOptions.find((it) => it.value === courtHouseId) || null}
                        renderInput={(params) =>
                            <TextField {...params}
                                       margin="dense"
                                       label="Adliye"
                                       autoComplete="new-court-house-id"/>
                        }/>
                </FormControl>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateCourtDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {createCourtDialogOpen && (
                <CreateCourt handleClose={handleCreateCourtDialogClose} handleAddCourt={handleAddCourt}/>
            )}

            {editCourtDialogOpen && activeCourt && (
                <EditCourtDialog
                    handleEditCourt={handleEditCourt}
                    handleClose={handleEditCourtDialogClose}
                    court={activeCourt}/>
            )}

            {deleteCourtDialogOpen && activeCourt && (
                <DeleteCourtPopup
                    court={activeCourt}
                    onClose={handleDeleteCourtDialogClose}
                    handleDeleteCourt={handleDelete}/>
            )}
            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label='court table'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "40%"}}>ADI</TableCell>
                            <TableCell style={{width: "40%"}}>TÜRÜ</TableCell>
                            <TableCell style={{width: "20%"}}>İŞLEMLER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courts?.entity.map((court) => (
                            <TableRow key={court.id}>
                                <TableCell>{court.name}</TableCell>
                                <TableCell>{getPresentableCourtType(court.type)}</TableCell>
                                <TableCell>
                                    <CourtAction
                                        court={court}
                                        onEdit={handleEditCourtDialogOpen}
                                        onDelete={openDeleteCourt}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            {courts?.totalNumberPages != null && courts.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={courts.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
};
