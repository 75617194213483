import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {ContractDTO, PaginatedEntityList} from "../../api/dtos";

export interface ContractState {
    allPaginatedContracts: PaginatedEntityList<ContractDTO> | null;
    getAllPaginatedContractsStatus: AsyncTaskStatus | null;
    createContractTaskStatus: AsyncTaskStatus | null;
    updateContractStatusTaskStatus: AsyncTaskStatus | null;
    deleteContractTaskStatus: AsyncTaskStatus | null;
    editContractTaskStatus: AsyncTaskStatus | null;
}

const initialState: ContractState = {
    allPaginatedContracts: null,
    getAllPaginatedContractsStatus: null,
    createContractTaskStatus: null,
    updateContractStatusTaskStatus: null,
    deleteContractTaskStatus: null,
    editContractTaskStatus: null
};

const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setUpdateContractStatusTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateContractStatusTaskStatus = action.payload;
        },
        setCreateContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createContractTaskStatus = action.payload;
        },
        setGetAllPaginatedContractsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedContractsStatus = action.payload;
        },
        setAllPaginatedContracts: (state, action: PayloadAction<PaginatedEntityList<ContractDTO> | null>) => {
            state.allPaginatedContracts = action.payload;
        },
        setDeleteContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteContractTaskStatus = action.payload;
        },
        setEditContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editContractTaskStatus = action.payload;
        }
    }
});

export const {
    setCreateContractTaskStatus,
    setUpdateContractStatusTaskStatus,
    setGetAllPaginatedContractsTaskStatus,
    setAllPaginatedContracts,
    setDeleteContractTaskStatus,
    setEditContractTaskStatus
} = contractSlice.actions;

export default contractSlice.reducer;
