import React, {useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {ContractDTO} from "../../api/dtos";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {deleteContractAction} from "../../api/contract";
import {setDeleteContractTaskStatus} from "../../features/contracts/contractSlice";

interface DeleteContractDialogProps {
    handleClose: () => void;
    handleDelete: () => void;
    contract: ContractDTO;
}

export const DeleteContractDialog: React.FC<DeleteContractDialogProps> = ({contract, handleClose, handleDelete}) => {
    const deleteContractTaskStatus = useSelector((state: AppState) => state.contract.deleteContractTaskStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setDeleteContractTaskStatus(null));
        };
    }, []);

    const handleDeleteClick = async () => {
        try {
            const result = await dispatch(deleteContractAction(contract.id));
            if (result.type === 'contract/deleteContract/fulfilled') {
                handleDelete();
            }
        } catch (error) {
            console.error("Sözleşme silme işleminde bir hata oluştu:", error);
        }
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Sözleşme Sil ({contract.id})</DialogTitle>
            <DialogContent>
                Seçilen Sözleşmeyi Silmek İstiyor musunuz?
                {deleteContractTaskStatus?.type === AsyncTaskStatusType.Error && deleteContractTaskStatus.errorMessage && (
                    <ErrorText type="form" message={deleteContractTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleDeleteClick} color="error">Sil</Button>
            </DialogActions>
        </CustomDialog>
    );
};
