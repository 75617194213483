import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    addNoteToLegalCaseAction,
    deleteLegalCaseAction,
    getLegalCasesAction,
    updateLegalCaseStatusAction
} from "../../api/legalCase";
import React, {useEffect, useState} from "react";
import {createLegalCaseNoticeAction} from "../../api/settings";
import {CreateLegalNoticeDTO, getPresentableLegalCaseStatus, LegalCaseDTO, LegalCaseStatus} from "../../api/dtos";
import FormControl from "@mui/material/FormControl";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import CustomDialog from "../../components/CustomDialog";
import {AppState} from "../../store";
import {getAllUsersAction} from "../../api/appUser";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import {setAllUsersLookup} from "../../features/app-user/appUserSlice";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {setUpdateLegalCaseStatusTaskStatus} from "../../features/legal-case/legalCaseSlice";
import TextField from "@mui/material/TextField";

export interface AddNoteLegalCasePopupProps {
    legalCase: LegalCaseDTO;
    onClose: () => void;
    onAddNoteLegalCase: () => void;
}

export const AddNoteLegalCasePopup: React.FC<AddNoteLegalCasePopupProps> = ({
                                                                                legalCase,
                                                                                onClose,
                                                                                onAddNoteLegalCase
                                                                            }) => {
    const dispatch: any = useDispatch();
    const [note, setNote] = useState<string>('');
    const [errors, setErrors] = useState<FieldErrors>({});
    const addNoteToLegalCaseTaskStatus = useSelector((state: AppState) => state.legalCase.addNoteToLegalCaseTaskStatus);

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    }

    const handleSubmit = async () => {
        const result = await dispatch(addNoteToLegalCaseAction({id: legalCase.id, dto: {note}}));
        if (result.type === "legalCase/addNoteToLegalCaseAction/fulfilled") {
            onAddNoteLegalCase();
        }
    }

    useEffect(() => {
        if (addNoteToLegalCaseTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            addNoteToLegalCaseTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [addNoteToLegalCaseTaskStatus]);

    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Dava Notu Ekle ({legalCase.name})</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="note"
                        name="note"
                        label="Açıklama"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={note}
                        onChange={handleNoteChange}/>
                    {errors.note && <ErrorText message={errors.note}/>}
                </FormControl>
                {addNoteToLegalCaseTaskStatus?.type === AsyncTaskStatusType.Error && addNoteToLegalCaseTaskStatus.errorMessage && (
                    <ErrorText type="form" message={addNoteToLegalCaseTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button onClick={handleSubmit}>Kaydet</Button>
            </DialogActions>
        </CustomDialog>
    )
};

export interface DeleteLegalCasePopupProps {
    legalCase: LegalCaseDTO;
    onClose: () => void;
    onDeleteLegalCase: () => void;
    page: number;
}

export const DeleteLegalCasePopup: React.FC<DeleteLegalCasePopupProps> = ({
                                                                              legalCase,
                                                                              onDeleteLegalCase,
                                                                              onClose,
                                                                              page
                                                                          }) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleDelete = async () => {
        try {
            const result = await dispatch(deleteLegalCaseAction(legalCase.id));
            if (result.type === "legalCase/deleteLegalCase/fulfilled") {
                onDeleteLegalCase();

                if (page === 1) {
                    dispatch(getLegalCasesAction({
                        companyIdFilter: null,
                        clientIdFilter: null,
                        nameFilter: null,
                        typeFilter: null,
                        courtIdFilter: null,
                        statusFilter: null,
                        courtHouseFilter: null,
                        fileNumberFilter: null,
                        responsibleIdFilter: null,
                        page: 1
                    }));
                } else {
                    navigate("");
                }
            }
        } catch (error) {
            console.error("Form gönderiminde bir hata oluştu:", error);
        }
    };
    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Dava Sil ({legalCase.name})</DialogTitle>
            <DialogContent>
                Seçilen Davayı Silmek İstiyor musunuz?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button color="error" onClick={handleDelete}>Sil</Button>
            </DialogActions>
        </CustomDialog>
    )
};

interface ChangeStatusLegalCasePopupProps {
    legalCase: LegalCaseDTO;
    onClose: () => void;
    onChangeStatus: (legalCaseStatus: LegalCaseStatus) => void;
}

export const ChangeStatusLegalCasePopup: React.FC<ChangeStatusLegalCasePopupProps> = ({
                                                                                          legalCase,
                                                                                          onClose,
                                                                                          onChangeStatus
                                                                                      }) => {
    const [formData, setFormData] = useState<{ newStatus: LegalCaseStatus }>({
        newStatus: legalCase.status
    });

    const [errors, setErrors] = useState<FieldErrors>({});
    const updateLegalCaseStatusTaskStatus = useSelector((state: AppState) => state.legalCase.updateLegalCaseStatusTaskStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setUpdateLegalCaseStatusTaskStatus(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (updateLegalCaseStatusTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            updateLegalCaseStatusTaskStatus.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message
            );
            setErrors(updatedErrors);
        }
    }, [updateLegalCaseStatusTaskStatus]);

    const handleSaveClick = async () => {
        if (formData.newStatus !== legalCase.status) {
            try {
                const result = await dispatch(updateLegalCaseStatusAction({
                    legalCaseId: legalCase.id,
                    newStatus: formData.newStatus
                }));
                if (result.type === "legalCase/updateLegalCaseStatus/fulfilled") {
                    onChangeStatus(formData.newStatus);
                }
            } catch (error) {
                console.error("Güncelleme işlemi başarısız oldu: ", error);
            }
        }
    };

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Durumu Değiştir</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="new-status-label">Durum Seç</InputLabel>
                    <Select
                        labelId="new-status-label"
                        id="newStatus"
                        name="newStatus"
                        label="Durum Seç"
                        fullWidth
                        value={formData.newStatus}
                        onChange={handleChangeFormData}>
                        {Object.values(LegalCaseStatus).map((statusValue) => (
                            <MenuItem key={statusValue} value={statusValue}>
                                {getPresentableLegalCaseStatus(statusValue)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.newStatus && <ErrorText message={errors.newStatus}/>}
                </FormControl>
                {updateLegalCaseStatusTaskStatus?.type === AsyncTaskStatusType.Error && updateLegalCaseStatusTaskStatus.errorMessage && (
                    <ErrorText type="form" message={updateLegalCaseStatusTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button onClick={handleSaveClick} color="primary" disabled={formData.newStatus === legalCase.status}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
