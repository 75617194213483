import axios from "axios";
import {EndPoints} from "./EndPoints";
import {expiresIn, getAppToken, getAppUserId} from "../shared/utils";
import {
    logout,
    setAuthenticationResult,
    setLoginTaskStatus,
    setRefreshTokenTaskStatus
} from "../features/auth/authSlice";
import {AsyncTaskStatusType, AuthResponseDTO, ResponseDTO} from "../shared/dtos";
import {setLoadUserDataTaskStatus} from "../features/settings/settingsSlice";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {StorageKeys} from "./constants";

export const getUserDataAction = createAsyncThunk(
    'user/getUserData',
    async (_, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setLoadUserDataTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            const userId = getAppUserId();

            if (!token || !userId) {
                throw new Error('User token or ID is missing');
            }

            const response = await axios.get(`${EndPoints.user}/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                let userWithToken = getState().auth.userWithToken!;
                userWithToken = {...userWithToken, token, user: resultValue};
                dispatch(setAuthenticationResult(userWithToken));
                dispatch(setLoadUserDataTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadUserDataTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                localStorage.clear();
                sessionStorage.clear();
                dispatch(logout());
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadUserDataTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const authenticateUserAction = createAsyncThunk(
    'auth/authenticateUser',
    async ({email, password, rememberMe, mfaCode}: {
        email: string,
        password: string,
        rememberMe: boolean,
        mfaCode: string
    }, {
               dispatch,
               rejectWithValue
           }) => {
        // TODO: get from config
        const pushNotificationToken = "";

        dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const response = await axios.post(
                `${EndPoints.auth}/login`,
                {email, password, pushNotificationToken, mfaCode},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                const {token, refreshToken, user} = resultValue as AuthResponseDTO;
                const tokenExpiryIn = Date.now() + expiresIn * 1000;

                dispatch(setAuthenticationResult({user, token, refreshToken, tokenExpiryIn}));
                const usedStorage = rememberMe ? localStorage : sessionStorage;
                usedStorage.setItem(StorageKeys.TOKEN, token);
                usedStorage.setItem(StorageKeys.REFRESH_TOKEN, refreshToken);
                usedStorage.setItem(StorageKeys.TOKEN_EXPIRY, String(tokenExpiryIn));
                usedStorage.setItem(StorageKeys.USER_ID, String(user.id));
                dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Success}));

                return token;
            } else if (code === "MfaCodeRequired") {
                const errorMessage = "İki aşamalı doğrulama kodunu giriniz.";
                dispatch(setLoginTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage,
                    errorDetails
                }));
                return rejectWithValue(errorMessage);
            } else {
                dispatch(setLoginTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoginTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const refreshTokenAction = createAsyncThunk(
    'auth/refreshToken',
    async (_, {dispatch, getState, rejectWithValue}: any) => {
        dispatch(setRefreshTokenTaskStatus({type: AsyncTaskStatusType.Loading}));
        const state = getState();
        const refreshToken = state.auth.userWithToken?.refreshToken;

        if (!refreshToken) {
            return rejectWithValue('No refresh token available');
        }

        try {
            const response = await axios.post(
                `${EndPoints.auth}/refresh-token`,
                {refreshToken},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                const token = resultValue;
                const tokenExpiryIn = Date.now() + expiresIn * 1000;

                localStorage.setItem(StorageKeys.TOKEN, token);
                localStorage.setItem(StorageKeys.REFRESH_TOKEN, refreshToken);
                localStorage.setItem(StorageKeys.TOKEN_EXPIRY, String(tokenExpiryIn));

                let userWithToken = state.auth.userWithToken!;
                userWithToken = {...userWithToken, token, refreshToken, tokenExpiryIn};
                dispatch(setAuthenticationResult(userWithToken));

                return token;
            } else {
                dispatch(setRefreshTokenTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setRefreshTokenTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
