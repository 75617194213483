import axios from "axios";
import {EndPoints} from "./EndPoints";
import {AddTaskDTO, TaskPriority, TaskStatus} from "./dtos";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    setAllPaginatedTasks,
    setCreateTaskTaskStatus,
    setDeleteTaskTaskStatus,
    setEditTaskAssigneesTaskStatus,
    setEditTaskTaskStatus,
    setGetAllPaginatedTasksTaskStatus,
    setUpdateTaskStatusTaskStatus
} from "../features/task/taskSlice";
import {ErrorMessages, Headers} from "./constants";

export const createTaskAction = createAsyncThunk(
    'task/createTask',
    async (dto: AddTaskDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(EndPoints.task, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateTaskTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setCreateTaskTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllPaginatedTasksAction = createAsyncThunk(
    'task/getAllTasks',
    async ({
               legalCaseIdFilter,
               userIdFilter,
               textFilter,
               statusFilter,
               priorityFilter,
               companyIdFilter,
               page
           }: {
        legalCaseIdFilter: number | null;
        userIdFilter: number | null;
        textFilter: string | null;
        statusFilter: TaskStatus | null;
        priorityFilter: TaskPriority | null;
        companyIdFilter: number | null;
        page: number;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setGetAllPaginatedTasksTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({page: page.toString()});
            if (legalCaseIdFilter != null) params.append("legalCaseIdFilter", legalCaseIdFilter.toString());
            if (userIdFilter != null) params.append("userIdFilter", userIdFilter.toString());
            if (textFilter != null && textFilter !== "") params.append("textFilter", textFilter);
            if (statusFilter != null) params.append("statusFilter", statusFilter);
            if (priorityFilter != null) params.append("priorityFilter", priorityFilter);
            if (companyIdFilter != null) params.append("companyIdFilter", companyIdFilter.toString());

            const response = await axios.get(EndPoints.task + `/tasks-by-filter?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllPaginatedTasksTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedTasks(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setGetAllPaginatedTasksTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setGetAllPaginatedTasksTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const updateTaskStatusAction = createAsyncThunk(
    'task/updateTaskStatus',
    async ({
               taskId,
               newStatus,
               waitReason
           }: {
        taskId: number;
        newStatus: string;
        waitReason: string | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setUpdateTaskStatusTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/update-status`, {newStatus, waitReason},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    }
                }
            );

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setUpdateTaskStatusTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setUpdateTaskStatusTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setUpdateTaskStatusTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteTaskAction = createAsyncThunk(
    'tasks/deleteTask',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setDeleteTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.delete(`${EndPoints.task}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteTaskTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setDeleteTaskTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editTaskAction = createAsyncThunk(
    'task/editTask',
    async ({
               taskId,
               title,
               description,
               priority,
               dueDate
           }: {
        taskId: number;
        title: string;
        description: string;
        priority: TaskPriority;
        dueDate: number | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setEditTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}`, {
                title,
                description,
                priority,
                dueDate
            }, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditTaskTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditTaskTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setEditTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editTaskAssigneesAction = createAsyncThunk(
    'task/editTaskAssignees',
    async ({
               taskId,
               assigneeIds
           }: {
        taskId: number;
        assigneeIds: number[];
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setEditTaskAssigneesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/assignees`, {
                assigneeIds: assigneeIds
            }, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditTaskAssigneesTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditTaskAssigneesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setEditTaskAssigneesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
