import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import moment from "moment";
import CustomDialog from "../../components/CustomDialog";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import trLocale from 'date-fns/locale/tr';

interface AddTrialDateDialogProps {
    handleClose: () => void;
    addTrialDate: (result: any) => void;
}

interface FormData {
    date: Date;
}

export const AddTrialDateDialog: React.FC<AddTrialDateDialogProps> = ({handleClose, addTrialDate}) => {

    const handleDateChange = (newDate: Date | null) => {
        setFormData({
            ...formData,
            date: newDate || new Date()
        });
    };

    const [formData, setFormData] = useState<FormData>({
        date: new Date()
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        addTrialDate(moment(formData.date).format("YYYY-MM-DDTHH:mm:ss"));
        handleClose();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
            <CustomDialog
                open={true}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}>
                <DialogTitle>Duruşma Tarihi Seç</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <DatePicker
                            label="Duruşma Tarihi"
                            value={formData.date}
                            onChange={handleDateChange}
                            //@ts-ignore
                            renderInput={(params) => <TextField {...params} fullWidth/>}/>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>İptal</Button>
                    <Button type="submit">Ekle</Button>
                </DialogActions>
            </CustomDialog>
        </LocalizationProvider>
    );
}
