import {CourtHouseDTO} from "../../api/dtos";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomDialog from "../../components/CustomDialog";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {deleteCourtHouseAction, getCourtHousesAction} from "../../api/settings";

export interface DeleteCourtHouseCasePopupProps {
    courtHouse: CourtHouseDTO;
    onClose: () => void;
    onDeleteCourtHouse: () => void;
    page: number;
    filterByName?: string;
    cityPlateId?: number;
}

export const DeleteCourtHouseCasePopup: React.FC<DeleteCourtHouseCasePopupProps> = ({
                                                                                        courtHouse,
                                                                                        onDeleteCourtHouse,
                                                                                        onClose,
                                                                                        page,
                                                                                        filterByName,
                                                                                        cityPlateId
                                                                                    }) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleDelete = async () => {
        try {
            const result = await dispatch(deleteCourtHouseAction(courtHouse.id));
            if (result.type === 'courts/deleteCourtHouse/fulfilled') {
                onDeleteCourtHouse();
                if (page === 1) {
                    dispatch(getCourtHousesAction({filterByName, cityPlateId, page}));
                } else {
                    navigate("");
                }
            }
        } catch (error) {
            console.error("Adliye silme işleminde bir hata oluştu:", error);
        }
    };

    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Adliye Sil ({courtHouse.name})</DialogTitle>
            <DialogContent>
                Seçilen Adliyeyi Silmek İstiyor musunuz?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button color="error" onClick={handleDelete}>Sil</Button>
            </DialogActions>
        </CustomDialog>
    );
};
