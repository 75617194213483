import {Box, Fab} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

export const LegalCaseExpenses = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
            }}>
            <Fab
                size="small"
                color="primary"
                aria-label="add"
                sx={{marginLeft: "auto", marginBottom: 7}}
                onClick={() => {
                }}>
                <AddIcon/>
            </Fab>
        </Box>
    );
}


