import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, Select, TextField,} from '@mui/material';
import {getPresentableTaskPriority, TaskDTO, TaskPriority} from "../../api/dtos";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CustomDialog from "../../components/CustomDialog";
import {editTaskAction} from "../../api/task";
import {setEditTaskTaskStatus} from "../../features/task/taskSlice";
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {tr} from 'date-fns/locale';
import {getAllUsersAction, getUsersAction} from "../../api/appUser";
import {setAllPaginatedUsers, setAllUsersLookup} from "../../features/app-user/appUserSlice";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import {getAllCompaniesAction} from "../../api/settings";

interface EditTaskDialogProps {
    handleClose: () => void;
    handleEditTask: () => void;
    task: TaskDTO;
}

export const EditTaskDialog: React.FC<EditTaskDialogProps> = ({
                                                                  task,
                                                                  handleClose,
                                                                  handleEditTask,
                                                              }) => {
    const editTaskTaskStatus = useSelector((state: AppState) => state.task.editTaskTaskStatus);
    const [formData, setFormData] = useState<{
        title: string;
        description: string;
        priority: TaskPriority;
        dueDate: Date | null;
    }>({
        title: task.title,
        description: task.description,
        priority: task.priority,
        dueDate: task.dueDate ? moment.unix(task.dueDate).toDate() : null,
    });

    const [errors, setErrors] = useState<FieldErrors>({});
    const dispatch: any = useDispatch();
    const [dateError, setDateError] = useState<boolean>(false);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const useAssociatedCompanyId = currentUser?.associatedCompany?.id;

    useEffect(() => {
        if (useAssociatedCompanyId) {
            // @TODO: add getAllUsersByFilter if all companies not loaded
            dispatch(getUsersAction({companyIdFilter: currentUser?.associatedCompany?.id, page: 1}));
        } else {
            dispatch(getAllCompaniesAction());
            dispatch(getAllUsersAction());
        }
        return () => {
            dispatch(setEditTaskTaskStatus(null));
            dispatch(setAllPaginatedUsers(null));
            dispatch(setAllUsersLookup(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (editTaskTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            editTaskTaskStatus?.fieldErrors.forEach((it) => updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [editTaskTaskStatus]);

    const handleSaveClick = async () => {
        try {
            const result = await dispatch(editTaskAction({
                taskId: task.id,
                title: formData.title,
                description: formData.description,
                priority: formData.priority,
                dueDate: formData.dueDate ? moment(formData.dueDate).unix() : null
            }));
            if (result.type === "task/editTask/fulfilled") {
                handleEditTask();
            }
        } catch (error) {
            console.error("Task güncelleme işlemi başarısız oldu: ", (error as any).message || error);
        }
    };

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleChangeDueDate = (newDate: Date | null) => {
        setFormData((prevData) => {
            const checkedData = (newDate && !isNaN(newDate.getTime())) ? newDate : null;
            return {
                ...prevData,
                dueDate: checkedData
            };
        });
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Görevi Düzenle</DialogTitle>
            <DialogContent>
                <TextField
                    label="Görev Başlığı"
                    name="title"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={formData.title}
                    onChange={handleChangeFormData}
                    error={!!errors.title}
                    helperText={errors.title}/>
                <TextField
                    label="Görev Açıklaması"
                    name="description"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={formData.description}
                    onChange={handleChangeFormData}
                    error={!!errors.description}
                    helperText={errors.description}/>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="priority-label">Öncelik</InputLabel>
                    <Select
                        labelId="priority-label"
                        id="priority"
                        name="priority"
                        value={formData.priority}
                        onChange={handleChangeFormData}
                        label="Öncelik"
                        fullWidth>
                        {Object.values(TaskPriority).map((priorityValue) => (
                            <MenuItem key={priorityValue} value={priorityValue}>
                                {getPresentableTaskPriority(priorityValue)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.priority && <ErrorText message={errors.priority}/>}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Hedeflenen Tarih"
                            value={formData.dueDate !== null ? new Date(formData.dueDate) : null}
                            onChange={handleChangeDueDate}
                            //@ts-ignore
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!errors.dueDate}
                                    helperText={errors.dueDate}
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"/>
                            )}/>
                    </LocalizationProvider>
                    {dateError && (
                        <ErrorText message={dateError ? 'Son tarih seçilmesi zorunludur' : ''}/>
                    )}
                    {errors.dueDate && (
                        <ErrorText message={errors.dueDate}/>
                    )}
                </FormControl>
                {editTaskTaskStatus?.type === AsyncTaskStatusType.Error && editTaskTaskStatus.errorMessage && (
                    <ErrorText type="form" message={editTaskTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button
                    onClick={handleSaveClick}
                    color="primary"
                    disabled={editTaskTaskStatus?.type === AsyncTaskStatusType.Loading}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
