import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import CustomDialog from "../../components/CustomDialog";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import {resetPasswordAction} from "../../api/appUser";
import {PasswordResetDTO, UserDTO} from "../../api/dtos";
import ErrorText from "../../components/ErrorText";
import {setResetPasswordTaskStatus} from "../../features/app-user/appUserSlice";

interface ResetPasswordDialogProps {
    user: UserDTO;
    handleClose: () => void;
}

interface ResetPasswordFormData {
    password: string;
    passwordConfirmation: string;
}

export const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({handleClose, user}) => {
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [hasConfirmationError, setHasConfirmationError] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(setResetPasswordTaskStatus(null));
        }
    }, []);

    const [formData, setFormData] = useState<ResetPasswordFormData>({
        password: "",
        passwordConfirmation: ""
    });
    const resetPasswordTaskStatus = useSelector((state: AppState) => state.appUser.resetPasswordTaskStatus);

    const convertDTO = (data: ResetPasswordFormData): PasswordResetDTO => ({
        oldPassword: null,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation
    });

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const result = await dispatch(resetPasswordAction({id: user.id, dto: convertDTO(formData)}));

        if (result.type === 'user/resetPassword/fulfilled') {
            handleClose();
        }
    };

    useEffect(() => {
        if (formData.password === formData.passwordConfirmation) {
            setErrors((prevErrors) => {
                const {passwordConfirmation, ...rest} = prevErrors;
                return rest;
            });
            setHasConfirmationError(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordConfirmation: "Şifreler Uyuşmuyor."
            }));
            setHasConfirmationError(true);
        }
    }, [formData.password, formData.passwordConfirmation]);

    useEffect(() => {
        if (resetPasswordTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            resetPasswordTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [resetPasswordTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Şifre Güncelle ({user.firstName} {user.lastName})</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        name="password"
                        label="Şifre"
                        type="password"
                        fullWidth
                        autoComplete="new-password"
                        variant="outlined"
                        value={formData.password}
                        onChange={handleChangeFormData}/>
                    {errors.password && (
                        <ErrorText message={errors.password}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        label="Şifre Onay"
                        type="password"
                        autoComplete="new-password"
                        fullWidth
                        variant="outlined"
                        value={formData.passwordConfirmation}
                        onChange={handleChangeFormData}/>
                    {errors.passwordConfirmation && (
                        <ErrorText message={errors.passwordConfirmation}/>
                    )}
                </FormControl>
                {resetPasswordTaskStatus?.type === AsyncTaskStatusType.Error && resetPasswordTaskStatus.errorMessage && (
                    <ErrorText type="form" message={resetPasswordTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit" disabled={hasConfirmationError}>Değiştir</Button>
            </DialogActions>
        </CustomDialog>
    );
};
