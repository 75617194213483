// ** React Import
import {ReactNode} from 'react'

// ** Next Import
// ** MUI Imports
import Box, {BoxProps} from '@mui/material/Box'
import {styled, useTheme} from '@mui/material/styles'
import Typography, {TypographyProps} from '@mui/material/Typography'

// ** Type Import
import {Settings} from '../../../../context/settingsContext'

// ** Configs
import themeConfig from '../../../../../configs/themeConfig'
import {Link} from 'react-router-dom'

interface Props {
    hidden: boolean
    settings: Settings
    toggleNavVisibility: () => void
    saveSettings: (values: Settings) => void
    verticalNavMenuBranding?: (props?: any) => ReactNode
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4.5),
    transition: 'padding .25s ease-in-out',
    minHeight: theme.mixins.toolbar.minHeight
}))

const HeaderTitle = styled(Typography)<TypographyProps>(({theme}) => ({
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    transition: 'opacity .25s ease-in-out, margin .25s ease-in-out'
}))

const StyledLink = styled('a')({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
})

const VerticalNavHeader = (props: Props) => {
    // ** Props
    const {verticalNavMenuBranding: userVerticalNavMenuBranding} = props

    // ** Hooks
    const theme = useTheme()

    return (
        <MenuHeaderWrapper className='nav-header' sx={{pl: 6}}>
            {userVerticalNavMenuBranding ? (
                userVerticalNavMenuBranding(props)
            ) : (
                <Link to='/'>
                    <StyledLink>
                        <img height={50} width={50} src='/avatar.png'/>
                        <HeaderTitle variant='h6' sx={{ml: 3}}>
                            {themeConfig.templateName}
                        </HeaderTitle>
                    </StyledLink>
                </Link>
            )}
        </MenuHeaderWrapper>
    )
}

export default VerticalNavHeader
