import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as XLSX from "xlsx-js-style";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {robotoRegularBase64} from "../shared/pdf-fonts";
import {getAllClientsAction, getAllPaginatedClientsAction} from "../api/settings";
import {AppState} from "../store";
import {CreateClientDialog} from "./create-client/createClientDialog";
import {getPresentableClientType} from "../api/dtos";

export default function Clients() {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || "1");
    const filterByName = searchParams.get("filterByName") || "";
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllPaginatedClientsAction({filterByName, page}));
    }, [filterByName, page]);

    const clients = useSelector((state: AppState) => state.settings.allPaginatedClients);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("filterByName", event.target.value);
        searchParams.set("page", "1");
        navigate(`?${searchParams.toString()}`);
    };

    const handleCreateClientDialogOpen = () => setCreateClientDialogOpen(true);

    const handleCreateClientDialogClose = () => {
        setCreateClientDialogOpen(false);
        dispatch(getAllPaginatedClientsAction({filterByName, page}));
    };

    const exportToExcel = () => {
        if (clients?.entity) {
            const headers = [["Şirket Adı", "Tür", "Adres", "Şehir", "TCKN", "VKN"]];
            const worksheet = XLSX.utils.json_to_sheet(
                clients.entity.map((client: any) => ({
                    "Şirket Adı": client.name,
                    "Tür": client.type,
                    "Adres": client.address,
                    "Şehir": client.city.name,
                    "TCKN": client.tckn || "-",
                    "VKN": client.vkn || "-",
                }))
            );
            XLSX.utils.sheet_add_aoa(worksheet, headers, {origin: "A1"});
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Şirket Listesi");
            XLSX.writeFile(workbook, "karşı_taraf_listesi.xlsx");
        }
    };

    const exportToPDF = () => {
        if (clients?.entity) {
            const doc = new jsPDF();
            doc.addFileToVFS("Roboto-Regular.ttf", robotoRegularBase64);
            doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
            doc.setFont("Roboto");

            doc.setFontSize(18);
            doc.text("Diğer Şirketler Listesi", 14, 20);

            const tableData = clients.entity.map((client: any) => [
                client.name,
                client.type,
                client.address,
                client.city.name,
                client.tckn || "-",
                client.vkn || "-",
            ]);

            const tableColumns = ["Şirket Adı", "Tür", "Adres", "Şehir", "TCKN", "VKN"];

            autoTable(doc, {
                head: [tableColumns],
                body: tableData,
                startY: 30,
                margin: {horizontal: 10},
                styles: {
                    font: "Roboto",
                    cellPadding: 3,
                    fontSize: 10,
                    valign: "middle",
                    halign: "left",
                },
            });

            doc.save("karşı_taraf_listesi.pdf");
        }
    };

    const handleCreate = () => {
        navigate("");
        dispatch(getAllPaginatedClientsAction({filterByName, page}));
    }

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>KARŞI TARAF</h1>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2}}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateClientDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
                <Box sx={{display: "flex", gap: 2, mb: 2}}>
                    <Button variant="contained" color="primary" onClick={exportToExcel}>
                        Excel'e Dışa Aktar
                    </Button>
                    <Button variant="contained" color="secondary" onClick={exportToPDF}>
                        PDF'e Dışa Aktar
                    </Button>
                </Box>
            </div>

            {createClientDialogOpen && (
                <CreateClientDialog handleClose={handleCreateClientDialogClose}
                                    handleCreate={handleCreate}/>
            )}

            <Box sx={{overflowX: "auto"}}>
                <Table sx={{minWidth: 800}} aria-label="clients table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ŞİRKET ADI</TableCell>
                            <TableCell>TÜR</TableCell>
                            <TableCell>ADRES</TableCell>
                            <TableCell>ŞEHİR</TableCell>
                            <TableCell>TCKN</TableCell>
                            <TableCell>VKN</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clients?.entity.map((client) => (
                            <TableRow key={client.id}>
                                <TableCell>{client.name}</TableCell>
                                <TableCell>{getPresentableClientType(client.type)}</TableCell>
                                <TableCell>{client.address}</TableCell>
                                <TableCell>{client.city.name}</TableCell>
                                <TableCell>{client.tckn}</TableCell>
                                <TableCell>{client.vkn}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {clients?.totalNumberPages != null && clients.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={clients.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}
