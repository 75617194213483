import React from "react";
import classNames from "classnames";

export interface DragHandlerProps {
    active: boolean;
    appendedNode: Node & ParentNode | null;
    dragging?: boolean;
    onMouseUp: (e: MouseEvent) => void;
    onDragStart: () => void;
    children: any;
    style?: React.CSSProperties;
    className?: string;
}

// TODO: use functional component
export class DragHandler extends React.Component<DragHandlerProps> {
    private intervalId: NodeJS.Timeout | undefined;
    private dragHandler?: HTMLSpanElement;
    private ghostElement: HTMLElement | null = null;

    public componentDidMount(): void {
        if (this.props.active && this.dragHandler) {
            this.dragHandler.onmousedown = this.onMouseDown;
        }
    }

    public componentDidUpdate(prevProps: Readonly<DragHandlerProps>): void {
        if (prevProps.active !== this.props.active) {
            if (this.dragHandler) {
                this.dragHandler.onmousedown = this.props.active
                    ? this.onMouseDown
                    : null;
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.dragHandler) {
            this.dragHandler.onmousedown = null;
        }
        this.removeInterval();
    }

    public render(): React.ReactNode {
        const {active, children, style, className, dragging} = this.props;
        const innerClassName = classNames("drag-handler", {active, dragging}, className);

        return (
            <span className={innerClassName} ref={this.refDragHandler} style={style}>
                {children}
            </span>
        );
    }

    private readonly removeInterval = () => {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    };

    private readonly refDragHandler = (dragHandler: HTMLSpanElement) => {
        if (dragHandler) {
            this.dragHandler = dragHandler;
        }
    };

    private readonly onMouseDown = (e: MouseEvent) => {
        e.preventDefault();
        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.onMouseUp);
    };

    private readonly onMouseUp = (e: MouseEvent): void => {
        const {onMouseUp, appendedNode, dragging} = this.props;
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
        if (dragging && appendedNode && appendedNode.lastChild) {
            appendedNode.removeChild(appendedNode.lastChild);
            this.removeInterval();
            onMouseUp(e);
            e.stopPropagation();
            e.preventDefault();
        }
    };

    private readonly onMouseMove = (e: MouseEvent): void => {
        const {onDragStart, dragging, appendedNode} = this.props;
        if (appendedNode) {
            if (!dragging) {
                this.ghostElement = this.dragHandler?.cloneNode(true) as HTMLElement;
                if (this.ghostElement) {
                    this.ghostElement.style.marginLeft = "0px";
                    this.ghostElement.style.position = "absolute";
                    this.ghostElement.classList.add("dragging-item-info");
                    appendedNode.appendChild(this.ghostElement);
                    onDragStart();
                }
            } else {
                this.setTopAndLeftByMouseEvent(e);
            }
        }
        e.stopPropagation();
        e.preventDefault();
    };

    private readonly setTopAndLeft = (e: MouseEvent) => {
        if (this.ghostElement) {
            // pageX ve pageY kullanarak belge konumunu alıyoruz
            this.ghostElement.style.top = `${e.pageY}px`;
            this.ghostElement.style.left = `${e.pageX + 2}px`;
        }
    };

    private readonly checkGhostInView = (e: MouseEvent) => {
        const currentYCoord = e.pageY;
        const rootView = document.body;
        if (this.ghostElement && rootView) {
            const ghostHeight = this.ghostElement.clientHeight;
            return (
                currentYCoord > 0 &&
                currentYCoord < rootView.scrollHeight - rootView.scrollTop - ghostHeight
            );
        }
        return false;
    };

    private readonly setTopAndLeftByMouseEvent = (e: MouseEvent) => {
        this.removeInterval();
        const ghostHeight = this.ghostElement?.clientHeight || 0;
        const rootView = document.body;
        let ghostInView = this.checkGhostInView(e);

        if (ghostInView && rootView) {
            if (e.pageY > rootView.clientHeight - ghostHeight) {
                this.intervalId = setInterval(() => {
                    ghostInView = this.checkGhostInView(e);
                    if (ghostInView) {
                        rootView.scrollBy(0, 5);
                        this.setTopAndLeft(e);
                    } else {
                        this.removeInterval();
                    }
                }, 25);
            } else if (e.pageY < ghostHeight && rootView.scrollTop > 100) {
                this.intervalId = setInterval(() => {
                    ghostInView = this.checkGhostInView(e);
                    if (ghostInView) {
                        rootView.scrollBy(0, -5);
                        this.setTopAndLeft(e);
                    } else {
                        this.removeInterval();
                    }
                }, 25);
            } else {
                this.setTopAndLeft(e);
            }
        }
    };
}
