import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {getStatisticsAction} from '../api/legalCase';
import {AppState} from '../store';
import {useTheme} from '@mui/material/styles';
import UserLayout from "./UserLayout";
import ApexChartWrapper from "../theme/@core/styles/libs/react-apexcharts";

const UserStatistic = () => {
    const theme = useTheme();
    const dispatch: any = useDispatch();

    const statistics = useSelector((state: AppState) => state.legalCase.statistics);

    useEffect(() => {
        dispatch(getStatisticsAction());
    }, [dispatch]);

    if (!statistics) {
        return null;
    }
    const userCategories = statistics.statisticsByUser.map((stat) => stat.name);
    const userOpenCategories = statistics.statisticsByUserOpen.map((stat) => stat.name);

    const createOptions = (categories: string[]): ApexOptions => ({
        chart: {
            parentHeightOffset: 0,
            toolbar: {show: false},
            stacked: false,
            zoom: {enabled: false},
            selection: {enabled: false},
        },
        plotOptions: {bar: {borderRadius: 4, distributed: false, columnWidth: "16px"}},
        stroke: {width: 2, colors: [theme.palette.background.paper]},
        dataLabels: {enabled: false},
        legend: {show: true},
        xaxis: {
            categories: categories,
            labels: {show: true},
            axisTicks: {show: false},
            axisBorder: {show: false}
        },
        yaxis: {
            show: true,
            min: 0,
            labels: {
                offsetX: -17,
                formatter: (val) => Math.ceil(val).toString()
            }
        },
        grid: {
            strokeDashArray: 7,
            padding: {top: -1, right: 0, left: -12, bottom: 5}
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `Dava Sayısı: ${val}`
            }
        },
        colors: [theme.palette.primary.main, '#0000ff']
    });

    const userData = {
        name: 'Kullanıcı İstatistikleri',
        data: userCategories.map((userCategory) => {
            const found = statistics.statisticsByUser.find((stat) => stat.name === userCategory);
            return found?.count || 0;
        })
    };

    const userOpenData = {
        name: 'Kullanıcıların Açık Dava İstatistikleri',
        data: userOpenCategories.map(userOpenCategory => {
            const found = statistics.statisticsByUserOpen.find((stat) => stat.name === userOpenCategory);
            return found?.count || 0;
        })
    };

    return (
        <UserLayout>
            <ApexChartWrapper>
                <Card>
                    <CardContent>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h6" gutterBottom>
                                {userData.name}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Bu grafik, kullanıcılar tarafından oluşturulan davaların dağılımını sunar.
                            </Typography>
                            <Chart
                                type="bar"
                                height={300}
                                options={createOptions(userCategories)}
                                series={[userData, userOpenData]}/>
                        </Grid>
                    </CardContent>
                </Card>
            </ApexChartWrapper>
        </UserLayout>
    );
};

export default UserStatistic;
