// ** Types Import
import {Settings} from '../../../../context/settingsContext'
import {NavLink, NavSectionTitle, VerticalNavItemsType} from '../../../types'

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import Collapse from "@mui/material/Collapse";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../../store";
import {setOpenSections} from "../../../../../../features/appData/appDataSlice";

interface Props {
    settings: Settings
    navVisible?: boolean
    groupActive: string[]
    currentActiveGroup: string[]
    verticalNavItems?: VerticalNavItemsType
    saveSettings: (values: Settings) => void
    setGroupActive: (value: string[]) => void
    setCurrentActiveGroup: (item: string[]) => void
}

const VerticalNavItems = (props: Props) => {
    const {verticalNavItems} = props;
    const openSections = useSelector((appState: AppState) => appState.appData.openSections);
    const dispatch: any = useDispatch();

    const toggleSection = (sectionTitle: string) => {
        dispatch(setOpenSections({
            ...openSections,
            [sectionTitle]: !openSections[sectionTitle]
        }));
    };

    let currentGroup: { title: NavSectionTitle | null; contents: NavLink[] } = {
        title: null,
        contents: []
    };

    const groupedItems: { title: NavSectionTitle | null; contents?: NavLink[]; visible?: boolean; }[] =
        verticalNavItems?.map(item => {
            if (currentGroup.title || currentGroup.contents.length > 0 && currentGroup) {
                groupedItems.push(currentGroup);
            }

            return {
                title: item as NavSectionTitle,
                contents: item.items,
                visible: item.visible
            };
        }) || [];

    return (
        <>
            {groupedItems.filter((it) => (it.visible === undefined || it.visible) && it.contents && it.contents?.length > 0)
                .map((item, index) => {
                    const isOpen = openSections[item.title!.sectionTitle];
                    const innerToggleSection = () => toggleSection(item.title!.sectionTitle);
                    return (
                        <li key={index}>
                            <VerticalNavSectionTitle
                                {...props}
                                item={item.title!}
                                isOpen={isOpen}
                                toggleSection={innerToggleSection}/>

                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                {item.contents?.filter((it) => it.visible === undefined || it.visible)
                                    .map((it, key) => (
                                        <VerticalNavLink
                                            {...props}
                                            key={key}
                                            item={it}
                                            toggleNavVisibility={() => {
                                                /* Implement nav visibility toggle here */
                                            }}/>
                                    ))}
                            </Collapse>
                        </li>
                    );
                })}
        </>
    )
}

export default VerticalNavItems;
