import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Edit, Lock, Settings} from "@mui/icons-material";
import {UserDTO, UserPermission} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import Tooltip from '@mui/material/Tooltip';
import {adminOrHasPermission} from "../shared/utils";

interface UserActionProps {
    user: UserDTO;
    onEdit: (user: UserDTO) => void;
    onChangePassword: (user: UserDTO) => void;
    onEditPermissions: (user: UserDTO) => void;
}

const UserAction: React.FC<UserActionProps> = ({
                                                   user,
                                                   onEdit,
                                                   onChangePassword,
                                                   onEditPermissions,
                                               }) => {
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const editUser = () => onMenuItemClick(() => onEdit(user));
    const resetPassword = () => onMenuItemClick(() => onChangePassword(user));
    const editPermissions = () => onMenuItemClick(() => onEditPermissions(user));

    const canEditUser = adminOrHasPermission(currentUser, UserPermission.USER_EDIT);
    const canResetPassword = adminOrHasPermission(currentUser, UserPermission.USER_PASSWORD_RESET);
    const forOtherUser = currentUser?.id !== user.id;
    const canEditPermissions = adminOrHasPermission(currentUser, UserPermission.USER_ADD) && forOtherUser;

    const hasAtLeastOneAction = canEditUser || canResetPassword || canEditPermissions;

    return hasAtLeastOneAction
        ? (
            <div>
                <IconButton
                    aria-controls={open ? 'user-action-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="small"
                    aria-label="settings"
                    className="card-more-options"
                    sx={{color: 'text.secondary'}}>
                    <DotsVertical/>
                </IconButton>
                <Menu
                    id="user-action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    {canEditUser && (
                        <MenuItem onClick={editUser}>
                            <IconButton size="small" aria-label="edit" className="card-more-options"
                                        sx={{color: 'text.secondary'}}>
                                <Edit/>
                            </IconButton>
                            Kullanıcı Düzenle
                        </MenuItem>
                    )}
                    {canResetPassword && (
                        <MenuItem onClick={resetPassword}>
                            <IconButton
                                size="small"
                                aria-label="change-password"
                                className="card-more-options"
                                sx={{color: 'text.secondary'}}>
                                <Lock/>
                            </IconButton>
                            Şifre Güncelle
                        </MenuItem>
                    )}
                    {canEditPermissions && (
                        <MenuItem onClick={editPermissions}>
                            <IconButton size="small" aria-label="edit-permissions" className="card-more-options"
                                        sx={{color: 'text.secondary'}}>
                                <Settings/>
                            </IconButton>
                            İzinleri Düzenle
                        </MenuItem>
                    )}
                </Menu>
            </div>
        )
        : (
            <Tooltip
                title="Bu kullanıcı üzerinde işlem yetkiniz yok">
                <IconButton
                    size="small"
                    aria-label="settings"
                    className="card-more-options"
                    sx={{color: 'action.disabled'}}>
                    <DotsVertical/>
                </IconButton>
            </Tooltip>
        );
};

export default UserAction;