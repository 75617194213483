import {UserRole} from "../api/dtos";

export enum AsyncTaskStatusType {
    Loading = "Loading",
    Success = "Success",
    Error = "Error"
}

export interface FieldErrors {
    [field: string]: string;
}

export interface FieldErrorDTO {
    field: string;
    message: string;
}

export interface AsyncTaskStatus {
    type: AsyncTaskStatusType;
    errorMessage?: string;
    fieldErrors?: FieldErrorDTO[];
    errorDetails?: any;
}

export interface CurrentUserDTO {
    id: number;
    email: string;
    role: UserRole;
    firstName: string;
    lastName: string;
    locked: boolean;
    deleted: boolean;
    mfaEnabled: boolean;
}

export interface AuthResponseDTO {
    token: string;
    refreshToken: string;
    user: CurrentUserDTO;
}

export namespace ResponseDTO {
    export enum CodeEnum {
        DuplicateObject = 'DuplicateObject',
        Forbidden = 'Forbidden',
        InternalError = 'InternalError',
        InvalidFile = 'InvalidFile',
        InvalidObjectState = 'InvalidObjectState',
        MethodNotAllowed = 'MethodNotAllowed',
        ObjectNotFound = 'ObjectNotFound',
        Success = 'Success',
        UnsupportedMediaType = 'UnsupportedMediaType',
        ValidationError = 'ValidationError'
    }
}

export interface FieldErrorDTO {
    field: string;
    message: string;
}

export interface ResponseDTO {
    code: ResponseDTO.CodeEnum;
    errorDetails?: any;
    fieldErrors: Array<FieldErrorDTO>;
    message: string;
    resultValue?: any;
}
