import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import CustomDialog from "../../components/CustomDialog";
import {createClientAction, getLoadCitiesAction} from "../../api/settings";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {ClientType, AddClientDTO, getPresentableClientType} from "../../api/dtos";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import {setCreateClientTaskStatus} from "../../features/settings/settingsSlice";

interface CreateClientDialogProps {
    handleClose: () => void;
    handleCreate: () => void;
}

export const CreateClientDialog: React.FC<CreateClientDialogProps> = ({handleClose, handleCreate}) => {
    const dispatch: any = useDispatch();
    const createClientTaskStatus = useSelector((state: AppState) => state.settings.createClientTaskStatus);
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);

    const [formData, setFormData] = useState<AddClientDTO>({
        name: "",
        type: ClientType.Person,
        address: "",
        city: 0,
    });

    useEffect(() => {
        if (!allCitiesLookup || allCitiesLookup.length === 0) {
            dispatch(getLoadCitiesAction());
        }
    }, [dispatch, allCitiesLookup]);

    const [errors, setErrors] = useState<FieldErrors>({});

    const cityOptions = allCitiesLookup?.map((city) => ({
        value: city.id,
        label: city.value,
    })) || [];

    const clientTypes = [
        {value: ClientType.Person, label: getPresentableClientType(ClientType.Person)},
        {value: ClientType.Institution, label: getPresentableClientType(ClientType.Institution)},
    ];

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const result = await dispatch(createClientAction(formData));
            if (result.type === "settings/createClient/fulfilled") {
                handleClose();
                handleCreate();
            }
        } catch (error: any) {
            setErrors({
                ...errors,
                form: error.message || "Bilinmeyen bir hata oluştu",
            });
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setCreateClientTaskStatus(null));
        };
    }, [dispatch]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Karşı Taraf Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        select
                        required
                        margin="dense"
                        id="type"
                        name="type"
                        label="Tür"
                        fullWidth
                        variant="outlined"
                        value={formData.type}
                        onChange={(e) => setFormData({...formData, type: e.target.value as ClientType})}>
                        {clientTypes.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        id="tckn"
                        name="tckn"
                        label="TCKN"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.tckn || ""}
                        onChange={(e) => setFormData({...formData, tckn: e.target.value})}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        id="vkn"
                        name="vkn"
                        label="VKN"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.vkn || ""}
                        onChange={(e) => setFormData({...formData, vkn: e.target.value})}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="address"
                        name="address"
                        label="Adres"
                        multiline
                        rows={3}
                        fullWidth
                        variant="outlined"
                        value={formData.address}
                        onChange={(e) => setFormData({...formData, address: e.target.value})}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setFormData({
                                ...formData,
                                city: newValue ? newValue.value : 0,
                            });
                        }}
                        options={cityOptions}
                        value={cityOptions.find((opt) => opt.value === formData.city) || null}
                        renderInput={(params) => (
                            <TextField {...params}
                                       label="Şehirler"
                                       variant="outlined"
                                       required/>
                        )}/>
                </FormControl>
                {createClientTaskStatus?.type === AsyncTaskStatusType.Error && createClientTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createClientTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
