import React, {useEffect, useState} from "react";
import {Box, Button, Pagination, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getAllPaginatedContractsAction} from "../api/contract";
import {AppState} from "../store";
import {
    ContractDTO,
    ContractStatus,
    ContractType,
    getPresentableContractStatus,
    getPresenTableContractType,
    UserPermission
} from "../api/dtos";
import {setLoadUsersTaskStatus} from "../features/settings/settingsSlice";
import UserLayout from "../components/UserLayout";
import * as XLSX from "xlsx-js-style";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {robotoRegularBase64} from "../shared/pdf-fonts";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import {CreateContractDialog} from "./create-contract/createContractDialog";
import ContractAction from "../actions/ContractAction";
import {DeleteContractDialog} from "./delete-contract/deleteContractDialog";
import {EditContractDialog} from "./edit-contract/EditContractDialog";
import {adminOrHasPermission} from "../shared/utils";

export const Contracts = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const allPaginatedContracts = useSelector((state: AppState) => state.contract.allPaginatedContracts);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const page = Number(searchParams.get("page") || "1");
    const [typeFilter, setTypeFilter] = useState<ContractType | null>(null);
    const [statusFilter, setStatusFilter] = useState<ContractStatus | null>(null);
    const [createContractDialogOpen, setCreateContractDialogOpen] = useState(false);
    const [activeContract, setActiveContract] = useState<ContractDTO | null>(null);
    const [deleteContractDialogOpen, setDeleteContractDialogOpen] = useState(false);
    const [editContractDialogOpen, setEditContractDialogOpen] = useState(false)

    const handleCreateContractDialogClose = () => setCreateContractDialogOpen(false);
    const handleCreateContractDialogOpen = () => setCreateContractDialogOpen(true);
    const openCreateContract = () => handleCreateContractDialogOpen();

    const handleDeleteContractDialogOpen = () => setDeleteContractDialogOpen(true);
    const handleDeleteContractDialogClose = () => setDeleteContractDialogOpen(false);

    const handleEditContractDialogOpen = () => setEditContractDialogOpen(true);
    const handleEditContractDialogClose = () => setEditContractDialogOpen(false);

    useEffect(() => {
        if (currentUser) {
            let relatedCompanyId = null;

            if (currentUser.associatedCompany) {
                relatedCompanyId = currentUser.associatedCompany.id
            }

            dispatch(getAllPaginatedContractsAction({
                    relatedCompanyId: relatedCompanyId,
                    type: typeFilter,
                    status: statusFilter,
                    page,
                })
            );

            return () => {
                dispatch(setLoadUsersTaskStatus(null));
            };
        }
    }, [dispatch, page, typeFilter, statusFilter, currentUser?.id, currentUser?.role]);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const reloadFirstPage = () => {
        let relatedCompanyId = null;
        if (currentUser) {
            if (currentUser.associatedCompany) {
                relatedCompanyId = currentUser.associatedCompany.id
            }
            if (page === 1) {
                dispatch(
                    getAllPaginatedContractsAction({
                        relatedCompanyId: relatedCompanyId,
                        type: typeFilter,
                        status: statusFilter,
                        page,
                    })
                );
            } else {
                navigate("/sozlesmeler?page=1");
            }
        }
    };

    const handleCreateContract = () => reloadFirstPage();

    const openDelete = (contract: ContractDTO) => {
        setActiveContract(contract);
        handleDeleteContractDialogOpen();
    };

    const openEdit = (contract: ContractDTO) => {
        setActiveContract(contract);
        handleEditContractDialogOpen();
    };

    const handleDelete = () => {
        setActiveContract(null);
        handleDeleteContractDialogClose();
        reloadFirstPage();
    }

    const handleEdit = () => {
        setActiveContract(null);
        handleEditContractDialogClose();
        reloadFirstPage();
    }

    const exportToExcel = () => {
        const headers = [
            ["İlgili Taraf", "Sözleşme Türü", "Başlangıç Tarihi", "Geçerlilik Tarihi", "İlgili Şube/İştirak", "Durum"]
        ];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedContracts?.entity || []).map((contract) => ({
                "İlgili Taraf": contract.relatedSide,
                "Sözleşme Türü": getPresenTableContractType(contract.type),
                "Başlangıç Tarihi": contract.signDate
                    ? moment.unix(contract.signDate).format("DD MMMM YYYY")
                    : "-",
                "Geçerlilik Tarihi": contract.validUntil
                    ? moment.unix(contract.validUntil).format("DD MMMM YYYY")
                    : "Süresiz",
                "İlgili Şube/İştirak": contract.relatedCompany.name,
                "Durum": getPresentableContractStatus(contract.status),
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);
        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((_, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Sözleşmeler");
        XLSX.writeFile(workbook, `${currentUser?.associatedCompany?.name}_sözlesmeler.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.addFileToVFS("Roboto-Regular.ttf", robotoRegularBase64);
        doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
        doc.setFont("Roboto");

        doc.setFontSize(18);
        doc.text("Sözleşmeler", 14, 20);

        const tableData = (allPaginatedContracts?.entity || []).map((contract) => [
            contract.relatedSide,
            getPresenTableContractType(contract.type),
            contract.signDate
                ? moment.unix(contract.signDate).format("DD MMMM YYYY")
                : "-",
            contract.validUntil
                ? moment.unix(contract.validUntil).format("DD MMMM YYYY")
                : "Süresiz",
            contract.relatedCompany.name,
            getPresentableContractStatus(contract.status),
        ]);

        const tableColumns = ["İlgili Taraf", "Sözleşme Türü", "Başlangıç Tarihi", "Geçerlilik Tarihi", "İlgili Şube/İştirak", "Durum"];

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: "Roboto",
                cellPadding: 3,
                fontSize: 10,
                valign: "middle",
                halign: "left",
            },
        });

        doc.save(`${currentUser?.associatedCompany?.name}_sözleşmeler.pdf`);
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>SÖZLEŞMELER</h1>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: "flex", gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: "0.875rem", padding: "8px 16px"}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: "0.875rem", padding: "8px 16px"}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    {adminOrHasPermission(currentUser, UserPermission.CONTRACT_ADD) && (
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            sx={{marginLeft: "auto", marginBottom: 5}}
                            onClick={openCreateContract}>
                            <AddIcon/>
                        </Fab>
                    )}
                </Box>
            </div>

            {createContractDialogOpen && (
                <CreateContractDialog
                    onClose={handleCreateContractDialogClose}
                    onContractAdded={handleCreateContract}/>
            )}

            {deleteContractDialogOpen && activeContract != null && (
                <DeleteContractDialog
                    contract={activeContract}
                    handleClose={handleDeleteContractDialogClose}
                    handleDelete={handleDelete}/>
            )}

            {editContractDialogOpen && activeContract != null && (
                <EditContractDialog
                    contract={activeContract}
                    handleClose={handleEditContractDialogClose}
                    handleEdit={handleEdit}/>
            )}
            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label="my contracts table">
                    <TableHead>
                        <TableRow>
                            <TableCell>İLGİLİ ŞUBE</TableCell>
                            <TableCell>İLGİLİ TARAF</TableCell>
                            <TableCell>SÖZLEŞME TÜRÜ</TableCell>
                            <TableCell>BAŞLANGIÇ TARİHİ</TableCell>
                            <TableCell>GEÇERLİLİK TARİHİ</TableCell>
                            <TableCell>DURUM</TableCell>
                            <TableCell>İŞLEMLER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPaginatedContracts?.entity.map((contract) => (
                            <TableRow key={contract.id}>
                                <TableCell>{contract.relatedCompany.name}</TableCell>
                                <TableCell>{contract.relatedSide}</TableCell>
                                <TableCell>{getPresenTableContractType(contract.type)}</TableCell>
                                <TableCell>{
                                    contract.signDate
                                        ? moment.unix(contract.signDate).format("DD MMMM YYYY")
                                        : "-"}
                                </TableCell>
                                <TableCell>{
                                    contract.validUntil
                                        ? moment.unix(contract.validUntil).format("DD MMMM YYYY")
                                        : "Süresiz"}
                                </TableCell>
                                <TableCell>{getPresentableContractStatus(contract.status)}</TableCell>
                                <TableCell>
                                    <ContractAction
                                        onEdit={openEdit}
                                        contract={contract}
                                        onDelete={openDelete}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {allPaginatedContracts?.totalNumberPages != null && allPaginatedContracts.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination page={page} count={allPaginatedContracts.totalNumberPages} defaultPage={1}
                                onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
};
