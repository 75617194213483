import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {NotificationDTO, PaginatedEntityList} from "../../api/dtos";

export interface NotificationState {
    allPaginatedNotifications: PaginatedEntityList<NotificationDTO> | null;
    loadNotificationsTaskStatus: AsyncTaskStatus | null;
    updateNotificationStatusTaskStatus: AsyncTaskStatus | null;
    deleteNotificationTaskStatus: AsyncTaskStatus | null;
    markNotificationTaskStatus: AsyncTaskStatus | null;
}

const initialState: NotificationState = {
    allPaginatedNotifications: null,
    loadNotificationsTaskStatus: null,
    updateNotificationStatusTaskStatus: null,
    deleteNotificationTaskStatus: null,
    markNotificationTaskStatus: null
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setLoadNotificationsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadNotificationsTaskStatus = action.payload;
        },
        setAllPaginatedNotifications: (state, action: PayloadAction<PaginatedEntityList<NotificationDTO> | null>) => {
            state.allPaginatedNotifications = action.payload;
        },
        setUpdateNotificationStatusTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateNotificationStatusTaskStatus = action.payload;
        },
        setDeleteNotificationTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteNotificationTaskStatus = action.payload;
        },
        setMarkNotificationTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.markNotificationTaskStatus = action.payload;
        }
    }
});

export const {
    setAllPaginatedNotifications,
    setLoadNotificationsTaskStatus,
    setUpdateNotificationStatusTaskStatus,
    setDeleteNotificationTaskStatus,
    setMarkNotificationTaskStatus
} = notificationSlice.actions;

export default notificationSlice.reducer;
