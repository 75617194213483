import {CourtDTO} from "../../api/dtos";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomDialog from "../../components/CustomDialog";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {deleteCourtAction} from "../../api/settings";

export interface DeleteCourtPopupProps {
    court: CourtDTO;
    onClose: () => void;
    handleDeleteCourt: () => void;
}

export const DeleteCourtPopup: React.FC<DeleteCourtPopupProps> = ({
                                                                      court,
                                                                      onClose,
                                                                      handleDeleteCourt
                                                                  }) => {
    const dispatch: any = useDispatch();
    const handleDelete = async () => {
        try {
            const result = await dispatch(deleteCourtAction(court.id));
            if (result.type === 'courts/deleteCourt/fulfilled') {
                handleDeleteCourt();
            }
        } catch (error) {
            console.error("Mahkeme silme işleminde bir hata oluştu:", error);
        }
    };

    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Mahkeme Sil ({court.name})</DialogTitle>
            <DialogContent>
                Seçilen Mahkemeyi Silmek İstiyor musunuz?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button color="error" onClick={handleDelete}>Sil</Button>
            </DialogActions>
        </CustomDialog>
    );
};
