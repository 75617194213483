import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import CustomDialog from "../../components/CustomDialog";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import {resetOwnPasswordAction} from "../../api/appUser";
import {PasswordResetDTO} from "../../api/dtos";
import ErrorText from "../../components/ErrorText";
import {setResetOwnPasswordTaskStatus} from "../../features/app-user/appUserSlice";

interface ResetOwnPasswordDialogProps {
    handleClose: () => void;
}

interface ResetOwnPasswordFormData {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
}

export const ResetOwnPasswordDialog: React.FC<ResetOwnPasswordDialogProps> = ({handleClose}) => {
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [hasConfirmationError, setHasConfirmationError] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(setResetOwnPasswordTaskStatus(null));
        }
    }, []);

    const [formData, setFormData] = useState<ResetOwnPasswordFormData>({
        oldPassword: "",
        password: "",
        passwordConfirmation: ""
    });
    const resetOwnPasswordTaskStatus = useSelector((state: AppState) => state.appUser.resetOwnPasswordTaskStatus);

    const convertDTO = (data: ResetOwnPasswordFormData): PasswordResetDTO => ({
        oldPassword: data.oldPassword,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation
    });

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const result = await dispatch(resetOwnPasswordAction(convertDTO(formData)));

        if (result.type === 'user/resetOwnPassword/fulfilled') {
            handleClose();
        }
    };

    useEffect(() => {
        if (formData.password === formData.passwordConfirmation) {
            setErrors((prevErrors) => {
                const {passwordConfirmation, ...rest} = prevErrors;
                return rest;
            });
            setHasConfirmationError(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordConfirmation: "Şifreler Uyuşmuyor."
            }));
            setHasConfirmationError(true);
        }
    }, [formData.password, formData.passwordConfirmation]);

    useEffect(() => {
        if (resetOwnPasswordTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            resetOwnPasswordTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [resetOwnPasswordTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Şifre Güncelle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="oldpassword"
                        name="oldPassword"
                        label="Eski Şifre"
                        type="password"
                        fullWidth
                        autoComplete="new-password"
                        variant="outlined"
                        value={formData.oldPassword}
                        onChange={handleChangeFormData}/>
                    {errors.oldPassword && (
                        <ErrorText message={errors.oldPassword}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        name="password"
                        label="Şifre"
                        type="password"
                        fullWidth
                        autoComplete="new-password"
                        variant="outlined"
                        value={formData.password}
                        onChange={handleChangeFormData}/>
                    {errors.password && (
                        <ErrorText message={errors.password}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        label="Şifre Onay"
                        type="password"
                        autoComplete="new-password"
                        fullWidth
                        variant="outlined"
                        value={formData.passwordConfirmation}
                        onChange={handleChangeFormData}/>
                    {errors.passwordConfirmation && (
                        <ErrorText message={errors.passwordConfirmation}/>
                    )}
                </FormControl>
                {resetOwnPasswordTaskStatus?.type === AsyncTaskStatusType.Error && resetOwnPasswordTaskStatus.errorMessage && (
                    <ErrorText type="form" message={resetOwnPasswordTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit" disabled={hasConfirmationError}>Değiştir</Button>
            </DialogActions>
        </CustomDialog>
    );
};
