import {ReactNode, useEffect, useRef, useState} from 'react';
import List from '@mui/material/List';
import Box, {BoxProps} from '@mui/material/Box';
import {styled, useTheme} from '@mui/material/styles';
import VerticalNavItems from "../theme/@core/layouts/components/vertical/navigation/VerticalNavItems";
import {hexToRGBA} from "../theme/@core/utils/hex-to-rgba";
import VerticalNavHeader from "../theme/@core/layouts/components/vertical/navigation/VerticalNavHeader";
import {Settings} from "../theme/@core/context/settingsContext";
import {VerticalNavItemsType} from "../theme/@core/layouts/types";
import Drawer from "./Drawer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store";
import {setMenuScrollPosition} from "../features/appData/appDataSlice";
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
    hidden: boolean;
    navWidth: number;
    settings: Settings;
    children: ReactNode;
    navVisible: boolean;
    toggleNavVisibility: () => void;
    setNavVisible: (value: boolean) => void;
    verticalNavItems?: VerticalNavItemsType;
    saveSettings: (values: Settings) => void;
    verticalNavMenuContent?: (props?: any) => ReactNode;
    afterVerticalNavMenuContent?: (props?: any) => ReactNode;
    beforeVerticalNavMenuContent?: (props?: any) => ReactNode;
}

const StyledBoxForShadow = styled(Box)<BoxProps>({
    top: 64,
    left: -8,
    zIndex: 2,
    height: 15,
    display: 'none',
    position: 'absolute',
    pointerEvents: 'none',
    width: 'calc(100% + 15px)',
    '&.d-block': {
        display: 'block'
    }
});
const StyledBoxForShadow2 = styled(Box)<BoxProps>({
    bottom: 24,
    left: -8,
    zIndex: 2,
    height: 15,
    display: 'none',
    position: 'absolute',
    pointerEvents: 'none',
    width: 'calc(100% + 15px)',
    '&.d-block': {
        display: 'block'
    }
});

const Navigation = (props: Props) => {
    const dispatch: any = useDispatch();
    const menuScrollPosition = useSelector((state: AppState) => state.appData.menuScrollPosition);
    const {
        hidden,
        afterVerticalNavMenuContent,
        beforeVerticalNavMenuContent,
        verticalNavMenuContent: userVerticalNavMenuContent
    } = props;

    const [groupActive, setGroupActive] = useState<string[]>([]);
    const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([]);

    const shadowRef = useRef<HTMLDivElement>(null);
    const shadowRef2 = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const scrollMenu = () => {
        const container = containerRef.current;

        if (container) {
            const scrollPosition = container.scrollTop;
            const containerHeight = container.clientHeight;
            const scrollHeight = container.scrollHeight;

            dispatch(setMenuScrollPosition(scrollPosition));

            if (shadowRef.current && scrollPosition > 0) {
                if (!shadowRef.current.classList.contains('d-block')) {
                    shadowRef.current.classList.add('d-block');
                }
            } else if (shadowRef.current) {
                shadowRef.current.classList.remove('d-block');
            }

            if (shadowRef2.current && scrollPosition + containerHeight >= scrollHeight) {
                shadowRef2.current.classList.remove('d-block');
            } else if (shadowRef2.current) {
                if (!shadowRef2.current.classList.contains('d-block')) {
                    shadowRef2.current.classList.add('d-block');
                }
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.scrollTop = menuScrollPosition;
            container.addEventListener('scroll', scrollMenu);
        } else {
            const observer = new MutationObserver((_, obs) => {
                const newContainer = containerRef.current;
                if (newContainer) {
                    newContainer.scrollTop = menuScrollPosition;
                    newContainer.addEventListener('scroll', scrollMenu);
                    obs.disconnect();
                }
            });

            observer.observe(document.body, {childList: true, subtree: true});

            return () => {
                observer.disconnect();
            };
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', scrollMenu);
            }
        };
    }, [menuScrollPosition, isSmallScreen]);

    return (
        <Drawer {...props}>
            <VerticalNavHeader {...props} />
            <StyledBoxForShadow
                ref={shadowRef}
                sx={{
                    background: `linear-gradient(${theme.palette.background.default} 40%,${hexToRGBA(
                        theme.palette.background.default,
                        0.1
                    )} 95%,${hexToRGBA(theme.palette.background.default, 0.05)})`
                }}/>
            <Box sx={{height: '100%', display: 'flex', position: 'relative', overflow: 'hidden'}}>
                <Box
                    ref={containerRef}
                    sx={{flex: 1, overflowY: 'auto', overflowX: 'hidden'}}>
                    {beforeVerticalNavMenuContent ? beforeVerticalNavMenuContent(props) : null}
                    <Box sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        {userVerticalNavMenuContent
                            ? (
                                userVerticalNavMenuContent(props)
                            )
                            : (
                                <List className='nav-items' sx={{transition: 'padding .25s ease', pr: 4.5}}>
                                    <VerticalNavItems
                                        groupActive={groupActive}
                                        setGroupActive={setGroupActive}
                                        currentActiveGroup={currentActiveGroup}
                                        setCurrentActiveGroup={setCurrentActiveGroup}
                                        {...props}/>
                                </List>
                            )}
                    </Box>
                </Box>
            </Box>
            <StyledBoxForShadow2
                ref={shadowRef2}
                sx={{
                    background: `linear-gradient(${hexToRGBA(
                        theme.palette.background.default,
                        0.05
                    )} 5%, ${hexToRGBA(
                        theme.palette.background.default,
                        0.1
                    )} 20%, ${theme.palette.background.default} 85%)`
                }}/>
            {afterVerticalNavMenuContent ? afterVerticalNavMenuContent(props) : null}
        </Drawer>
    );
};

export default Navigation;
