import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import Button from "@mui/material/Button";
import {getPresentableLitigantType, LitigantType} from "../../api/litigantType";
import {AddLitigantToCaseDTO, CaseType, CompanyType} from "../../api/dtos";
import CustomDialog from "../../components/CustomDialog";
import {getAllClientsAction, getAllCompaniesAction} from "../../api/settings";
import {AddLitigantType} from "./createLegalCaseDialog";
import {CreateCompanyDialog} from "../create-company/createCompanyDialog";
import {CreateClientDialog} from "../create-client/createClientDialog";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

interface AddProsecutorDialogProps {
    handleClose: () => void;
    caseType: CaseType;
    addProsecutor: (result: AddLitigantType) => void;
    excludeItems: AddLitigantToCaseDTO[];
}

interface FormData {
    litigantType: LitigantType;
    selection: number | null;
    name: string;
}

export const AddProsecutorDialog: React.FC<AddProsecutorDialogProps> = ({
                                                                            handleClose,
                                                                            caseType,
                                                                            excludeItems,
                                                                            addProsecutor
                                                                        }) => {
    const allClients = useSelector((state: AppState) => state.settings.allClientsLookup);
    const allCompanies = useSelector((state: AppState) => state.settings.allCompanies);
    const dispatch: any = useDispatch();

    const [isCreateCompanyDialogOpen, setIsCreateCompanyDialogOpen] = useState(false);
    const [isCreateClientDialogOpen, setIsCreateClientDialogOpen] = useState(false);

    const handleCloseCreateCompanyDialog = () => {
        setIsCreateCompanyDialogOpen(false);
    };

    const handleCloseCreateClientDialog = () => {
        setIsCreateClientDialogOpen(false);
    };

    const handleNewCompanyCreated = () => {
        dispatch(getAllCompaniesAction());
        handleCloseCreateCompanyDialog();
    };

    const handleNewClientCreated = () => {
        dispatch(getAllClientsAction());
        handleCloseCreateClientDialog();
    };

    const excludedClientIds = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.Client)
        .map((excludeItem) => excludeItem.clientId);

    const filteredAllClients = allClients?.filter(
        (client) => !excludedClientIds.includes(client.id)
    );

    const excludedCompanyIds = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.Company)
        .map((excludeItem) => excludeItem.companyId);

    const filteredAllCompanies = allCompanies?.filter(
        (company) =>
            !excludedCompanyIds.includes(company.id) &&
            [CompanyType.Subsidiary, CompanyType.Branch].includes(company.type)
    );

    const initialFormData: FormData = {
        litigantType: LitigantType.Company,
        selection: null,
        name: ""
    };

    const litigantTypeRef = useRef(initialFormData.litigantType);
    const [formData, setFormData] = useState<FormData>(initialFormData);

    useEffect(() => {
        if (formData.litigantType !== litigantTypeRef.current) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                selection: null,
                name: "",
            }));
        }
        litigantTypeRef.current = formData.litigantType;
    }, [formData.litigantType]);

    useEffect(() => {
        if (!allCompanies || allCompanies.length === 0) {
            dispatch(getAllCompaniesAction());
        }
    }, [dispatch, allCompanies]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let selection = undefined;
        let name = undefined;
        switch (formData.litigantType) {
            case LitigantType.Company:
                selection = allCompanies?.find((it) => it.id === formData.selection);
                break;
            case LitigantType.Client:
                selection = allClients?.find((it) => it.id === formData.selection);
                break;
        }

        addProsecutor({
            litigantType: formData.litigantType,
            selection,
            name
        });
        handleClose();
    };

    const submitDisabled = formData.selection == null;

    let title = "Davacı Seç";
    let selectTypeLabel = "Davacı Tipi";
    let otherLabel = "Davacı";

    switch (caseType) {
        case CaseType.Criminal:
            title = "Müşteki/Katılan Seç";
            selectTypeLabel = "Müşteki/Katılan Tipi";
            otherLabel = "Müşteki/Katılan";
            break;
        case CaseType.Executive:
            title = "Alacaklı Seç";
            selectTypeLabel = "Alacaklı Tipi";
            otherLabel = "Alacaklı";
            break;
        case CaseType.Investigation:
            title = "Müşteki Seç";
            selectTypeLabel = "Müşteki Tipi";
            otherLabel = "Müşteki";
            break;
    }

    return (
        <>
            <CustomDialog
                open={true}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="litigantType-label">{selectTypeLabel}</InputLabel>
                        <Select
                            autoFocus={true}
                            labelId="status-label"
                            id="litigantType"
                            name="litigantType"
                            value={formData.litigantType}
                            label={selectTypeLabel}
                            onChange={(e) => setFormData({...formData, litigantType: e.target.value as LitigantType})}>
                            {Object.values(LitigantType).map((litigantType) => (
                                <MenuItem key={litigantType} value={litigantType}>
                                    {getPresentableLitigantType(litigantType)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {formData.litigantType === LitigantType.Client && (
                        <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                            <Autocomplete
                                options={filteredAllClients || []}
                                getOptionLabel={(option: any) => option.value || ""}
                                onChange={(event, newValue) => {
                                    setFormData({
                                        ...formData,
                                        selection: newValue ? newValue.id : null,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={otherLabel} variant="outlined"/>
                                )}
                                value={filteredAllClients?.find(
                                    (client) => client.id === formData.selection
                                ) || null}/>
                        </FormControl>
                    )}

                    {formData.litigantType === LitigantType.Company && (
                        <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                            <Autocomplete
                                options={filteredAllCompanies || []}
                                getOptionLabel={(option: any) => option.name || ""}
                                onChange={(event, newValue) => {
                                    setFormData({
                                        ...formData,
                                        selection: newValue ? newValue.id : null,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={otherLabel} variant="outlined"/>
                                )}
                                value={filteredAllCompanies?.find(
                                    (company) => company.id === formData.selection
                                ) || null}/>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>İptal</Button>
                    <Button type="submit" disabled={submitDisabled}>Ekle</Button>
                </DialogActions>
            </CustomDialog>

            {isCreateCompanyDialogOpen && (
                <CreateCompanyDialog
                    companyType={CompanyType.Branch}
                    handleClose={handleCloseCreateCompanyDialog}
                    handleCreate={handleNewCompanyCreated}/>
            )}

            {isCreateClientDialogOpen && (
                <CreateClientDialog
                    handleClose={handleCloseCreateClientDialog}
                    handleCreate={handleNewClientCreated}/>
            )}
        </>
    );
};
