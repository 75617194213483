import UserLayout from "../components/UserLayout";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLoadEmailSettingsAction, updateEmailSettingsAction} from "../api/settings";
import {AppState} from "../store";
import {Alert, Box, Button, DialogActions, FormControl, Snackbar, TextField, Typography} from "@mui/material";
import {FieldErrors} from "../shared/dtos";
import ErrorText from "../components/ErrorText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function MailSettings() {
    const dispatch: any = useDispatch();
    const emailSettings = useSelector((state: AppState) => state.settings.emailSettings);
    const updateEmailSettingsTaskStatus = useSelector((state: AppState) => state.settings.updateEmailSettingsTaskStatus);
    const [errors, setErrors] = useState<FieldErrors>({});
    const [hasConfirmationError, setHasConfirmationError] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        MAIL_HOST: "",
        MAIL_PORT: "",
        MAIL_USERNAME: "",
        MAIL_PASSWORD: "",
        MAIL_PROTOCOL: "",
        MAIL_SMTP_AUTH: "",
        MAIL_TLS_ENABLE: "",
        MAIL_DEBUG_ENABLE: "",
    });

    useEffect(() => {
        dispatch(getLoadEmailSettingsAction());
    }, [dispatch]);

    useEffect(() => {
        if (emailSettings) {
            setFormData({
                MAIL_HOST: emailSettings.MAIL_HOST || "",
                MAIL_PORT: emailSettings.MAIL_PORT || "",
                MAIL_USERNAME: emailSettings.MAIL_USERNAME || "",
                MAIL_PASSWORD: emailSettings.MAIL_PASSWORD || "",
                MAIL_PROTOCOL: emailSettings.MAIL_PROTOCOL || "",
                MAIL_SMTP_AUTH: emailSettings.MAIL_SMTP_AUTH || "",
                MAIL_TLS_ENABLE: emailSettings.MAIL_TLS_ENABLE || "",
                MAIL_DEBUG_ENABLE: emailSettings.MAIL_DEBUG_ENABLE || "",
            });
        }
    }, [emailSettings]);

    useEffect(() => {
        if (updateEmailSettingsTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            updateEmailSettingsTaskStatus.fieldErrors.forEach((error) => {
                updatedErrors[error.field] = error.message;
            });
            setErrors(updatedErrors);
        }
    }, [updateEmailSettingsTaskStatus]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const result = await dispatch(updateEmailSettingsAction({propMap: formData}));
        if (result.type === 'settings/updateEmailSettings/fulfilled') {
            setSnackbarMessage('Ayarlar başarıyla güncellendi!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            dispatch(getLoadEmailSettingsAction());
        } else {
            setSnackbarMessage('Ayarları güncellerken bir hata oluştu.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <UserLayout>
            <Typography variant="h4" gutterBottom>
                E-POSTA AYARLARI
            </Typography>

            <Box sx={{width: '40%'}}>
                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Mail Sunucusu"
                        name="MAIL_HOST"
                        value={formData.MAIL_HOST}
                        onChange={handleChange}/>
                    {errors.MAIL_HOST && (
                        <ErrorText message={errors.MAIL_HOST}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Mail Port Numarası"
                        name="MAIL_PORT"
                        value={formData.MAIL_PORT}
                        onChange={handleChange}/>
                    {errors.MAIL_PORT && (
                        <ErrorText message={errors.MAIL_PORT}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Mail Kullanıcı Adı"
                        name="MAIL_USERNAME"
                        autoComplete="new-email"
                        value={formData.MAIL_USERNAME}
                        onChange={handleChange}/>
                    {errors.MAIL_USERNAME && (
                        <ErrorText message={errors.MAIL_USERNAME}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Mail Şifresi"
                        name="MAIL_PASSWORD"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        value={formData.MAIL_PASSWORD}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword((prev) => !prev)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end">
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}/>
                    {errors.MAIL_PASSWORD && (
                        <ErrorText message={errors.MAIL_PASSWORD}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Mail Protokolü"
                        name="MAIL_PROTOCOL"
                        value={formData.MAIL_PROTOCOL}
                        onChange={handleChange}/>
                    {errors.MAIL_PROTOCOL && (
                        <ErrorText message={errors.MAIL_PROTOCOL}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.MAIL_SMTP_AUTH === "true"}
                                onChange={(e) => setFormData((prevData) => ({
                                    ...prevData,
                                    MAIL_SMTP_AUTH: e.target.checked.toString(),
                                }))}
                                name="MAIL_SMTP_AUTH"/>
                        }
                        label="SMTP Kimlik Doğrulama"/>
                    {errors.MAIL_SMTP_AUTH && (
                        <ErrorText message={errors.MAIL_SMTP_AUTH}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.MAIL_TLS_ENABLE === "true"}
                                onChange={(e) => setFormData((prevData) => ({
                                    ...prevData,
                                    MAIL_TLS_ENABLE: e.target.checked.toString(),
                                }))}
                                name="MAIL_TLS_ENABLE"/>
                        }
                        label="TLS Etkin"/>
                    {errors.MAIL_TLS_ENABLE && (
                        <ErrorText message={errors.MAIL_TLS_ENABLE}/>
                    )}
                </FormControl>

                <FormControl fullWidth sx={{mb: 1}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.MAIL_DEBUG_ENABLE === "true"}
                                onChange={(e) => setFormData((prevData) => ({
                                    ...prevData,
                                    MAIL_DEBUG_ENABLE: e.target.checked.toString(),
                                }))}
                                name="MAIL_DEBUG_ENABLE"/>
                        }
                        label="Hata Ayıklama Modu"/>
                    {errors.MAIL_DEBUG_ENABLE && (
                        <ErrorText message={errors.MAIL_DEBUG_ENABLE}/>
                    )}
                </FormControl>

                {updateEmailSettingsTaskStatus?.type === "Error" && (
                    <Typography style={{color: "red"}}>
                        Hata: {updateEmailSettingsTaskStatus.errorMessage}
                    </Typography>
                )}
                <DialogActions>
                    <Button onClick={handleSubmit} disabled={hasConfirmationError}>
                        Güncelle
                    </Button>
                </DialogActions>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{
                        width: '100%',
                        backgroundColor: '#003087',
                        color: '#FFD700'
                    }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </UserLayout>
    );
}
