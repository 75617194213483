import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from '@mui/material';
import {getPresentableTaskStatus, TaskDTO, TaskStatus} from "../../api/dtos";
import {updateTaskStatusAction} from "../../api/task";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {setUpdateTaskStatusTaskStatus} from "../../features/task/taskSlice";
import CustomDialog from "../../components/CustomDialog";
import TextField from "@mui/material/TextField";

const fieldVariant = "outlined";

interface StatusDialogProps {
    handleClose: () => void;
    handleChangeStatus: () => void;
    task: TaskDTO;
}

export const ChangeStatusDialog: React.FC<StatusDialogProps> = ({
                                                                    task,
                                                                    handleClose,
                                                                    handleChangeStatus
                                                                }) => {
    const updateTaskStatusTaskStatus = useSelector((state: AppState) => state.task.updateTaskStatusTaskStatus);
    const [formData, setFormData] = useState<{
        newStatus: TaskStatus;
        waitReason: string | null;
    }>({
        newStatus: task.status,
        waitReason: null
    });

    const [errors, setErrors] = useState<FieldErrors>({});
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setUpdateTaskStatusTaskStatus(null));
        };
    }, []);

    useEffect(() => {
        if (updateTaskStatusTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            updateTaskStatusTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [updateTaskStatusTaskStatus]);

    const handleSaveClick = async () => {
        if (formData.newStatus !== task.status) {
            try {
                const result = await dispatch(updateTaskStatusAction({
                    taskId: task.id,
                    newStatus: formData.newStatus,
                    waitReason: formData.newStatus === TaskStatus.WaitingForReply ? formData.waitReason : null
                }));
                if (result.type === "task/updateTaskStatus/fulfilled") {
                    handleChangeStatus();
                }
            } catch (error) {
                console.error("Güncelleme işlemi başarısız oldu: ", (error as any).message || error);
            }
        }
    };

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Durumu Değiştir</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="new-status-label">Durum Seç</InputLabel>
                    <Select
                        labelId="new-status-label"
                        id="newStatus"
                        name="newStatus"
                        label="Durum Seç"
                        fullWidth
                        variant={fieldVariant}
                        value={formData.newStatus}
                        onChange={handleChangeFormData}>
                        {Object.values(TaskStatus).map((statusValue) => (
                            <MenuItem key={statusValue} value={statusValue}>
                                {getPresentableTaskStatus(statusValue)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.newStatus && (
                        <ErrorText message={errors.newStatus}/>
                    )}
                </FormControl>
                {formData.newStatus === TaskStatus.WaitingForReply && (
                    <TextField
                        name="waitReason"
                        label="Bekleme Sebebi"
                        fullWidth
                        variant={fieldVariant}
                        value={formData.waitReason || ""}
                        onChange={(e) => setFormData({...formData, waitReason: e.target.value})}
                        error={!!errors.waitReason}
                        helperText={errors.waitReason}/>
                )}
                {updateTaskStatusTaskStatus?.type === AsyncTaskStatusType.Error && updateTaskStatusTaskStatus.errorMessage && (
                    <ErrorText type="form" message={updateTaskStatusTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button
                    onClick={handleSaveClick}
                    color="primary"
                    disabled={formData.newStatus === task.status || (formData.newStatus === TaskStatus.WaitingForReply && !formData.waitReason)}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
