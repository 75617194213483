import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Box, Tab, Tabs} from '@mui/material';
import UserLayout from '../../components/UserLayout';
import {CaseType} from "../../api/dtos";
import {LegalCaseDetails} from "./LegalCaseDetails";
import {LegalCaseFiles} from "./LegalCaseFiles";
import {LegalCaseExpenses} from "./LegalCaseExpenses";
import {LegalCaseNotices} from "./LegalCaseNotices";
import {LegalCaseTask} from "./LegalCaseTask";
import moment from "moment/moment";

moment.locale('tr');

enum TabRoutes {
    Details = 'ayrintilar',
    Files = 'dosyalar',
    Expenses = 'masraflar',
    Notices = 'tebligatlar',
    Tasks = 'gorevler',
}

const tabIndexMap: Map<TabRoutes, number> = new Map([
    [TabRoutes.Details, 0],
    [TabRoutes.Files, 1],
    [TabRoutes.Expenses, 2],
    [TabRoutes.Notices, 3],
    [TabRoutes.Tasks, 4],
]);

const tabLabelMap: Map<number, TabRoutes> = new Map([
    [0, TabRoutes.Details],
    [1, TabRoutes.Files],
    [2, TabRoutes.Expenses],
    [3, TabRoutes.Notices],
    [4, TabRoutes.Tasks]
]);

const caseTypeToTitleMap: Record<CaseType, string> = {
    [CaseType.Law]: "Dava",
    [CaseType.Investigation]: "Soruşturma",
    [CaseType.Criminal]: "Ceza Davası",
    [CaseType.Executive]: "İcra Takibi"
};

const routeToCaseTypeMap: Record<string, CaseType> = {
    'hukuk-davalari': CaseType.Law,
    'sorusturmalar': CaseType.Investigation,
    'ceza-davalari': CaseType.Criminal,
    'icra-takibi': CaseType.Executive
};

const LegalCaseDetailsTabs = () => {
    const {id, tab} = useParams<{ id: string, tab: string }>();
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const pathSegments = location.pathname.split('/').filter(Boolean);
    const caseType = routeToCaseTypeMap[pathSegments[0]] || CaseType.Law;
    const title = caseTypeToTitleMap[caseType] || "Dava";

    useEffect(() => {
        const initialIndex = tabIndexMap.get(tab as TabRoutes) ?? 0;
        setTabIndex(initialIndex);
    }, [tab]);

    const handleTabChange = (_: any, newIndex: number) => {
        setTabIndex(newIndex);
        const route = tabLabelMap.get(newIndex);
        if (route) {
            navigate(`/${pathSegments[0]}/${id}/${route}`);
        }
    };

    const legalCaseId = Number(id!);

    return (
        <UserLayout>
            <h1>{title.toLocaleUpperCase("tr")} DETAYI</h1>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Dava Detay Sekmeleri">
                <Tab label="Ayrıntılar"/>
                <Tab label="Dosyalar"/>
                <Tab label="Masraflar"/>
                <Tab label="Görevler"/>
            </Tabs>
            <Box sx={{pt: 2}}>
                {tabIndex === 0 && <LegalCaseDetails id={legalCaseId}/>}
                {tabIndex === 1 && <LegalCaseFiles id={legalCaseId}/>}
                {tabIndex === 2 && <LegalCaseExpenses/>}
                {tabIndex === 3 && <LegalCaseTask id={legalCaseId}/>}
            </Box>
        </UserLayout>
    );
}

export default LegalCaseDetailsTabs;
