import React, {useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {TaskDTO} from "../../api/dtos";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {deleteTaskAction} from "../../api/task";
import {setDeleteTaskTaskStatus} from "../../features/task/taskSlice";

interface DeleteTaskDialogProps {
    handleClose: () => void;
    handleDelete: () => void;
    task: TaskDTO;
}

export const DeleteTaskDialog: React.FC<DeleteTaskDialogProps> = ({task, handleClose, handleDelete}) => {
    const deleteTaskTaskStatus = useSelector((state: AppState) => state.task.deleteTaskTaskStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setDeleteTaskTaskStatus(null));
        };
    }, []);

    const handleDeleteClick = async () => {
        try {
            const result = await dispatch(deleteTaskAction(task.id));
            if (result.type === 'tasks/deleteTask/fulfilled') {
                handleDelete();
            }
        } catch (error) {
            console.error("Görev silme işleminde bir hata oluştu:", error);
        }
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Görev Sil ({task.title})</DialogTitle>
            <DialogContent>
                Seçilen Görevi Silmek İstiyor musunuz?
                {deleteTaskTaskStatus?.type === AsyncTaskStatusType.Error && deleteTaskTaskStatus.errorMessage && (
                    <ErrorText type="form" message={deleteTaskTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleDeleteClick} color="error">Sil</Button>
            </DialogActions>
        </CustomDialog>
    );
};
