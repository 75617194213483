import React, {useEffect, useState} from "react";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {createFolderForLegalCaseAction} from "../../api/legalCase";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {LegalCaseDTO} from "../../api/dtos";
import {setCreateFolderTaskStatus} from "../../features/legal-case/legalCaseSlice";

interface AddFolderToLegalCaseDialogProps {
    onClose: () => void;
    legalCase: LegalCaseDTO;
    onFolderAdded: () => void;
    folderId?: number;
}

const fieldVariant = "outlined";

interface AddCreateFolderFormData {
    name: string;
}

export const AddFolderToLegalCaseDialog: React.FC<AddFolderToLegalCaseDialogProps> = ({
                                                                                          onClose,
                                                                                          legalCase,
                                                                                          onFolderAdded,
                                                                                          folderId
                                                                                      }) => {
    const dispatch: any = useDispatch();

    const defaultFormData: AddCreateFolderFormData = {name: ""};

    const [formData, setFormData] = useState<AddCreateFolderFormData>(defaultFormData);

    const handleChangeFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const dto = {
            name: formData.name,
            parentFolderId: folderId || null,
        };

        try {
            const result = await dispatch(
                createFolderForLegalCaseAction({
                    caseId: legalCase.id,
                    dto,
                })
            );

            if (result.type === "legalcase/createFolderForLegalCase/fulfilled") {
                onFolderAdded();
                onClose();
            }
        } catch (error) {
            console.error("Klasör eklerken bir hata oluştu:", error);
        }
    };

    const createFolderTaskStatus = useSelector(
        (state: AppState) => state.legalCase.createFolderTaskStatus
    );

    const [errors, setErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (createFolderTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createFolderTaskStatus?.fieldErrors.forEach((it) => (updatedErrors[it.field] = it.message));
            setErrors(updatedErrors);
        }
    }, [createFolderTaskStatus]);

    useEffect(() => {
        return () => {
            dispatch(setCreateFolderTaskStatus(null));
        }
    }, []);

    return (
        <CustomDialog
            open={true}
            onClose={onClose}
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Davaya Klasör Ekle ({legalCase.name})</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Klasör Adı"
                        type="text"
                        fullWidth
                        variant={fieldVariant}
                        onChange={handleChangeFormData}
                        required/>
                    {errors.name && <ErrorText message={errors.name}/>}
                </FormControl>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        margin="dense"
                        id="description"
                        name="description"
                        label="Açıklama"
                        type="text"
                        fullWidth
                        variant={fieldVariant}
                        onChange={handleChangeFormData}/>
                    {errors.description && <ErrorText message={errors.description}/>}
                </FormControl>
                {createFolderTaskStatus?.type === AsyncTaskStatusType.Error &&
                    createFolderTaskStatus.errorMessage && (
                        <ErrorText type="form" message={createFolderTaskStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button type="submit" disabled={!formData.name}>
                    Ekle
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
