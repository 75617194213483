import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Delete, Edit} from "@mui/icons-material";
import {CourtDTO} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import Tooltip from "@mui/material/Tooltip";
import {adminOrHasPermission} from "../shared/utils";

interface CourtActionProps {
    court: CourtDTO;
    onEdit: (court: CourtDTO) => void;
    onDelete: (court: CourtDTO) => void;
}

const CourtAction: React.FC<CourtActionProps> = ({
                                                     court,
                                                     onEdit,
                                                     onDelete,
                                                 }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    if (!user) return null;

    const editCourt = () => onMenuItemClick(() => onEdit(court));
    const deleteCourt = () => onMenuItemClick(() => onDelete(court));

    const courtEdit = adminOrHasPermission(user);
    const courtDelete = adminOrHasPermission(user);
    const hasAtLeastOneAction = courtEdit || courtDelete;

    return hasAtLeastOneAction
        ? (
            <>
                <IconButton
                    aria-controls={open ? 'court-action-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size='small'
                    aria-label='settings'
                    sx={{color: 'text.secondary'}}>
                    <DotsVertical/>
                </IconButton>
                <Menu
                    id="court-action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    {courtEdit && (
                        <MenuItem onClick={editCourt}>
                            <IconButton size='small' aria-label='edit' sx={{color: 'text.secondary'}}>
                                <Edit/>
                            </IconButton>
                            Düzenle
                        </MenuItem>
                    )}
                    {courtDelete && (
                        <MenuItem onClick={deleteCourt}>
                            <IconButton size='small' aria-label='delete' sx={{color: 'text.secondary'}}>
                                <Delete/>
                            </IconButton>
                            Sil
                        </MenuItem>
                    )}
                </Menu>
            </>
        ) : (
            <Tooltip title="Bu işlem için yetkiniz yok!">
                <IconButton
                    size="small"
                    aria-label="settings"
                    sx={{color: 'action.disabled'}}>
                    <DotsVertical/>
                </IconButton>
            </Tooltip>
        );
};

export default CourtAction;
