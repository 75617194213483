import React, {Fragment, SyntheticEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../store';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/material/styles';
import LogoutVariant from 'mdi-material-ui/LogoutVariant';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Typography from "@mui/material/Typography";
import {ListItemText, Popper} from "@mui/material";
import Divider from "@mui/material/Divider";
import {getAllNotificationsAction, markNotificationAsReadAction} from "../api/notification";

const BadgeContentSpan = styled('span')(({theme}) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

    const notifications = useSelector((state: AppState) => state.notification.allPaginatedNotifications?.entity);

    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        dispatch(getAllNotificationsAction(1));
    }, []);

    useEffect(() => {
        if (notifications) {
            setUnreadCount(notifications.filter(notification => !notification.read).length);
        }
    }, [notifications]);

    const handleDropdownOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickDropdownButton = (url?: string) => {
        if (url) {
            navigate(url);
        }
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl2(null);
    };

    const handleMarkAsRead = async (id: number) => {
        const result = await dispatch(markNotificationAsReadAction({id, read: true}));
        if (result.type === "notification/markNotificationAsRead/fulfilled") {
            dispatch(getAllNotificationsAction(1));
        }
    };

    const handleOpenHover = (event: SyntheticEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
        setIsHovering(true);
    };

    const [isHovering, setIsHovering] = useState(false);

    const handleCloseHover = () => {
        setIsHovering(false);
    };

    const handleIconClick = () => {
        setIsHovering(false);
        navigate('/bildirimler');
    };

    return (
        <Fragment>
            <IconButton
                color="inherit"
                onMouseEnter={handleOpenHover}
                onClick={handleIconClick}
                aria-controls="notification-popper"
                aria-haspopup="true">
                <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon/>
                </Badge>
            </IconButton>

            <Popper
                id="notification-popper"
                open={isHovering}
                anchorEl={anchorEl2}
                placement="bottom-end"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={handleCloseHover}
                style={{zIndex: 1300}}>
                <Box sx={{
                    width: 300,
                    maxHeight: 400,
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 1
                }}>
                    {notifications && notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <Box key={notification.id}>
                                <MenuItem
                                    onClick={() => {
                                        handleMarkAsRead(notification.id);
                                    }}
                                    sx={{
                                        backgroundColor: notification.read ? '#4c4040' : '#2c5e83',
                                    }}>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{fontWeight: notification.read ? 'normal' : 'bold'}}>
                                                {notification.title}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography variant="body2" color="text.secondary">
                                                    {notification.description}
                                                </Typography>
                                                {notification.relatedTask && (
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        sx={{fontStyle: 'italic'}}>
                                                        Task: {notification.relatedTask.title}
                                                    </Typography>
                                                )}
                                            </>
                                        }/>
                                </MenuItem>
                                <Divider/>
                            </Box>
                        ))
                    ) : (
                        <MenuItem onClick={handleClose}>
                            <Typography>No new notifications</Typography>
                        </MenuItem>
                    )}
                </Box>
            </Popper>

            <Badge
                overlap="circular"
                onClick={handleDropdownOpen}
                sx={{ml: 2, cursor: 'pointer'}}
                badgeContent={<BadgeContentSpan/>}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Avatar
                    alt="John Doe"
                    onClick={handleDropdownOpen}
                    sx={{width: 40, height: 40}}
                    src="/avatar.png"/>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClickDropdownButton()}
                sx={{'& .MuiMenu-paper': {width: 230, marginTop: 4}}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}>
                <MenuItem sx={{p: 0}} onClick={() => handleClickDropdownButton('/profile')}>
                    <Box sx={{py: 2, px: 4, display: 'flex', alignItems: 'center', color: 'text.primary'}}>
                        <AccountOutline sx={{marginRight: 2}}/>
                        Profilim
                    </Box>
                </MenuItem>
                <MenuItem sx={{py: 2}} onClick={() => handleClickDropdownButton('/logout')}>
                    <LogoutVariant sx={{marginRight: 2, fontSize: '1.375rem', color: 'text.secondary'}}/>
                    Çıkış
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default UserDropdown;
